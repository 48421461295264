import React, { useState, useMemo } from "react";
import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import AdminTable from "../Components/AdminTable";
import ClientModal from "./ClientModal";
import ClientCompanyModal from "./ClientCompanyModal";
import CompanyList from "./CompanyList";
import ErrorAlert from "../ErrorAlert";

const formatDate = (date) => {
  if (!date) return '-';
  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return '-';
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
  } catch (err) {
    return '-';
  }
};

const ClientManagement = ({
  clients = [],
  clientComps = {},
  loading = false,
  error = null,
  handleAddClient,
  handleUpdateClient,
  handleDeleteClient,
  handleUpdateOrCreateComp,
  handleDeleteClientComp,
}) => {
  const [showClientModal, setShowClientModal] = useState(false);
  const [showClientCompModal, setShowClientCompModal] = useState(false);
  const [editingClient, setEditingClient] = useState(null);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const clientColumns = useMemo(() => [
    { Header: "Client ID", accessor: "Client_ID" },
    { Header: "Client Name", accessor: "Client_Name" },
    { Header: "Client Type", accessor: "Client_Type" },
    { 
      Header: "Companies",
      id: "companies",
      Cell: ({ row }) => (
        <div className="min-w-[200px]">
          <CompanyList companies={clientComps[row.original.Client_ID] || []} />
        </div>
      )
    },
    { Header: "Create Date", accessor: "Create_Date", Cell: ({ value }) => formatDate(value) },
    { Header: "Last Updated", accessor: "Last_Updated", Cell: ({ value }) => formatDate(value) },
    { Header: "Client Status", accessor: "Client_Status" },
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) => (
        <div className="flex items-center space-x-2">
          <Button color="light" size="xs" onClick={() => {
            setEditingClient(row.original);
            setShowClientModal(true);
          }}>
            Edit
          </Button>
          <Button color="failure" size="xs" onClick={() => handleDeleteClient(row.original.Client_ID)}>
            Delete
          </Button>
          <Button color="success" size="xs" onClick={() => {
            setCurrentClientId(row.original.Client_ID);
            setShowClientCompModal(true);
          }}>
            Manage Companies
          </Button>
        </div>
      ),
    },
  ], [clientComps, handleDeleteClient]);

  const filteredClients = useMemo(() => {
    return clients.filter(client => {
      const searchLower = searchTerm.toLowerCase();
      const clientCompanies = clientComps[client.Client_ID] || [];
      const companyMatch = clientCompanies.some(company => 
        company.Company_Name?.toLowerCase().includes(searchLower) ||
        company.Company_ID?.toLowerCase().includes(searchLower)
      );
      
      return (
        client.Client_ID?.toLowerCase().includes(searchLower) ||
        client.Client_Name?.toLowerCase().includes(searchLower) ||
        client.Client_Type?.toLowerCase().includes(searchLower) ||
        client.Client_Status?.toLowerCase().includes(searchLower) ||
        companyMatch
      );
    });
  }, [clients, clientComps, searchTerm]);

  return (
    <div className="space-y-6 p-6 bg-white rounded-lg shadow">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Client Management</h2>
          <p className="mt-1 text-sm text-gray-600">
            Displaying {filteredClients.length} clients
          </p>
        </div>
        <Button 
          color="blue"
          size="lg"
          onClick={() => {
            setEditingClient(null);
            setShowClientModal(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Client
        </Button>
      </div>

      {error && <ErrorAlert message={error} className="mt-4" />}

      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Search clients and companies..."
          className="pl-10 pr-4 py-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="overflow-hidden border border-gray-200 rounded-lg">
          <AdminTable
            columns={clientColumns}
            data={filteredClients}
            pageSize={10}
          />
        </div>
      )}

      <ClientModal
        show={showClientModal}
        onClose={() => {
          setShowClientModal(false);
          setEditingClient(null);
        }}
        client={editingClient}
        onSave={(clientData) => {
          if (editingClient) {
            handleUpdateClient(clientData);
          } else {
            handleAddClient(clientData);
          }
          setShowClientModal(false);
          setEditingClient(null);
        }}
      />

      <ClientCompanyModal
        show={showClientCompModal}
        onClose={() => setShowClientCompModal(false)}
        clientId={currentClientId}
        clientComps={clientComps[currentClientId] || []}
        onSave={handleUpdateOrCreateComp}
        onDelete={handleDeleteClientComp}
      />
    </div>
  );
};

export default React.memo(ClientManagement);
