import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ParetoChart = ({
  Data_List,
  Chart_Width = 300,
  Chart_Height = 300,
  Bar_Color = '#3b82f6',
  Line_Color = '#ef4444',
  Show_Percentages = true,
  Is_Animated = true
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 60, Bottom: 60, Left: 60 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Pareto Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Sort data by value in descending order
    const Sorted_Data = [...Data_List].sort((A, B) => B.Value - A.Value);

    // Calculate cumulative percentages
    const Total_Value = d3.sum(Sorted_Data, Item => Item.Value);
    let Cumulative_Value = 0;
    const Enriched_Data = Sorted_Data.map(Item => {
      Cumulative_Value += Item.Value;
      return {
        ...Item,
        Percentage: (Item.Value / Total_Value) * 100,
        Cumulative_Percentage: (Cumulative_Value / Total_Value) * 100
      };
    });

    // Create scales
    const X_Scale = d3.scaleBand()
      .domain(Enriched_Data.map(Item => Item.Label))
      .range([0, Inner_Width])
      .padding(0.3);

    const Y_Scale_Left = d3.scaleLinear()
      .domain([0, d3.max(Enriched_Data, Item => Item.Value)])
      .range([Inner_Height, 0])
      .nice();

    const Y_Scale_Right = d3.scaleLinear()
      .domain([0, 100])
      .range([Inner_Height, 0]);

    // Create line generator
    const Line_Generator = d3.line()
      .x(Item => X_Scale(Item.Label) + X_Scale.bandwidth() / 2)
      .y(Item => Y_Scale_Right(Item.Cumulative_Percentage));

    // Add bars
    const Bars = SVG.selectAll(".bar")
      .data(Enriched_Data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", Item => X_Scale(Item.Label))
      .attr("width", X_Scale.bandwidth())
      .attr("y", Inner_Height)
      .attr("height", 0)
      .attr("fill", Bar_Color);

    if (Is_Animated) {
      Bars.transition()
        .duration(1000)
        .attr("y", Item => Y_Scale_Left(Item.Value))
        .attr("height", Item => Inner_Height - Y_Scale_Left(Item.Value));
    } else {
      Bars.attr("y", Item => Y_Scale_Left(Item.Value))
        .attr("height", Item => Inner_Height - Y_Scale_Left(Item.Value));
    }

    // Add cumulative line
    const Path = SVG.append("path")
      .datum(Enriched_Data)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", Line_Color)
      .attr("stroke-width", 2);

    if (Is_Animated) {
      const Total_Length = Path.node().getTotalLength();
      Path.attr("stroke-dasharray", `${Total_Length} ${Total_Length}`)
        .attr("stroke-dashoffset", Total_Length)
        .attr("d", Line_Generator)
        .transition()
        .duration(1000)
        .attr("stroke-dashoffset", 0);
    } else {
      Path.attr("d", Line_Generator);
    }

    // Add axes
    const X_Axis = d3.axisBottom(X_Scale);
    const Y_Axis_Left = d3.axisLeft(Y_Scale_Left);
    const Y_Axis_Right = d3.axisRight(Y_Scale_Right).ticks(5).tickFormat(Value => `${Value}%`);

    SVG.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${Inner_Height})`)
      .call(X_Axis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    SVG.append("g")
      .attr("class", "y-axis-left")
      .call(Y_Axis_Left);

    SVG.append("g")
      .attr("class", "y-axis-right")
      .attr("transform", `translate(${Inner_Width},0)`)
      .call(Y_Axis_Right);

    // Add dots on line
    const Dots = SVG.selectAll(".dot")
      .data(Enriched_Data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", Item => X_Scale(Item.Label) + X_Scale.bandwidth() / 2)
      .attr("cy", Item => Y_Scale_Right(Item.Cumulative_Percentage))
      .attr("r", 4)
      .attr("fill", Line_Color);

    if (Is_Animated) {
      Dots.attr("opacity", 0)
        .transition()
        .duration(1000)
        .attr("opacity", 1);
    }

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    const Show_Tooltip = (Event, Item) => {
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>${Item.Label}</strong><br/>
        Value: ${Item.Value}<br/>
        Percentage: ${Item.Percentage.toFixed(1)}%<br/>
        Cumulative: ${Item.Cumulative_Percentage.toFixed(1)}%
      `)
        .style("left", `${Event.pageX}px`)
        .style("top", `${Event.pageY - 28}px`);
    };

    const Hide_Tooltip = () => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);
    };

    Bars.on("mouseover", Show_Tooltip)
      .on("mouseout", Hide_Tooltip);

    Dots.on("mouseover", Show_Tooltip)
      .on("mouseout", Hide_Tooltip);

  }, [Data_List, Chart_Width, Chart_Height, Bar_Color, Line_Color, Show_Percentages, Is_Animated]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

ParetoChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Bar_Color: PropTypes.string,
  Line_Color: PropTypes.string,
  Show_Percentages: PropTypes.bool,
  Is_Animated: PropTypes.bool
};

ParetoChart.Display_Name = 'ParetoChart';

export default ParetoChart;
