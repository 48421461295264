import React, { useState } from "react";
import { useContext } from "react";
import { AccountContext } from "../../contexts/Account";
import { Navigate, useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../../auth/UserPool";
import { Send_Email } from "../../global/SendMail";

const Reset = () => {
  const [Email_Address, setEmailAddress] = useState("");
  const [Verification_Code, setVerificationCode] = useState("");
  const [Is_Code_Sent, setIsCodeSent] = useState(false);
  const [Error_Message, setErrorMessage] = useState(null);
  const [Password_Check, setPasswordCheck] = useState("");
  const [Password_Value, setPasswordValue] = useState("");

  const { Reset_Password, Confirm_Password } = useContext(AccountContext);
  const Navigate = useNavigate();

  const Handle_Submit = (Event) => {
    Event.preventDefault();

    const User = new CognitoUser({
      Username: Email_Address,
      Pool: Pool,
    });

    User.forgotPassword({
      onSuccess: (Response_Data) => {
        console.log(Response_Data);
        setIsCodeSent(true);
      },
      onFailure: (Error) => {
        console.error(Error);
        setErrorMessage(Error.message);
      },
    });

    console.log("submitted");
  };

  const Handle_New_Password_Submit = (Event) => {
    Event.preventDefault();

    const User = new CognitoUser({
      Username: Email_Address,
      Pool: Pool,
    });

    User.confirmPassword(Verification_Code, Password_Value, {
      onSuccess: (Response_Data) => {
        console.log(Response_Data);
        Navigate("/signin");
      },
      onFailure: (Error) => {
        console.error("error", Error);
        setErrorMessage(Error.message);
      },
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-4">
      <div className="max-w-4xl w-full text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Reset Password</h1>
        <p className="text-xl text-gray-600">Enter your email to reset your password</p>
      </div>
      
      <div className="w-full max-w-md mx-auto">
        {!Is_Code_Sent ? (
          <form onSubmit={Handle_Submit}>
            <div className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7">
              <h3 className="mb-6 text-2xl font-medium text-center">
                Request Password Reset
              </h3>
              <input
                type="email"
                name="email"
                className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                placeholder="Email address"
                value={Email_Address}
                onChange={(Event) => setEmailAddress(Event.target.value)}
                required
              />
              {Error_Message && <div className="text-md text-red-600 mb-4">{Error_Message}</div>}
              <div className="block">
                <button
                  type="submit"
                  className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Send Reset Code
                </button>
              </div>
              <p className="w-full mt-4 text-sm text-center text-gray-500">
                Remember your password?{' '}
                <a href="/" className="text-blue-500 hover:text-blue-600 underline">
                  Sign in here
                </a>
              </p>
            </div>
          </form>
        ) : (
          <form onSubmit={Handle_New_Password_Submit}>
            <div className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7">
              <h3 className="mb-6 text-2xl font-medium text-center">
                Set New Password
              </h3>
              <input
                type="email"
                name="email"
                className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                placeholder="Email address"
                value={Email_Address}
                onChange={(Event) => setEmailAddress(Event.target.value)}
                required
              />
              <input
                type="text"
                name="verification_code"
                className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                placeholder="Verification Code"
                value={Verification_Code}
                onChange={(Event) => setVerificationCode(Event.target.value)}
                required
              />
              <input
                type="password"
                name="password"
                className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                placeholder="New Password"
                value={Password_Value}
                onChange={(Event) => setPasswordValue(Event.target.value)}
                required
              />
              {Error_Message && <div className="text-md text-red-600 mb-4">{Error_Message}</div>}
              <div className="block">
                <button
                  type="submit"
                  className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Reset Password
                </button>
              </div>
              <p className="w-full mt-4 text-sm text-center text-gray-500">
                Need a new code?{' '}
                <button
                  type="button"
                  onClick={Handle_Submit}
                  className="text-blue-500 hover:text-blue-600 underline"
                >
                  Request another code
                </button>
              </p>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

Reset.Display_Name = 'Reset';

export default Reset;
