import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

const SparkChart = ({ Chart_Title, Chart_Value, Value_Change, Chart_Data, Chart_Color = '#008FFB' }) => {
    const Chart_Options = useMemo(() => ({
        chart: {
            type: 'line',
            sparkline: {
                enabled: true
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        colors: [Chart_Color],
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: () => ''
                }
            },
            marker: {
                show: false
            }
        }
    }), [Chart_Color]);

    const Chart_Series = useMemo(() => [{
        name: Chart_Title,
        data: Array.isArray(Chart_Data) ? Chart_Data : []
    }], [Chart_Data, Chart_Title]);

    // Debug log
    console.log('SparkChart - Props:', {
        Chart_Title,
        Chart_Value,
        Value_Change,
        Data_Length: Chart_Data?.length,
        Data_Sample: Chart_Data?.[0]
    });

    const Change_Color = useMemo(() => {
        if (!Value_Change) return 'text-gray-500';
        const Numeric_Change = parseFloat(Value_Change);
        if (isNaN(Numeric_Change)) return 'text-gray-500';
        return Numeric_Change > 0 ? 'text-green-500' : Numeric_Change < 0 ? 'text-red-500' : 'text-gray-500';
    }, [Value_Change]);

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <div className="flex justify-between items-start mb-2">
                <div>
                    <h3 className="text-sm font-medium text-gray-500">{Chart_Title}</h3>
                    <p className="mt-1 text-xl font-semibold">{Chart_Value || '0'}</p>
                </div>
                <div className={`text-sm ${Change_Color}`}>
                    {Value_Change || '0%'}
                </div>
            </div>
            <div className="h-16">
                {Array.isArray(Chart_Data) && Chart_Data.length > 0 ? (
                    <Chart
                        options={Chart_Options}
                        series={Chart_Series}
                        type="line"
                        height="100%"
                    />
                ) : (
                    <div className="flex items-center justify-center h-full text-gray-400">
                        No data available
                    </div>
                )}
            </div>
        </div>
    );
};

SparkChart.displayName = 'SparkChart';

export default React.memo(SparkChart);
