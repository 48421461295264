import { API_Client, API_Error } from '../core/apiClient';
import { API_ENDPOINTS } from '../core/constants';

export const Employee_API = {
  getEmployees: async (Company_ID, Department_ID, Work_Location_ID, Options = {}) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }

    console.log('Employee_API: Getting employees', { Company_ID, Department_ID, Work_Location_ID, Options });
    
    // Use params for GET request
    return await API_Client.get(API_ENDPOINTS.EMPLOYEE.BASE, {
      params: {
        Company_ID,
        Department_ID,
        Work_Location_ID,
        ...Options
      }
    });
  },

  getEmployee: async (Company_ID, Employee_ID, Options = {}) => {
    if (!Company_ID || !Employee_ID) {
      throw new API_Error('Company ID and Employee ID are required', 'MISSING_REQUIRED_FIELDS');
    }

    console.log('Employee_API: Getting employee details', { Company_ID, Employee_ID });
    return await API_Client.get(`${API_ENDPOINTS.EMPLOYEE.BASE}/${Company_ID}/${Employee_ID}`, {
      params: Options
    });
  },

  createEmployee: async (Employee_Data) => {
    if (!Employee_Data.Company_ID || !Employee_Data.Email_Address) {
      throw new API_Error('Company ID and Email Address are required', 'MISSING_REQUIRED_FIELDS');
    }

    console.log('Employee_API: Creating employee', {
      Company_ID: Employee_Data.Company_ID,
      Email: Employee_Data.Email_Address
    });

    // Use request body for POST
    return await API_Client.post(API_ENDPOINTS.EMPLOYEE.BASE, Employee_Data);
  },

  updateEmployee: async (Company_ID, Employee_ID, Employee_Data) => {
    if (!Company_ID || !Employee_ID) {
      throw new API_Error('Company ID and Employee ID are required', 'MISSING_REQUIRED_FIELDS');
    }

    console.log('Employee_API: Updating employee', { Company_ID, Employee_ID });
    // Use request body for PUT
    return await API_Client.put(`${API_ENDPOINTS.EMPLOYEE.BASE}/${Company_ID}/${Employee_ID}`, Employee_Data);
  },

  deleteEmployee: async (Company_ID, Employee_ID) => {
    if (!Company_ID || !Employee_ID) {
      throw new API_Error('Company ID and Employee ID are required', 'MISSING_REQUIRED_FIELDS');
    }

    console.log('Employee_API: Deleting employee', { Company_ID, Employee_ID });
    // Use params for DELETE
    return await API_Client.delete(`${API_ENDPOINTS.EMPLOYEE.BASE}/${Company_ID}/${Employee_ID}`, {
      params: { Company_ID }
    });
  },

  // Performance metrics
  getEmployeeMetrics: async (Company_ID, Employee_ID) => {
    if (!Company_ID || !Employee_ID) {
      throw new API_Error('Company ID and Employee ID are required', 'MISSING_REQUIRED_FIELDS');
    }

    console.log('Employee_API: Getting employee metrics', { Company_ID, Employee_ID });
    return await API_Client.get(`${API_ENDPOINTS.EMPLOYEE.METRICS}/${Company_ID}/${Employee_ID}`);
  },

  // Batch operations
  updateEmployeesBatch: async (Company_ID, Updates) => {
    if (!Company_ID || !Array.isArray(Updates)) {
      throw new API_Error('Company ID and Updates array are required', 'MISSING_REQUIRED_FIELDS');
    }

    console.log('Employee_API: Batch updating employees', { Company_ID, Update_Count: Updates.length });
    // Use request body for PUT
    return await API_Client.put(`${API_ENDPOINTS.EMPLOYEE.BASE}/batch`, {
      Company_ID,
      Updates
    });
  }
};

export { Structure_API as Employee_Performance_API } from '../employee/performance';
