import React, { useContext, useState, useEffect} from 'react';
import { EmployeeContext} from '../../contexts/EmployeeContext';
import { AccountContext} from '../../contexts/Account';
import { Modal, Button, Label, Textarea, Card} from 'flowbite-react';
import { workflowApi} from '../../api';

const ApprovalActionModal = ({ Action_Data, On_Close}) => {
    const { Employees_List } = useContext(EmployeeContext);
    const [Comment_Text, setCommentText] = useState('');
    const [Action_History, setActionHistory] = useState([]);
    const [User_Email, setUserEmail] = useState('');
    const { Get_Session } = useContext(AccountContext);
    const [Actions_List, setActionsList] = useState([]);
    const [Workflows_Map, setWorkflowsMap] = useState({});

    useEffect(() => {
        const Fetch_Modal_Data = async () => {
            try {
                const Session = await Get_Session();
                setUserEmail(Session.idToken.payload.email);

                const [Actions_Response, Workflows_Response] = await Promise.all([
                    workflowApi.getActions(Action_Data.Company_ID),
                    workflowApi.getWorkflows(Action_Data.Company_ID),
                ]);
                const Workflows_Data_Map = Workflows_Response.reduce((Accumulator, Workflow) => ({
                    ...Accumulator,
                    [Workflow.Action_Group_ID]: Workflow,
                }), {});
                setActionsList(Actions_Response);
                setWorkflowsMap(Workflows_Data_Map);

                console.log('Workflows:', Workflows_Data_Map);

                const History_Response = await workflowApi.getCompletedActions(Action_Data.Company_ID, Action_Data.Employee_ID);
                const Formatted_History = History_Response.map(Item => ({
                    ...Item,
                    User_Email: Item.user_id,
                    Comments: Item.Approval_Notes,
                    Completed: Item.Completed,
                }));
                setActionHistory(Formatted_History || []);
            } catch (Error) {
                console.error('Error fetching data:', Error);
                setActionHistory([]);
            }
        };

        if (Action_Data.Employee_ID) {
            Fetch_Modal_Data();
        }
    }, [Action_Data.Company_ID, Action_Data.Employee_ID, Get_Session]);

    const Format_Date = (Date_String) => {
        return Date_String ? new Date(Date_String).toLocaleDateString('en-US') : 'N/A';
    };

    const Employee_Details = Employees_List.find(Employee => Employee.Employee_ID === Action_Data.Employee_ID);

    const Handle_Approve = async () => {
        try {
            const Next_Approval_Step = Action_Data.Approval_Step + 1;
            const Active_Approver = Workflows_Map[Action_Data.action_group_id]?.[`Approval_Group_${Next_Approval_Step}`];
    
            const Approval_Payload = {
                Company_Id: Action_Data.Company_ID,
                Action_GUID: Action_Data.Action_GUID,
                Employee_Id: Action_Data.Employee_ID,
                Work_Location_Id: Action_Data.Work_Location_ID,
                Department_Id: Action_Data.Department_ID,
                Job_Id: Action_Data.Job_ID,
                Action_Id: Action_Data.Action_ID,
                Action_Init_Date: new Date().toISOString(),
                user_id: User_Email,
                Approval_Step: Next_Approval_Step,
                Approval_Step_Date: new Date().toISOString(),
                Next_Step: Active_Approver === 'END' ? null : Next_Approval_Step + 1,
                Approval_Notes: Comment_Text,
                action_group_id: Action_Data.action_group_id,
                active_approver: Active_Approver,
                Completed: Active_Approver === 'END' ? true : null,
                Completion_Date: Active_Approver === 'END' ? new Date().toISOString() : null,
                TimeStamp: new Date().toISOString(),
            };
    
            await workflowApi.advanceAction(Approval_Payload);
            alert('Action approved successfully!');
            On_Close();
        } catch (Error) {
            console.error('Error approving action:', Error);
            alert('Failed to approve action.');
        }
    };

    const Handle_Reject = async () => {
        try {
            const Rejection_Payload = {
                Company_Id: Action_Data.Company_ID,
                Action_GUID: Action_Data.Action_GUID,
                Employee_Id: Action_Data.Employee_ID,
                Work_Location_Id: Employee_Details?.Work_Location_ID,
                Department_Id: Employee_Details?.Department_ID,
                Job_Id: Employee_Details?.Job_ID,
                Action_Id: Action_Data.Action_ID,
                Action_Init_Date: Action_Data.Action_Init_Date,
                user_id: User_Email,
                Approval_Step: Action_Data.Approval_Step,
                Approval_Step_Date: new Date().toISOString(),
                Next_Step: null,
                Approval_Notes: Comment_Text,
                action_group_id: Action_Data.action_group_id,
                Rejected: true,
                Rejected_Date: new Date().toISOString(),
                active_approver: null,
                timeStamp: new Date().toISOString(),
            };

            await workflowApi.rejectAction(Rejection_Payload);
            alert('Action rejected successfully!');
            On_Close();
        } catch (Error) {
            console.error('Error rejecting action:', Error);
            alert('Failed to reject action.');
        }
    };

    return (
        <Modal show={true} onClose={On_Close} size="xl">
            <Modal.Header>{Action_Data.Action_Name}</Modal.Header>
            <Modal.Body>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <p>Employee Name: {Employee_Details ? `${Employee_Details.First_Name} ${Employee_Details.Last_Name}` : 'N/A'}</p>
                        <p>Employee ID: {Employee_Details ? Employee_Details.Employee_ID : 'N/A'}</p>
                        <p>Annual Salary: {Employee_Details ? Employee_Details.Annual_Salary : 'N/A'}</p>
                        <p>Last Salary Update: {Employee_Details ? Format_Date(Employee_Details.Last_Salary_Adjustment_Date) : 'N/A'}</p>
                        <p>Hire Date: {Employee_Details ? Format_Date(Employee_Details.Date_of_Hire) : 'N/A'}</p>
                    </div>
                    <div>
                        <p><strong>Regrettable:</strong> {Action_Data.Is_Regrettable ? 'Yes' : 'No'}</p>
                        <p><strong>Regret Comment:</strong> {Action_Data.Regret_Comment || 'N/A'}</p>
                        <p><strong>Additional Comments:</strong> {Action_Data.Approval_Notes || 'N/A'}</p>
                    </div>
                </div>
                <div className="mt-4">
                    <Label htmlFor="comments">Comments</Label>
                    <Textarea
                        id="comments"
                        value={Comment_Text}
                        onChange={(Event) => setCommentText(Event.target.value)}
                        placeholder="Add comments for approval/rejection"
                    />
                </div>

                {Action_History && Action_History.length > 0 && (
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold">Action History</h3>
                        {Action_History.map((History_Item, Index) => (
                            <Card key={Index} className="mb-4">
                                <p>
                                    {History_Item.Action_Name} - {History_Item.Completed ? 'Completed' : 'Approved'} on: {Format_Date(History_Item.Completion_Date || History_Item.Approval_Step_Date)}
                                </p>
                                <p>User: {History_Item.User_Email}</p>
                                <p>Comments: {History_Item.Comments || 'N/A'}</p>
                            </Card>
                        ))}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button color="success" onClick={Handle_Approve}>Approve</Button>
                <Button color="failure" onClick={Handle_Reject}>Reject</Button>
                <Button color="gray" onClick={On_Close}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

ApprovalActionModal.Display_Name = 'ApprovalActionModal';

export default ApprovalActionModal;
