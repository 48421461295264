import React, { useState, useMemo } from "react";
import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSearch } from "@fortawesome/free-solid-svg-icons";
import AdminTable from "../Components/AdminTable";
import UserModal from "./UserModal";

const formatDate = (date) => {
  if (!date) return '-';
  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return '-';
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
  } catch (err) {
    return '-';
  }
};

const UserManagement = ({ 
  users = [], 
  loading = false, 
  handleAddUser, 
  handleUpdateUser, 
  handleDeleteUser, 
  clients = [] 
}) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const userColumns = useMemo(() => [
    { Header: "Email", accessor: "Email_Address" },
    { Header: "First Name", accessor: "First_Name" },
    { Header: "Last Name", accessor: "Last_Name" },
    { Header: "Role", accessor: "Role" },
    { Header: "Client ID", accessor: "Client_ID" },
    { Header: "Company ID", accessor: "Company_ID" },
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) => (
        <div className="flex items-center space-x-2">
          <Button color="light" size="xs" onClick={() => {
            setEditingUser(row.original);
            setShowUserModal(true);
          }}>
            Edit
          </Button>
          <Button color="failure" size="xs" onClick={() => handleDeleteUser(row.original.Email_Address)}>
            Delete
          </Button>
        </div>
      ),
    },
  ], [handleDeleteUser]);

  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      const searchLower = searchTerm.toLowerCase();
      return (
        user.Email_Address?.toLowerCase().includes(searchLower) ||
        user.First_Name?.toLowerCase().includes(searchLower) ||
        user.Last_Name?.toLowerCase().includes(searchLower) ||
        user.Role?.toLowerCase().includes(searchLower) ||
        user.Client_ID?.toLowerCase().includes(searchLower) ||
        user.Company_ID?.toLowerCase().includes(searchLower)
      );
    });
  }, [users, searchTerm]);

  return (
    <div className="space-y-6 p-6 bg-white rounded-lg shadow">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">User Management</h2>
          <p className="mt-1 text-sm text-gray-600">
            Displaying {filteredUsers.length} users
          </p>
        </div>
        <Button 
          color="blue"
          size="lg"
          onClick={() => {
            setEditingUser(null);
            setShowUserModal(true);
          }}
        >
          <FontAwesomeIcon icon={faUser} className="mr-2" />
          Add User
        </Button>
      </div>

      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Search users..."
          className="pl-10 pr-4 py-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="overflow-hidden border border-gray-200 rounded-lg">
          <AdminTable 
            columns={userColumns} 
            data={filteredUsers}
            pageSize={10}
          />
        </div>
      )}

      <UserModal
        show={showUserModal}
        onClose={() => {
          setShowUserModal(false);
          setEditingUser(null);
        }}
        user={editingUser}
        onSave={(userData) => {
          if (editingUser) {
            handleUpdateUser(userData);
          } else {
            handleAddUser(userData);
          }
          setShowUserModal(false);
          setEditingUser(null);
        }}
        clients={clients}
      />
    </div>
  );
};

export default React.memo(UserManagement);
