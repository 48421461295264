import React from 'react';
import { Modal, Button, Select, Spinner } from 'flowbite-react';

const ClientCompanyModal = ({
  showModal,
  setShowModal,
  clients = { data: { Records: [] } },  // Default with normalized structure
  companies = { data: { Records: [] } }, // Default with normalized structure
  selectedClientId,
  selectedCompanyId,
  handleClientChange,
  handleCompanyChange,
  handleConfirm,
  isLoading,
  error,
  allowSuperAdmin = false
}) => {
  // Safely access Records arrays with fallbacks
  const clientList = clients?.data?.Records || [];
  const companyList = companies?.data?.Records || [];

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)} size="md">
      <Modal.Header>Select Client and Company</Modal.Header>
      <Modal.Body>
        {error && (
          <div className="text-red-500 mb-4">
            {error}
          </div>
        )}
        <div className="space-y-4">
          <div>
            <label htmlFor="client" className="block mb-2 text-sm font-medium text-gray-900">
              Select Client
            </label>
            <Select
              id="client"
              value={selectedClientId || ""}
              onChange={handleClientChange}
              disabled={isLoading}
            >
              <option value="">Select a client...</option>
              {clientList.map((client) => (
                <option key={client.Client_ID} value={client.Client_ID}>
                  {client.Client_Name}
                </option>
              ))}
            </Select>
          </div>

          {selectedClientId && (
            <div>
              <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900">
                Select Company
              </label>
              <Select
                id="company"
                value={selectedCompanyId || ""}
                onChange={handleCompanyChange}
                disabled={isLoading}
              >
                <option value="">Select a company...</option>
                {companyList.map((company) => (
                  <option key={company.Company_ID} value={company.Company_ID}>
                    {company.Company_Name}
                  </option>
                ))}
              </Select>
            </div>
          )}

          {isLoading && (
            <div className="flex justify-center">
              <Spinner size="md" />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button
          color="blue"
          onClick={handleConfirm}
          disabled={!selectedClientId || !selectedCompanyId || isLoading}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClientCompanyModal;
