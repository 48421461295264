import React, { createContext, useState, useEffect, useCallback } from 'react';
import { analyticsApi } from '../api';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
    const [state, setState] = useState({
        powerScoreMetrics: null,
        structureMetrics: null,
        pmsCategories: null,
        metricAnalytics: null,
        actionAnalytics: null,
        approvalAnalytics: null,
        correlations: null,
        predictions: null,
        isLoading: true,
        error: null,
        queryContext: {
            Request_ID: '',
            Timestamp: ''
        }
    });

    // Fetch all analytics data
    const fetchAnalytics = useCallback(async (Company_ID) => {
        setState(prev => ({ ...prev, isLoading: true }));
        try {
            const [
                powerScores,
                structure,
                pmsCategories,
                metrics,
                actions,
                approvals,
                correlations,
                predictions
            ] = await Promise.all([
                analyticsApi.getPowerScores(Company_ID),
                analyticsApi.getStructureAnalytics(Company_ID),
                analyticsApi.getPMSCategories(Company_ID),
                analyticsApi.getMetricAnalytics(Company_ID),
                analyticsApi.getActionAnalytics(Company_ID),
                analyticsApi.getApprovalAnalytics(Company_ID),
                analyticsApi.getCorrelations(Company_ID),
                analyticsApi.getPredictions(Company_ID)
            ]);

            setState(prev => ({
                ...prev,
                powerScoreMetrics: powerScores.Power_Score_Metrics,
                structureMetrics: structure.Structure_Metrics,
                pmsCategories: pmsCategories.PMS_Categories,
                metricAnalytics: metrics.Metric_Analytics,
                actionAnalytics: actions.Action_Analytics,
                approvalAnalytics: approvals.Approval_Analytics,
                correlations: correlations.Impact_Analysis,
                predictions: predictions.Predictions,
                isLoading: false,
                error: null,
                queryContext: {
                    Request_ID: `analytics_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                }
            }));
        } catch (error) {
            setState(prev => ({
                ...prev,
                error: {
                    message: 'Failed to fetch analytics data',
                    errors: [error.message]
                },
                isLoading: false
            }));
        }
    }, []);

    // Get chart data based on type
    const getChartData = useCallback((chartType, options = {}) => {
        switch (chartType) {
            case 'power-score-distribution':
                return {
                    Data_List: Object.entries(state.powerScoreMetrics?.Overall?.Distribution || {})
                        .map(([range, count]) => ({
                            Label: range,
                            Value: count
                        }))
                };

            case 'pms-category-distribution':
                return {
                    Data_List: Object.entries(state.pmsCategories?.Distribution || {})
                        .map(([category, data]) => ({
                            Label: category,
                            Value: data.Count,
                            Power_Score: data.Average_Power_Score
                        }))
                };

            case 'workflow-metrics':
                return {
                    Data_List: [
                        {
                            Label: 'Total Actions',
                            Value: state.actionAnalytics?.Volume_Metrics?.Total_Actions
                        },
                        {
                            Label: 'Active Actions',
                            Value: state.actionAnalytics?.Volume_Metrics?.Active_Actions
                        },
                        {
                            Label: 'Completed Actions',
                            Value: state.actionAnalytics?.Volume_Metrics?.Completed_Actions
                        }
                    ]
                };

            case 'performance-metrics':
                return {
                    Data_List: Object.entries(state.metricAnalytics?.Metric_Types || {})
                        .map(([metric, data]) => ({
                            Label: metric,
                            Value: data.Average,
                            Trend: data.Trend
                        }))
                };

            case 'correlation-impact':
                return {
                    Data_List: Object.entries(state.correlations?.Power_Score_Correlations || {})
                        .map(([factor, value]) => ({
                            Label: factor,
                            Value: value * 100
                        }))
                };

            default:
                return { Data_List: [] };
        }
    }, [state]);

    // Get predictions for a specific metric
    const getPredictions = useCallback((metricType) => {
        switch (metricType) {
            case 'power-score':
                return state.predictions?.Power_Score_Trends;
            case 'pms-category':
                return state.predictions?.PMS_Category_Shifts;
            case 'workflow':
                return state.predictions?.Workflow_Optimization;
            default:
                return null;
        }
    }, [state.predictions]);

    const contextValue = {
        ...state,
        fetchAnalytics,
        getChartData,
        getPredictions
    };

    return (
        <AnalyticsContext.Provider value={contextValue}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => {
    const context = React.useContext(AnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
};
