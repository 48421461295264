const Parse_Currency_Value = (Value) => {
    if (typeof Value === 'number') return Value;
    if (!Value) return 0;
    return parseFloat(Value.replace(/[^\d.-]/g, '')) || 0;
};

const Parse_Numeric_Value = (Value) => {
    if (typeof Value === 'number') return Value;
    if (!Value) return 0;
    return parseInt(Value, 10) || 0;
};

export const prepareChartDataCompany = (Metrics_Data) => {
    if (!Array.isArray(Metrics_Data) || !Metrics_Data.length) {
        console.log('prepareChartDataCompany - No metrics data:', { Metrics_Data });
        return {
            Chart_Data: {},
            Latest_Metrics: null,
            Previous_Metrics: null
        };
    }

    // Debug log
    console.log('prepareChartDataCompany - Processing metrics:', {
        Count: Metrics_Data.length,
        Sample: Metrics_Data[0]
    });

    // Sort metrics by Period_Date
    const Sorted_Metrics = [...Metrics_Data].sort((a, b) => {
        return new Date(a.Period_Date || 0) - new Date(b.Period_Date || 0);
    });

    // Use metrics directly with DB field names
    const Chart_Data = {
        Period_Date: Sorted_Metrics.map(m => m.Period_Date),
        Total_Risk: Sorted_Metrics.map(m => Parse_Currency_Value(m.Total_Risk)),
        Total_Risk_Critical: Sorted_Metrics.map(m => Parse_Currency_Value(m.Total_Risk_Critical)),
        Count_Total: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Total)),
        Count_Male: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Male)),
        Count_Female: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Female)),
        Count_Red: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Red)),
        Count_Yellow: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Yellow)),
        Count_Total_Critical: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Total_Critical)),
        Count_Male_Critical: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Male_Critical)),
        Count_Female_Critical: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Female_Critical)),
        Count_Red_Critical: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Red_Critical)),
        Count_Yellow_Critical: Sorted_Metrics.map(m => Parse_Numeric_Value(m.Count_Yellow_Critical))
    };

    // Latest and previous metrics are just the raw objects
    const Latest_Metrics = Sorted_Metrics[Sorted_Metrics.length - 1] || null;
    const Previous_Metrics = Sorted_Metrics[Sorted_Metrics.length - 2] || null;

    // Debug log
    console.log('prepareChartDataCompany - Processed data:', {
        Chart_Data_Sample: {
            Period_Date: Chart_Data.Period_Date?.[0],
            Total_Risk: Chart_Data.Total_Risk?.[0],
            Count_Total: Chart_Data.Count_Total?.[0]
        },
        Latest: Latest_Metrics,
        Previous: Previous_Metrics
    });

    return {
        Chart_Data,
        Latest_Metrics,
        Previous_Metrics
    };
};

export const calculateMetricChange = (Current_Value, Previous_Value, Value_Type = 'number') => {
    if (!Current_Value || !Previous_Value) return { Value: 0, Text: '0%' };

    let Parsed_Current_Value = Current_Value;
    let Parsed_Previous_Value = Previous_Value;

    if (Value_Type === 'currency') {
        Parsed_Current_Value = Parse_Currency_Value(Current_Value);
        Parsed_Previous_Value = Parse_Currency_Value(Previous_Value);
    } else {
        Parsed_Current_Value = Parse_Numeric_Value(Current_Value);
        Parsed_Previous_Value = Parse_Numeric_Value(Previous_Value);
    }

    if (Parsed_Previous_Value === 0) return { Value: 0, Text: '0%' };

    const Change_Value = ((Parsed_Current_Value - Parsed_Previous_Value) / Parsed_Previous_Value) * 100;
    return {
        Value: Change_Value,
        Text: `${Change_Value > 0 ? '+' : ''}${Change_Value.toFixed(1)}%`
    };
};

export const formatMetricValue = (Value, Value_Type = 'number') => {
    if (Value === null || Value === undefined) return '0';
    
    if (Value_Type === 'currency') {
        const Numeric_Value = Parse_Currency_Value(Value);
        return `$${Numeric_Value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`;
    }
    
    const Numeric_Value = Parse_Numeric_Value(Value);
    return Numeric_Value.toLocaleString('en-US');
};
