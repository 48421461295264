import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../contexts/Status";
import { AccountContext } from "../../contexts/Account";
import { usersApi } from "../../api";
import TopNavigation from "../../components/common/TopNavigation";
import UserManagement from "../../components/super/UserManagement";
import SuperClients from "./Clients";
import EmailConfiguration from "./EmailConfiguration";
import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faBuilding, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ClientCompanyModal from "../../components/ClientCompanyModal";
import ErrorAlert from "../../components/ErrorAlert";

const WelcomeSection = () => (
  <div className="flex flex-col items-center justify-center p-8">
    <h2 className="text-2xl font-semibold text-gray-700 mb-4">
      Welcome to the Super Admin Dashboard
    </h2>
    <p className="text-gray-600 text-center max-w-2xl">
      Please select a section from the navigation above to get started.
    </p>
  </div>
);

const UsersSection = () => {
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch users and clients in parallel
        const [usersResponse, clientsResponse] = await Promise.all([
          usersApi.getUsers(),
          usersApi.getClients()
        ]);

        const usersList = Array.isArray(usersResponse) ? usersResponse :
                         Array.isArray(usersResponse?.data) ? usersResponse.data :
                         usersResponse?.data?.Records || [];

        const clientsList = Array.isArray(clientsResponse) ? clientsResponse :
                          Array.isArray(clientsResponse?.data) ? clientsResponse.data :
                          clientsResponse?.data?.Records || [];

        setUsers(usersList);
        setClients(clientsList);
      } catch (error) {
        console.error('Failed to fetch users data:', error);
        setError("Failed to fetch users data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddUser = async (userData) => {
    try {
      setLoading(true);
      await usersApi.createUser(userData);
      const response = await usersApi.getUsers();
      const usersList = Array.isArray(response) ? response :
                       Array.isArray(response?.data) ? response.data :
                       response?.data?.Records || [];
      setUsers(usersList);
    } catch (error) {
      console.error('Failed to add user:', error);
      setError("Failed to add user");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async (userData) => {
    try {
      setLoading(true);
      await usersApi.updateUser(userData.Email_Address, userData);
      const response = await usersApi.getUsers();
      const usersList = Array.isArray(response) ? response :
                       Array.isArray(response?.data) ? response.data :
                       response?.data?.Records || [];
      setUsers(usersList);
    } catch (error) {
      console.error('Failed to update user:', error);
      setError("Failed to update user");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (email) => {
    try {
      setLoading(true);
      await usersApi.deleteUser(email);
      const response = await usersApi.getUsers();
      const usersList = Array.isArray(response) ? response :
                       Array.isArray(response?.data) ? response.data :
                       response?.data?.Records || [];
      setUsers(usersList);
    } catch (error) {
      console.error('Failed to delete user:', error);
      setError("Failed to delete user");
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <ErrorAlert message={error} onClose={() => setError(null)} />;
  }

  return (
    <UserManagement
      users={users}
      clients={clients}
      loading={loading}
      handleAddUser={handleAddUser}
      handleUpdateUser={handleUpdateUser}
      handleDeleteUser={handleDeleteUser}
    />
  );
};

const DashboardSuper = () => {
  const navigate = useNavigate();
  const { Is_Super_Admin, Update_Client_And_Company } = useContext(StatusContext);
  const { Get_Session } = useContext(AccountContext);
  
  const [activeSection, setActiveSection] = useState('');
  const [error, setError] = useState(null);
  const [showClientModal, setShowClientModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  // Verify session on mount
  useEffect(() => {
    const verifySession = async () => {
      try {
        await Get_Session();
        if (!Is_Super_Admin) {
          navigate('/unauthorized');
        }
      } catch (error) {
        setError("Session verification failed. Please sign in again.");
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    verifySession();
  }, [Get_Session, Is_Super_Admin, navigate]);

  // Fetch clients when modal opens
  useEffect(() => {
    const fetchClients = async () => {
      if (!showClientModal) return;
      
      try {
        setLoading(true);
        const response = await usersApi.getClients();
        const clientsList = Array.isArray(response) ? response :
                          Array.isArray(response?.data) ? response.data :
                          response?.data?.Records || [];
        setClients(clientsList);
      } catch (error) {
        console.error('Failed to fetch clients:', error);
        setError("Failed to fetch clients");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [showClientModal]);

  const handleClientChange = async (e) => {
    const clientId = e.target.value;
    setSelectedClientId(clientId);
    setSelectedCompanyId('');
    
    if (clientId) {
      try {
        setLoading(true);
        const response = await usersApi.getClientCompanies(clientId);
        const companiesList = Array.isArray(response) ? response :
                            Array.isArray(response?.data) ? response.data :
                            response?.data?.Records || [];
        setCompanies(companiesList);
      } catch (error) {
        console.error('Failed to fetch companies:', error);
        setError("Failed to fetch companies");
      } finally {
        setLoading(false);
      }
    } else {
      setCompanies([]);
    }
  };

  const handleCompanyChange = (e) => {
    setSelectedCompanyId(e.target.value);
  };

  const handleConfirm = async () => {
    if (selectedClientId && selectedCompanyId) {
      try {
        setLoading(true);
        // Get client and company names for display
        const selectedClient = clients.find(c => c.Client_ID === selectedClientId);
        const selectedCompany = companies.find(c => c.Company_ID === selectedCompanyId);
        
        console.log('Updating context:', {
          clientId: selectedClientId,
          clientName: selectedClient?.Client_Name,
          companyId: selectedCompanyId,
          companyName: selectedCompany?.Company_Name
        });

        // Update context and navigate
        await Update_Client_And_Company(selectedClientId, selectedCompanyId);
        setShowClientModal(false);
        console.log('Context updated, navigating to admin dashboard');
        navigate('/admin/dashboard', { replace: true });
      } catch (error) {
        console.error('Failed to update client and company:', error);
        setError(error.message || "Unable to update client and company. Please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please select both a client and a company.");
    }
  };

  const renderSection = () => {
    switch(activeSection) {
      case 'users':
        return <UsersSection />;
      case 'clients':
        return <SuperClients />;
      case 'email':
        return <EmailConfiguration />;
      default:
        return <WelcomeSection />;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // Format data for the modal
  const modalData = {
    clients: { data: { Records: clients }},
    companies: { data: { Records: companies }}
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <TopNavigation />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-8">Super Admin Dashboard</h1>
        {error && (
          <ErrorAlert 
            message={error} 
            onClose={() => setError(null)}
          />
        )}
        <div className="flex justify-center space-x-4 mb-8">
          <Button
            color={activeSection === 'users' ? "blue" : "light"}
            onClick={() => setActiveSection(activeSection === 'users' ? '' : 'users')}
          >
            <FontAwesomeIcon icon={faUsers} className="mr-2" />
            User Management
          </Button>
          <Button
            color={activeSection === 'clients' ? "blue" : "light"}
            onClick={() => setActiveSection(activeSection === 'clients' ? '' : 'clients')}
          >
            <FontAwesomeIcon icon={faBuilding} className="mr-2" />
            Client Management
          </Button>
          <Button
            color={activeSection === 'email' ? "blue" : "light"}
            onClick={() => setActiveSection(activeSection === 'email' ? '' : 'email')}
          >
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Email Configuration
          </Button>
          <Button
            color="green"
            onClick={() => setShowClientModal(true)}
          >
            Access Admin Dashboard
          </Button>
        </div>
        {renderSection()}
        <ClientCompanyModal 
          showModal={showClientModal}
          setShowModal={setShowClientModal}
          {...modalData}
          selectedClientId={selectedClientId}
          selectedCompanyId={selectedCompanyId}
          handleClientChange={handleClientChange}
          handleCompanyChange={handleCompanyChange}
          handleConfirm={handleConfirm}
          isLoading={loading}
          error={error}
          allowSuperAdmin={true}
        />
      </div>
    </div>
  );
};

export default DashboardSuper;
