import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { StatusContext } from '../../contexts/Status';
import Spinner from '../../global/Spinner';

const RoleRoute = ({ Allowed_Roles }) => {
  console.log('RoleRoute: Rendering', { Allowed_Roles });

  const { 
    Is_Super_Admin,
    User_Entitlements,
    Is_Loading,
    Auth_Status
  } = useContext(StatusContext);

  if (!Auth_Status) {
    console.log('RoleRoute: Not authenticated, redirecting to root');
    return <Navigate to="/" replace />;
  }

  if (Is_Loading) {
    console.log('RoleRoute: Loading state');
    return <Spinner />;
  }

  // Helper function to check if user has a specific role
  const Has_Role = (Role_Name) => {
    return User_Entitlements?.some(E => E.Roles[Role_Name]) || false;
  };

  // Map role names to their status
  const User_Roles = {
    'SuperAdmin': Is_Super_Admin,
    'Client Admin': Has_Role('Admin'),
    'Manager': Has_Role('Manager'),
    'Approver': Has_Role('Approver'),
    'Auditor': Has_Role('Auditor'),
    'HR': Has_Role('HR'),
    'Executive': Has_Role('Executive'),
    'Recorder': Has_Role('Recorder')
  };

  console.log('RoleRoute: User roles', User_Roles);

  const Has_Allowed_Role = Allowed_Roles.some(Role => User_Roles[Role]);

  console.log('RoleRoute: Has allowed role?', Has_Allowed_Role);

  // Super admin special case - redirect to super dashboard unless specifically allowed
  if (Is_Super_Admin && !Allowed_Roles.includes('SuperAdmin')) {
    console.log('RoleRoute: Redirecting SuperAdmin to /super/dashboard');
    return <Navigate to="/super/dashboard" replace />;
  }

  if (Has_Allowed_Role) {
    console.log('RoleRoute: User has allowed role, rendering content');
    return <Outlet />;
  }

  console.log('RoleRoute: User does not have required role, redirecting to unauthorized');
  return <Navigate to="/unauthorized" replace />;
};

RoleRoute.Display_Name = 'RoleRoute';

export default RoleRoute;
