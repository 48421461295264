import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Input
} from "@material-tailwind/react";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import UserTableRow from './UserTableRow';

const TABLE_HEAD = ["Email", "Client", "Company", "Roles", "Edit"];

const UserList = ({ 
  users, 
  onUpdateUser,
  currentPage,
  totalPages,
  onPageChange,
  searchQuery,
  onSearchChange 
}) => {
  const [editingUser, setEditingUser] = useState(null);
  const [editedRoles, setEditedRoles] = useState({});

  const handleEdit = (user) => {
    setEditingUser(user.Email_Address);
    setEditedRoles({
      Admin: user.Admin,
      Manager: user.Manager,
      Approver: user.Approver,
      Auditor: user.Auditor,
      HR: user.HR,
      Executive: user.Executive,
      Recorder: user.Recorder
    });
  };

  const handleSave = async (user) => {
    await onUpdateUser({
      ...user,
      ...editedRoles
    });
    setEditingUser(null);
    setEditedRoles({});
  };

  const handleRoleToggle = (role) => {
    setEditedRoles(prev => ({
      ...prev,
      [role]: !prev[role]
    }));
  };

  return (
    <Card className="h-full w-4/5">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              User list
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Click on the pencil to make changes, then click on it again to confirm.
            </Typography>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <div className="w-full md:w-72">
            <Input
              label="Search by Email Address"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="mt-4 w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}{" "}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              const isLast = index === users.length - 1;
              const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
              const isEditing = editingUser === user.Email_Address;

              return (
                <UserTableRow
                  key={user.Email_Address}
                  user={isEditing ? { ...user, ...editedRoles } : user}
                  isEditing={isEditing}
                  onEdit={() => handleEdit(user)}
                  onSave={() => handleSave(user)}
                  onRoleToggle={handleRoleToggle}
                  classes={classes}
                />
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {currentPage} of {totalPages}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default UserList;
