import React, { useState, useEffect } from 'react';
import { workflowApi } from '../../api';

const ModalEdit = ({ Action_Data, Company_ID, On_Close, On_Success }) => {
  const [Form_State, setFormState] = useState({
    Action_Name: '',
    Action_Description: '',
    Action_Type: 'Standard',
    Action_Group_ID: 'Free'
  });

  useEffect(() => {
    if (Action_Data) {
      setFormState({
        Action_Name: Action_Data.Action_Name,
        Action_Description: Action_Data.Action_Description,
        Action_Type: Action_Data.Action_Type || 'Standard',
        Action_Group_ID: Action_Data.Action_Group_ID || 'Free'
      });
    }
  }, [Action_Data]);

  const Handle_Form_Submit = async () => {
    try {
      await workflowApi.updateAction(Company_ID, Action_Data.Action_ID, {
        Action_Name: Form_State.Action_Name,
        Action_Description: Form_State.Action_Description,
        Action_Type: Form_State.Action_Type,
        Action_Group_ID: Form_State.Action_Group_ID
      });
      On_Success();
      On_Close();
    } catch (Error) {
      console.error('Error updating action:', Error);
    }
  };

  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-3xl font-semibold">Edit Action</h3>
          </div>
          <div className="relative p-6 flex-auto">
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Action Name</label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={Form_State.Action_Name}
                  onChange={(Event) => setFormState({ ...Form_State, Action_Name: Event.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Action Description</label>
                <textarea
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  rows={3}
                  value={Form_State.Action_Description}
                  onChange={(Event) => setFormState({ ...Form_State, Action_Description: Event.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Action Type</label>
                <select
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={Form_State.Action_Type}
                  onChange={(Event) => setFormState({ ...Form_State, Action_Type: Event.target.value })}
                >
                  <option value="Standard">Standard</option>
                  <option value="Custom">Custom</option>
                  <option value="System">System</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Action Group</label>
                <select
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={Form_State.Action_Group_ID}
                  onChange={(Event) => setFormState({ ...Form_State, Action_Group_ID: Event.target.value })}
                >
                  <option value="Free">Free</option>
                  <option value="Low">Low</option>
                  <option value="Med">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
            </form>
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={On_Close}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={Handle_Form_Submit}
            >
              Update Action
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalEdit.Display_Name = 'ModalEdit';

export default ModalEdit;
