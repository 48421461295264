import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const D3TimelineChart = ({
  Data_List,
  Chart_Width = 800,
  Chart_Height = 400,
  Margin = { Top: 40, Right: 40, Bottom: 40, Left: 150 },
  Row_Height = 50,
  Status_Colors = {
    'Completed': '#22c55e',
    'In_Progress': '#f59e0b',
    'Pending': '#9ca3af',
    'Blocked': '#ef4444'
  }
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Math.max(Chart_Height - Margin.Top - Margin.Bottom, Data_List.length * Row_Height);

    // Create SVG
    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Timeline Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Create scales
    const Time_Scale = d3.scaleTime()
      .domain([
        d3.min(Data_List, d => d.Start_Date),
        d3.max(Data_List, d => d.End_Date || new Date())
      ])
      .range([0, Inner_Width]);

    const Category_Scale = d3.scaleBand()
      .domain(Data_List.map(d => d.Category))
      .range([0, Inner_Height])
      .padding(0.2);

    // Add axes
    const X_Axis = d3.axisBottom(Time_Scale);
    SVG.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${Inner_Height})`)
      .call(X_Axis);

    const Y_Axis = d3.axisLeft(Category_Scale);
    SVG.append("g")
      .attr("class", "y-axis")
      .call(Y_Axis);

    // Add grid lines
    SVG.selectAll("grid-line")
      .data(Time_Scale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid-line")
      .attr("x1", d => Time_Scale(d))
      .attr("x2", d => Time_Scale(d))
      .attr("y1", 0)
      .attr("y2", Inner_Height)
      .style("stroke", "#e5e7eb")
      .style("stroke-dasharray", "4,4");

    // Create timeline bars
    const Timeline_Group = SVG.append("g")
      .attr("class", "timeline-group");

    Data_List.forEach(item => {
      const Bar_Height = Category_Scale.bandwidth();
      const Y_Position = Category_Scale(item.Category);

      // Add background bar
      Timeline_Group.append("rect")
        .attr("class", "timeline-bg")
        .attr("x", Time_Scale(item.Start_Date))
        .attr("y", Y_Position)
        .attr("width", Time_Scale(item.End_Date || new Date()) - Time_Scale(item.Start_Date))
        .attr("height", Bar_Height)
        .attr("rx", 4)
        .style("fill", "#f3f4f6");

      // Add progress bar
      if (item.Progress !== undefined) {
        Timeline_Group.append("rect")
          .attr("class", "timeline-progress")
          .attr("x", Time_Scale(item.Start_Date))
          .attr("y", Y_Position)
          .attr("width", (Time_Scale(item.End_Date || new Date()) - Time_Scale(item.Start_Date)) * (item.Progress / 100))
          .attr("height", Bar_Height)
          .attr("rx", 4)
          .style("fill", Status_Colors[item.Status] || Status_Colors.Pending);
      }

      // Add milestones
      if (item.Milestones) {
        item.Milestones.forEach(milestone => {
          Timeline_Group.append("circle")
            .attr("class", "milestone")
            .attr("cx", Time_Scale(milestone.Date))
            .attr("cy", Y_Position + Bar_Height / 2)
            .attr("r", 6)
            .style("fill", Status_Colors[milestone.Status] || Status_Colors.Pending)
            .style("stroke", "white")
            .style("stroke-width", 2);
        });
      }

      // Add item label
      Timeline_Group.append("text")
        .attr("class", "timeline-label")
        .attr("x", Time_Scale(item.Start_Date) + 8)
        .attr("y", Y_Position + Bar_Height / 2)
        .attr("dy", "0.35em")
        .style("fill", "#1f2937")
        .text(item.Label);
    });

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Timeline_Group.selectAll(".timeline-progress, .milestone")
      .on("mouseover", (Event, d) => {
        const Target = d3.select(Event.currentTarget);
        const Is_Milestone = Target.classed("milestone");
        const Parent_Data = Is_Milestone ? d : Data_List.find(item => 
          item.Category === d3.select(Event.currentTarget.parentNode)
            .datum()?.Category
        );

        Tooltip.transition()
          .duration(200)
          .style("opacity", 0.9);

        let Content = Is_Milestone ?
          `<strong>${d.Label}</strong><br/>
           Date: ${d3.timeFormat("%B %d, %Y")(d.Date)}<br/>
           Status: ${d.Status}` :
          `<strong>${Parent_Data.Label}</strong><br/>
           Progress: ${Parent_Data.Progress}%<br/>
           Status: ${Parent_Data.Status}`;

        Tooltip.html(Content)
          .style("left", `${Event.pageX + 10}px`)
          .style("top", `${Event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        Tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }, [Data_List, Chart_Width, Chart_Height, Margin, Row_Height, Status_Colors]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

D3TimelineChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Category: PropTypes.string.isRequired,
    Label: PropTypes.string.isRequired,
    Start_Date: PropTypes.instanceOf(Date).isRequired,
    End_Date: PropTypes.instanceOf(Date),
    Status: PropTypes.string.isRequired,
    Progress: PropTypes.number,
    Milestones: PropTypes.arrayOf(PropTypes.shape({
      Date: PropTypes.instanceOf(Date).isRequired,
      Label: PropTypes.string.isRequired,
      Status: PropTypes.string.isRequired
    }))
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Margin: PropTypes.shape({
    Top: PropTypes.number,
    Right: PropTypes.number,
    Bottom: PropTypes.number,
    Left: PropTypes.number
  }),
  Row_Height: PropTypes.number,
  Status_Colors: PropTypes.object
};

export default D3TimelineChart;
