import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BoxPlot = ({ 
  Data_List, 
  Chart_Width = 300, 
  Chart_Height = 300, 
  Chart_Color = '#3b82f6',
  Show_Outliers = true 
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 20, Right: 20, Bottom: 30, Left: 60 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Box Plot")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Calculate statistics for each group
    const Stats = Data_List.map(Group => {
      const Sorted_Values = Group.Values.sort(d3.ascending);
      const Q1 = d3.quantile(Sorted_Values, 0.25);
      const Median = d3.quantile(Sorted_Values, 0.5);
      const Q3 = d3.quantile(Sorted_Values, 0.75);
      const IQR = Q3 - Q1;
      const Min_Value = Q1 - 1.5 * IQR;
      const Max_Value = Q3 + 1.5 * IQR;
      const Outliers = Sorted_Values.filter(Value => Value < Min_Value || Value > Max_Value);
      
      return {
        Label: Group.Label,
        Q1,
        Median,
        Q3,
        IQR,
        Min: d3.min(Sorted_Values.filter(Value => Value >= Min_Value)),
        Max: d3.max(Sorted_Values.filter(Value => Value <= Max_Value)),
        Outliers
      };
    });

    // Create scales
    const X_Scale = d3.scaleBand()
      .domain(Data_List.map(Item => Item.Label))
      .range([0, Inner_Width])
      .padding(0.1);

    const Y_Scale = d3.scaleLinear()
      .domain([
        d3.min(Stats, Item => Math.min(Item.Min, ...Item.Outliers)),
        d3.max(Stats, Item => Math.max(Item.Max, ...Item.Outliers))
      ])
      .range([Inner_Height, 0])
      .nice();

    // Add boxes
    const Boxes = SVG.selectAll(".box")
      .data(Stats)
      .enter()
      .append("g")
      .attr("class", "box")
      .attr("transform", Item => `translate(${X_Scale(Item.Label)},0)`);

    // Add box rectangles
    Boxes.append("rect")
      .attr("x", 0)
      .attr("y", Item => Y_Scale(Item.Q3))
      .attr("width", X_Scale.bandwidth())
      .attr("height", Item => Y_Scale(Item.Q1) - Y_Scale(Item.Q3))
      .attr("fill", Chart_Color)
      .attr("opacity", 0.7)
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Add median lines
    Boxes.append("line")
      .attr("x1", 0)
      .attr("x2", X_Scale.bandwidth())
      .attr("y1", Item => Y_Scale(Item.Median))
      .attr("y2", Item => Y_Scale(Item.Median))
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    // Add whiskers
    Boxes.append("line")
      .attr("class", "whisker")
      .attr("x1", X_Scale.bandwidth() / 2)
      .attr("x2", X_Scale.bandwidth() / 2)
      .attr("y1", Item => Y_Scale(Item.Min))
      .attr("y2", Item => Y_Scale(Item.Max))
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Add whisker caps
    Boxes.append("line")
      .attr("class", "whisker-cap")
      .attr("x1", X_Scale.bandwidth() * 0.25)
      .attr("x2", X_Scale.bandwidth() * 0.75)
      .attr("y1", Item => Y_Scale(Item.Min))
      .attr("y2", Item => Y_Scale(Item.Min))
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    Boxes.append("line")
      .attr("class", "whisker-cap")
      .attr("x1", X_Scale.bandwidth() * 0.25)
      .attr("x2", X_Scale.bandwidth() * 0.75)
      .attr("y1", Item => Y_Scale(Item.Max))
      .attr("y2", Item => Y_Scale(Item.Max))
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Add outliers if enabled
    if (Show_Outliers) {
      Boxes.selectAll(".outlier")
        .data(Item => Item.Outliers.map(Value => ({ Value, Label: Item.Label })))
        .enter()
        .append("circle")
        .attr("class", "outlier")
        .attr("cx", X_Scale.bandwidth() / 2)
        .attr("cy", Item => Y_Scale(Item.Value))
        .attr("r", 3)
        .attr("fill", "red")
        .attr("opacity", 0.7);
    }

    // Add axes
    const X_Axis = d3.axisBottom(X_Scale);
    const Y_Axis = d3.axisLeft(Y_Scale);

    SVG.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${Inner_Height})`)
      .call(X_Axis);

    SVG.append("g")
      .attr("class", "y-axis")
      .call(Y_Axis);

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Boxes.on("mouseover", (Event, Item) => {
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>${Item.Label}</strong><br/>
        Min: ${Item.Min.toFixed(2)}<br/>
        Q1: ${Item.Q1.toFixed(2)}<br/>
        Median: ${Item.Median.toFixed(2)}<br/>
        Q3: ${Item.Q3.toFixed(2)}<br/>
        Max: ${Item.Max.toFixed(2)}<br/>
        ${Item.Outliers.length > 0 ? `Outliers: ${Item.Outliers.length}` : ''}
      `)
        .style("left", `${Event.pageX}px`)
        .style("top", `${Event.pageY - 28}px`);
    })
    .on("mouseout", () => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);
    });

  }, [Data_List, Chart_Width, Chart_Height, Chart_Color, Show_Outliers]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

BoxPlot.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Values: PropTypes.arrayOf(PropTypes.number).isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Chart_Color: PropTypes.string,
  Show_Outliers: PropTypes.bool
};

BoxPlot.Display_Name = 'BoxPlot';

export default BoxPlot;
