import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import D3SpiderChart from './D3SpiderChart';

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const D3FlowChart = ({
  Data,
  Chart_Width = 800,
  Chart_Height = 600,
  Margin = { Top: 40, Right: 40, Bottom: 40, Left: 40 },
  Node_Radius = 30,
  Status_Colors = {
    'Completed': '#22c55e',
    'In_Progress': '#f59e0b',
    'Pending': '#9ca3af',
    'Blocked': '#ef4444'
  },
  Show_Labels = true,
  Show_Status = true,
  Transition_Duration = 750
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data || !Data.nodes || !Data.links) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    // Create SVG
    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Flow Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Create force simulation
    const Simulation = d3.forceSimulation(Data.nodes)
      .force("link", d3.forceLink(Data.links).id(d => d.id).distance(150))
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(Inner_Width / 2, Inner_Height / 2))
      .force("collision", d3.forceCollide().radius(Node_Radius * 2));

    // Create arrow marker
    SVG.append("defs").selectAll("marker")
      .data(["arrow"])
      .enter().append("marker")
      .attr("id", d => d)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", Node_Radius + 10)
      .attr("refY", 0)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", "#9ca3af");

    // Create links
    const Links = SVG.append("g")
      .selectAll("path")
      .data(Data.links)
      .enter().append("path")
      .attr("class", "link")
      .attr("stroke", "#9ca3af")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("marker-end", "url(#arrow)");

    // Create nodes group
    const Nodes = SVG.append("g")
      .selectAll(".node")
      .data(Data.nodes)
      .enter().append("g")
      .attr("class", "node")
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));

    // Add node circles
    Nodes.append("circle")
      .attr("r", Node_Radius)
      .attr("fill", d => Status_Colors[d.status] || Status_Colors.Pending)
      .attr("stroke", "white")
      .attr("stroke-width", 2);

    // Add node labels
    if (Show_Labels) {
      Nodes.append("text")
        .attr("dy", Node_Radius + 20)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .text(d => d.label);
    }

    // Add status indicators
    if (Show_Status) {
      Nodes.append("text")
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("fill", "white")
        .style("font-weight", "bold")
        .text(d => d.completion_rate ? `${d.completion_rate}%` : '');
    }

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Nodes.on("mouseover", (Event, d) => {
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>${d.label}</strong><br/>
        Status: ${d.status}<br/>
        ${d.completion_rate ? `Completion Rate: ${d.completion_rate}%<br/>` : ''}
        ${d.average_time ? `Average Time: ${d.average_time} days<br/>` : ''}
        ${d.bottleneck ? '<strong>Bottleneck Detected</strong>' : ''}
      `)
        .style("left", `${Event.pageX + 10}px`)
        .style("top", `${Event.pageY - 28}px`);
    })
    .on("mouseout", () => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);
    });

    // Update positions on simulation tick
    Simulation.on("tick", () => {
      Links.attr("d", d => {
        const dx = d.target.x - d.source.x;
        const dy = d.target.y - d.source.y;
        const dr = Math.sqrt(dx * dx + dy * dy);
        return `M${d.source.x},${d.source.y}A${dr},${dr} 0 0,1 ${d.target.x},${d.target.y}`;
      });

      Nodes.attr("transform", d => `translate(${d.x},${d.y})`);
    });

    // Drag functions
    function dragstarted(Event) {
      if (!Event.active) Simulation.alphaTarget(0.3).restart();
      Event.subject.fx = Event.subject.x;
      Event.subject.fy = Event.subject.y;
    }

    function dragged(Event) {
      Event.subject.fx = Event.x;
      Event.subject.fy = Event.y;
    }

    function dragended(Event) {
      if (!Event.active) Simulation.alphaTarget(0);
      Event.subject.fx = null;
      Event.subject.fy = null;
    }

  }, [Data, Chart_Width, Chart_Height, Margin, Node_Radius, Status_Colors, Show_Labels, Show_Status, Transition_Duration]);

  return (
    <ChartContainer>
      <D3SpiderChart
        data={Data.nodes.map(node => ({
          Label: node.label,
          Values: {
            'Completion Rate': node.completion_rate || 0,
            'Average Time': node.average_time || 0,
            'Status': node.status
          }
        }))}
        Chart_Width={Chart_Width}
        Chart_Height={Chart_Height}
        Margin={Margin}
        Show_Legend={false}
        Show_Labels={false}
        Show_Tooltips={false}
      />
    </ChartContainer>
  );
};

D3FlowChart.propTypes = {
  Data: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      completion_rate: PropTypes.number,
      average_time: PropTypes.number,
      bottleneck: PropTypes.bool
    })).isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
      source: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired
    })).isRequired
  }).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Margin: PropTypes.shape({
    Top: PropTypes.number,
    Right: PropTypes.number,
    Bottom: PropTypes.number,
    Left: PropTypes.number
  }),
  Node_Radius: PropTypes.number,
  Status_Colors: PropTypes.object,
  Show_Labels: PropTypes.bool,
  Show_Status: PropTypes.bool,
  Transition_Duration: PropTypes.number
};

export default D3FlowChart;
