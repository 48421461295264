import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { organizationApi } from '../api';

export const JobsContext = createContext();

export const JobsProvider = ({ children, Company_ID }) => {
    const [State, setState] = useState({
        Jobs: [],
        Job_Map: {},
        Critical_Jobs: [],
        Is_Loading: true,
        Error: null
    });

    // Fetch jobs data
    const fetchData = useCallback(async () => {
        if (!Company_ID) {
            setState(prev => ({ ...prev, Is_Loading: false, Error: "No company selected" }));
            return;
        }

        setState(prev => ({ ...prev, Is_Loading: true, Error: null }));

        try {
            const [Jobs_Response, Critical_Jobs_Response] = await Promise.all([
                organizationApi.getJobs(Company_ID),
                organizationApi.getCriticalJobs(Company_ID)
            ]);

            // Process jobs data
            const Jobs = Jobs_Response.Data?.Records || [];
            const Job_Map = Jobs.reduce((map, job) => {
                if (job.Job_ID && job.Job_Title) {
                    map[job.Job_ID] = job.Job_Title;
                }
                return map;
            }, {});

            // Process critical jobs
            const Critical_Jobs = Critical_Jobs_Response.Data?.Records || [];

            setState(prev => ({
                ...prev,
                Jobs,
                Job_Map,
                Critical_Jobs,
                Is_Loading: false,
                Error: null
            }));
        } catch (Error) {
            console.error('Error fetching jobs data:', Error);
            setState(prev => ({
                ...prev,
                Is_Loading: false,
                Error: Error.Message || 'Failed to fetch jobs data'
            }));
        }
    }, [Company_ID]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Add a critical job
    const Add_Critical_Job = useCallback(async (Job_Data) => {
        try {
            await organizationApi.addCriticalJob(Company_ID, Job_Data);
            setState(prev => ({
                ...prev,
                Critical_Jobs: [...prev.Critical_Jobs, Job_Data]
            }));
            return true;
        } catch (Error) {
            console.error('Error adding critical job:', Error);
            return false;
        }
    }, [Company_ID]);

    // Remove a critical job
    const Remove_Critical_Job = useCallback(async (Job_Data) => {
        try {
            await organizationApi.removeCriticalJob(Company_ID, Job_Data);
            setState(prev => ({
                ...prev,
                Critical_Jobs: prev.Critical_Jobs.filter(job => 
                    !(job.Department_ID === Job_Data.Department_ID && 
                      job.Job_ID === Job_Data.Job_ID)
                )
            }));
            return true;
        } catch (Error) {
            console.error('Error removing critical job:', Error);
            return false;
        }
    }, [Company_ID]);

    // Check if a job is critical
    const Is_Critical_Job = useCallback((Department_ID, Job_ID) => {
        return State.Critical_Jobs.some(job => 
            job.Department_ID === Department_ID && 
            job.Job_ID === Job_ID
        );
    }, [State.Critical_Jobs]);

    // Get job title
    const Get_Job_Title = useCallback((Job_ID) => {
        return State.Job_Map[Job_ID] || Job_ID || 'Unknown Job';
    }, [State.Job_Map]);

    // Get all jobs for a department
    const Get_Department_Jobs = useCallback((Department_ID) => {
        return State.Jobs.filter(job => job.Department_ID === Department_ID);
    }, [State.Jobs]);

    // Get all critical jobs for a department
    const Get_Department_Critical_Jobs = useCallback((Department_ID) => {
        return State.Critical_Jobs.filter(job => job.Department_ID === Department_ID);
    }, [State.Critical_Jobs]);

    const Context_Value = useMemo(() => ({
        ...State,
        Add_Critical_Job,
        Remove_Critical_Job,
        Is_Critical_Job,
        Get_Job_Title,
        Get_Department_Jobs,
        Get_Department_Critical_Jobs,
        Refresh_Jobs: fetchData
    }), [
        State,
        Add_Critical_Job,
        Remove_Critical_Job,
        Is_Critical_Job,
        Get_Job_Title,
        Get_Department_Jobs,
        Get_Department_Critical_Jobs,
        fetchData
    ]);

    return (
        <JobsContext.Provider value={Context_Value}>
            {children}
        </JobsContext.Provider>
    );
};
