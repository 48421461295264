import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = process.env.REACT_APP_COOKIE_KEY || 'default-key';

export const cookieManager = {
    // Set encrypted cookie
    set: (name, value, days = 30) => {
        try {
            const encrypted = CryptoJS.AES.encrypt(
                JSON.stringify(value),
                ENCRYPTION_KEY
            ).toString();

            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            const expires = `expires=${date.toUTCString()}`;

            document.cookie = `${name}=${encrypted};${expires};path=/;SameSite=Strict`;
            return true;
        } catch (error) {
            console.error('Error setting encrypted cookie:', error);
            return false;
        }
    },

    // Get and decrypt cookie
    get: (name) => {
        try {
            const nameEQ = `${name}=`;
            const cookies = document.cookie.split(';');
            
            for (let cookie of cookies) {
                cookie = cookie.trim();
                if (cookie.indexOf(nameEQ) === 0) {
                    const encrypted = cookie.substring(nameEQ.length);
                    const decrypted = CryptoJS.AES.decrypt(
                        encrypted,
                        ENCRYPTION_KEY
                    ).toString(CryptoJS.enc.Utf8);
                    
                    return JSON.parse(decrypted);
                }
            }
            return null;
        } catch (error) {
            console.error('Error getting encrypted cookie:', error);
            return null;
        }
    },

    // Remove cookie
    remove: (name) => {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
};
