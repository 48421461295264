import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './D3HeatMap.css';

const D3HeatMap = ({ Data_List, Chart_Level, On_Select, On_Mark_Critical }) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List?.length) return;

    const Margin = { Top: 20, Right: 20, Bottom: 20, Left: 120 };
    const Chart_Width = 800 - Margin.Left - Margin.Right;
    const Chart_Height = Data_List.length * 40;

    // Clear previous content
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width + Margin.Left + Margin.Right)
      .attr("height", Chart_Height + Margin.Top + Margin.Bottom)
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Color scale
    const Color_Scale = d3.scaleSequential()
      .domain([0, d3.max(Data_List, Item => Item.Total_Value)])
      .interpolator(d3.interpolateReds);

    // Create cells
    const Cell_Groups = SVG.selectAll("g")
      .data(Data_List)
      .enter()
      .append("g")
      .attr("transform", (Item, Index) => `translate(0,${Index * 40})`);

    // Add rectangles
    Cell_Groups.append("rect")
      .attr("width", Chart_Width)
      .attr("height", 35)
      .attr("fill", Item => Color_Scale(Item.Total_Value))
      .attr("rx", 4)
      .attr("class", "heat-cell")
      .on("click", (Event, Item) => On_Select(Item))
      .append("title")
      .text(Item => `${Item.Name}\nTotal: ${Item.Total_Value}`);

    // Add labels
    Cell_Groups.append("text")
      .attr("x", -10)
      .attr("y", 20)
      .attr("text-anchor", "end")
      .text(Item => Item.Name)
      .attr("class", "cell-label");

    // Add total count
    Cell_Groups.append("text")
      .attr("x", Chart_Width - 10)
      .attr("y", 20)
      .attr("text-anchor", "end")
      .text(Item => Item.Total_Value)
      .attr("class", "cell-value");

    // Add critical job markers if at job level
    if (Chart_Level === 'Job') {
      Cell_Groups.append("circle")
        .attr("cx", Chart_Width - 50)
        .attr("cy", 17.5)
        .attr("r", 8)
        .attr("class", Item => `critical-marker ${Item.Is_Critical ? 'active' : ''}`)
        .on("click", (Event, Item) => {
          Event.stopPropagation();
          On_Mark_Critical(Item);
        });
    }

  }, [Data_List, Chart_Level, On_Select, On_Mark_Critical]);

  return (
    <div className="heat-map-container">
      <svg ref={SVG_Ref}></svg>
    </div>
  );
};

D3HeatMap.Display_Name = 'D3HeatMap';

export default D3HeatMap;
