import { API_ENDPOINTS } from '../core/constants';
import { API_Client, API_Error } from '../core/apiClient';

/**
 * Transform API response to standard format
 */
const Transform_Response = (Response_Data, Default_Data = []) => {
    if (!Response_Data) return { Message: '', Data: { Records: Default_Data } };
    
    if (Response_Data.Data) {
        return {
            Message: Response_Data.Message || '',
            Data: {
                Records: Array.isArray(Response_Data.Data) ? Response_Data.Data : (Response_Data.Data.Records || Default_Data),
                Analytics: Response_Data.Data.Analytics || {},
                Query_Context: Response_Data.Data.Query_Context || {}
            }
        };
    }
    
    return {
        Message: '',
        Data: {
            Records: Array.isArray(Response_Data) ? Response_Data : Default_Data
        }
    };
};

/**
 * Validate JSON fields in config data
 */
const Validate_Config_Data = (Config_Data) => {
    const Required_Fields = [
        'Company_ID',
        'Action_Type',
        'Action_Name',
        'PMS_Category'
    ];

    const JSON_Fields = [
        'Required_Fields',
        'Optional_Fields',
        'PMS_Metrics_Required',
        'PMS_Default_Milestones'
    ];

    // Check required fields
    for (const field of Required_Fields) {
        if (!Config_Data[field]) {
            throw new API_Error(`${field} is required`, 'MISSING_REQUIRED_FIELD');
        }
    }

    // Validate PMS_Category
    const Valid_Categories = ['REWARD', 'REPLACE', 'REMEDIATE'];
    if (!Valid_Categories.includes(Config_Data.PMS_Category)) {
        throw new API_Error('Invalid PMS_Category. Must be one of: ' + Valid_Categories.join(', '), 'INVALID_CATEGORY');
    }

    // Validate JSON fields
    for (const field of JSON_Fields) {
        if (Config_Data[field]) {
            try {
                // If it's a string, try to parse it
                if (typeof Config_Data[field] === 'string') {
                    Config_Data[field] = JSON.parse(Config_Data[field]);
                }
                // Validate specific fields
                switch (field) {
                    case 'Required_Fields':
                    case 'Optional_Fields':
                        if (typeof Config_Data[field] !== 'object' || Array.isArray(Config_Data[field])) {
                            throw new API_Error(`${field} must be an object`, 'INVALID_FIELD_FORMAT');
                        }
                        break;
                    case 'PMS_Metrics_Required':
                        if (typeof Config_Data[field] !== 'object') {
                            throw new API_Error('PMS_Metrics_Required must be an object', 'INVALID_FIELD_FORMAT');
                        }
                        break;
                    case 'PMS_Default_Milestones':
                        if (!Array.isArray(Config_Data[field])) {
                            throw new API_Error('PMS_Default_Milestones must be an array', 'INVALID_FIELD_FORMAT');
                        }
                        break;
                }
            } catch (error) {
                throw new API_Error(`Invalid JSON in ${field}: ${error.message}`, 'INVALID_JSON');
            }
        }
    }

    return Config_Data;
};

export const Action_Config_API = {
    // Get all action configurations
    getActionConfigs: async (Company_ID) => {
        if (!Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
        }

        const Response = await API_Client.get(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/config`, {
            params: { Company_ID }
        });
        return Transform_Response(Response);
    },

    // Create new action configuration
    createActionConfig: async (Config_Data) => {
        const Validated_Data = Validate_Config_Data(Config_Data);

        const Response = await API_Client.post(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/config`, Validated_Data);
        return Transform_Response(Response);
    },

    // Update existing action configuration
    updateActionConfig: async (Config_Data) => {
        if (!Config_Data.Config_ID) {
            throw new API_Error('Config ID is required for updates', 'MISSING_CONFIG_ID');
        }

        const Validated_Data = Validate_Config_Data(Config_Data);

        const Response = await API_Client.put(
            `${API_ENDPOINTS.WORKFLOW.ACTIONS}/config/${Config_Data.Config_ID}`,
            Validated_Data
        );
        return Transform_Response(Response);
    },

    // Delete action configuration
    deleteActionConfig: async (Company_ID, Config_ID) => {
        if (!Company_ID || !Config_ID) {
            throw new API_Error('Company ID and Config ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        const Response = await API_Client.delete(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/config/${Config_ID}`, {
            params: { Company_ID }
        });
        return Transform_Response(Response);
    },

    // Get action types
    getActionTypes: async (Company_ID) => {
        if (!Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
        }

        const Response = await API_Client.get(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/types`, {
            params: { Company_ID }
        });
        return Transform_Response(Response);
    }
};

// Export for backward compatibility
export const actionConfigApi = Action_Config_API;
