import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Card, Tabs } from 'flowbite-react';
import { analyticsApi } from '../../api';
import { JobsContext } from '../../contexts/JobsContext';
import { DepartmentContext } from '../../contexts/DepartmentContext';
import D3SparkChart from '../charts/D3SparkChart';
import BoxPlot from '../charts/BoxPlot';

const CriticalJobsAnalysis = ({ Company_ID, Job_ID, Department_ID }) => {
    const [Tenure_Data, setTenureData] = useState(null);
    const [Wage_Data, setWageData] = useState(null);
    const [Is_Loading, setIsLoading] = useState(true);
    const [Error, setError] = useState(null);
    const { Get_Job_Title } = useContext(JobsContext);
    const { Get_Department_Name } = useContext(DepartmentContext);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const [Tenure_Response, Wage_Response] = await Promise.all([
                analyticsApi.getTenureAnalytics.job(Company_ID, Job_ID, {
                    Include_Absence: true,
                    Department_ID
                }),
                analyticsApi.getWageAnalytics.job(Company_ID, Job_ID, {
                    Include_Tenure_Stats: true,
                    Department_ID,
                    Start_Date: '2019-01-01',
                    End_Date: new Date().toISOString().split('T')[0]
                })
            ]);

            setTenureData(Tenure_Response.Data?.Records || []);
            setWageData(Wage_Response.Data?.Records || []);
            setError(null);
        } catch (err) {
            console.error('Error fetching analysis data:', err);
            setError(err.Message || 'Failed to fetch analysis data');
        } finally {
            setIsLoading(false);
        }
    }, [Company_ID, Job_ID, Department_ID]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const renderTenureChart = () => {
        if (!Tenure_Data?.length) return null;

        const chartData = {
            labels: Tenure_Data.map(item => item.Period_Date.split('T')[0]),
            series: [
                {
                    name: 'Average Tenure',
                    data: Tenure_Data.map(item => item.Average_Tenure),
                    borderColor: '#3b82f6'
                },
                {
                    name: 'Absence Rate',
                    data: Tenure_Data.map(item => item.Absence_Rate || 0),
                    borderColor: '#eab308'
                },
                {
                    name: 'Flight Risk Score',
                    data: Tenure_Data.map(item => item.Flight_Risk_Score || 0),
                    borderColor: '#ef4444'
                }
            ]
        };

        return (
            <D3SparkChart
                Title="Tenure and Absence Patterns"
                Data={chartData}
                Chart_Height={400}
                Chart_Width={800}
            />
        );
    };

    const renderWageChart = () => {
        if (!Wage_Data?.length) return null;

        const Box_Plot_Data = [
            {
                Label: '<1 year',
                Values: Wage_Data.reduce((acc, item) => {
                    if (item.Tenure_Stats?.['<1']) {
                        acc.push(item.Tenure_Stats['<1'].Salary);
                    }
                    return acc;
                }, [])
            },
            {
                Label: '1-2 years',
                Values: Wage_Data.reduce((acc, item) => {
                    if (item.Tenure_Stats?.['1-2']) {
                        acc.push(item.Tenure_Stats['1-2'].Salary);
                    }
                    return acc;
                }, [])
            },
            {
                Label: '2-5 years',
                Values: Wage_Data.reduce((acc, item) => {
                    if (item.Tenure_Stats?.['2-5']) {
                        acc.push(item.Tenure_Stats['2-5'].Salary);
                    }
                    return acc;
                }, [])
            },
            {
                Label: '5+ years',
                Values: Wage_Data.reduce((acc, item) => {
                    if (item.Tenure_Stats?.['5+']) {
                        acc.push(item.Tenure_Stats['5+'].Salary);
                    }
                    return acc;
                }, [])
            }
        ];

        return (
            <BoxPlot
                Data_List={Box_Plot_Data}
                Chart_Width={800}
                Chart_Height={400}
                Y_Label="Salary ($)"
                Title="Salary Distribution by Tenure"
            />
        );
    };

    if (Is_Loading) return <div className="text-center">Loading...</div>;
    if (Error) return <div className="text-red-600">{Error}</div>;

    return (
        <Card>
            <div className="p-4">
                <h2 className="text-xl font-bold mb-4">
                    Critical Job Analysis: {Get_Job_Title(Job_ID)} in {Get_Department_Name(Department_ID)}
                </h2>

                <Tabs>
                    <Tabs.Item title="Tenure Analysis">
                        <div className="space-y-4">
                            <p className="text-sm text-gray-600">
                                Analysis of tenure patterns, absence rates, and potential flight risks.
                            </p>
                            {renderTenureChart()}
                            {Tenure_Data && (
                                <div className="grid grid-cols-3 gap-4 mt-4">
                                    <div className="bg-blue-50 p-4 rounded">
                                        <h3 className="font-semibold">Average Tenure</h3>
                                        <p>{Math.round(Tenure_Data[Tenure_Data.length - 1]?.Average_Tenure || 0)} days</p>
                                    </div>
                                    <div className="bg-yellow-50 p-4 rounded">
                                        <h3 className="font-semibold">Current Absence Rate</h3>
                                        <p>{(Tenure_Data[Tenure_Data.length - 1]?.Absence_Rate || 0).toFixed(2)}%</p>
                                    </div>
                                    <div className="bg-red-50 p-4 rounded">
                                        <h3 className="font-semibold">Flight Risk Score</h3>
                                        <p>{(Tenure_Data[Tenure_Data.length - 1]?.Flight_Risk_Score || 0).toFixed(2)}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Tabs.Item>

                    <Tabs.Item title="Wage Erosion Analysis">
                        <div className="space-y-4">
                            <p className="text-sm text-gray-600">
                                Analysis of salary patterns by tenure, highlighting potential wage erosion.
                            </p>
                            {renderWageChart()}
                            {Wage_Data && Wage_Data[Wage_Data.length - 1]?.Tenure_Stats && (
                                <div className="grid grid-cols-2 gap-4 mt-4">
                                    <div className="bg-orange-50 p-4 rounded">
                                        <h3 className="font-semibold">New Hire vs. Tenured Pay Gap</h3>
                                        <p>{((Wage_Data[Wage_Data.length - 1].Tenure_Stats['<1'].Average_Salary / 
                                             Wage_Data[Wage_Data.length - 1].Tenure_Stats['5+'].Average_Salary - 1) * 100).toFixed(1)}%</p>
                                    </div>
                                    <div className="bg-purple-50 p-4 rounded">
                                        <h3 className="font-semibold">Compression Risk Score</h3>
                                        <p>{(Wage_Data[Wage_Data.length - 1].Compression_Risk_Score || 0).toFixed(2)}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Tabs.Item>
                </Tabs>
            </div>
        </Card>
    );
};

export default CriticalJobsAnalysis;
