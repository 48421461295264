import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FiActivity, FiGitBranch, FiThermometer, FiUsers, FiCheckSquare, FiUserCheck, FiList, FiDatabase, FiPieChart, FiBarChart2, FiGrid, FiSettings } from 'react-icons/fi';

const AdminSecondaryNav = () => {
  const location = useLocation();

  // Debug log
  console.log('AdminSecondaryNav - Current location:', {
    pathname: location.pathname,
    state: location.state
  });

  const navItems = [
    {
      to: '/admin',
      icon: <FiActivity className="w-4 h-4" />,
      text: 'Dashboard'
    },
    {
      to: '/admin/data',
      icon: <FiPieChart className="w-4 h-4" />,
      text: 'Analytics'
    },
    {
      to: '/admin/chart-explorer',
      icon: <FiBarChart2 className="w-4 h-4" />,
      text: 'Chart Explorer'
    },
    {
      to: '/admin/chart-gallery',
      icon: <FiGrid className="w-4 h-4" />,
      text: 'Chart Gallery'
    },
    {
      to: '/admin/workflows',
      icon: <FiGitBranch className="w-4 h-4" />,
      text: 'Workflows'
    },
    {
      to: '/admin/actions',
      icon: <FiList className="w-4 h-4" />,
      text: 'Actions'
    },
    {
      to: '/admin/action-config',
      icon: <FiSettings className="w-4 h-4" />,
      text: 'Action Config'
    },
    {
      to: '/admin/approval-groups',
      icon: <FiCheckSquare className="w-4 h-4" />,
      text: 'Approval Groups'
    },
    {
      to: '/admin/approvals',
      icon: <FiUserCheck className="w-4 h-4" />,
      text: 'Approvals'
    },
    {
      to: '/admin/users',
      icon: <FiUsers className="w-4 h-4" />,
      text: 'Users'
    },
    {
      to: '/admin/critical-jobs',
      icon: <FiThermometer className="w-4 h-4" />,
      text: 'Critical Jobs'
    },
    {
      to: '/admin/reference-data',
      icon: <FiDatabase className="w-4 h-4" />,
      text: 'Reference Data'
    }
  ];

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex space-x-8 overflow-x-auto">
          {navItems.map((item) => {
            const isActive = location.pathname === item.to || 
                           (item.to === '/admin' && location.pathname === '/admin/');

            return (
              <NavLink
                key={item.to}
                to={item.to}
                className={
                  `flex items-center px-3 py-4 text-sm font-medium whitespace-nowrap ${
                    isActive
                      ? 'border-b-2 border-blue-500 text-blue-600'
                      : 'text-gray-500 hover:text-gray-700 hover:border-b-2 hover:border-gray-300'
                  }`
                }
              >
                {item.icon}
                <span className="ml-2">{item.text}</span>
              </NavLink>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default AdminSecondaryNav;
