import axios from 'axios';
import { Create_Auth_Headers } from './auth';

export class API_Error extends Error {
    constructor(message, code, details = {}) {
        super(message);
        this.name = 'API_Error';
        this.code = code;
        this.details = details;
    }
}

const API_Client = axios.create({
    baseURL: 'https://x25esadj2l.execute-api.us-east-2.amazonaws.com',
    headers: {
        'Content-Type': 'application/json'
    }
});

// Request interceptor
API_Client.interceptors.request.use(
    async (config) => {
        try {
            // Add auth headers to every request
            const Auth_Headers = await Create_Auth_Headers();
            if (!Auth_Headers.Authorization) {
                throw new API_Error('No authorization token available', 'NO_AUTH_TOKEN');
            }
            config.headers = {
                ...config.headers,
                ...Auth_Headers
            };
            console.log('API_Client: Making request to:', config.url);
            return config;
        } catch (error) {
            return Promise.reject(new API_Error(
                'Authorization failed: ' + error.message,
                'AUTH_ERROR',
                { originalError: error }
            ));
        }
    },
    (error) => {
        return Promise.reject(new API_Error(
            'Request configuration error: ' + error.message,
            'REQUEST_CONFIG_ERROR',
            { originalError: error }
        ));
    }
);

// Response interceptor
API_Client.interceptors.response.use(
    (response) => {
        // Return normalized response format
        return {
            Success: response.data.success,
            Message: response.data.message,
            Meta: response.data.meta,
            Data: response.data.data,
            Context: response.data.context
        };
    },
    (error) => {
        // Handle network errors
        if (!error.response) {
            return Promise.reject(new API_Error(
                'Network connection error. Please check your internet connection.',
                'NETWORK_ERROR',
                { originalError: error }
            ));
        }

        // Handle API errors
        return Promise.reject(new API_Error(
            error.response.data?.message || 'An unexpected error occurred',
            error.response.data?.code || 'API_ERROR',
            {
                status: error.response.status,
                meta: error.response.data?.meta,
                context: error.response.data?.context,
                originalError: error
            }
        ));
    }
);

export { API_Client };
