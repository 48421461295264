import React, { createContext, useContext, useMemo } from 'react';
import { useCompanyData } from './CompanyContext';
import { prepareChartDataCompany } from '../components/data/prepareChartDataCompany';

const CompanyChartDataContext = createContext();

export const CompanyChartDataProvider = ({ children }) => {
    const { Company_Metrics, Is_Loading: Company_Loading, Error: Company_Error } = useCompanyData();

    // Debug log
    console.log('CompanyChartDataProvider - Initializing:', {
        Has_Metrics: !!Company_Metrics,
        Metrics_Count: Company_Metrics?.length,
        Is_Loading: Company_Loading,
        Error: Company_Error
    });

    const Processed_Data = useMemo(() => {
        if (Company_Loading || !Company_Metrics) {
            console.log('CompanyChartDataProvider - Still loading or no metrics');
            return {
                Chart_Data: {},
                Latest_Metrics: null,
                Previous_Metrics: null
            };
        }

        if (Company_Error) {
            console.error('CompanyChartDataProvider - Error:', Company_Error);
            return {
                Chart_Data: {},
                Latest_Metrics: null,
                Previous_Metrics: null
            };
        }

        const Prepared_Data = prepareChartDataCompany(Company_Metrics);

        // Debug log
        console.log('CompanyChartDataProvider - Processed data:', {
            Has_Chart_Data: !!Prepared_Data.Chart_Data,
            Has_Latest_Metrics: !!Prepared_Data.Latest_Metrics,
            Has_Previous_Metrics: !!Prepared_Data.Previous_Metrics,
            Sample_Fields: Prepared_Data.Latest_Metrics ? Object.keys(Prepared_Data.Latest_Metrics) : []
        });

        return Prepared_Data;
    }, [Company_Metrics, Company_Loading, Company_Error]);

    const Context_Value = useMemo(() => ({
        Chart_Data: Processed_Data.Chart_Data || {},
        Latest_Metrics: Processed_Data.Latest_Metrics,
        Previous_Metrics: Processed_Data.Previous_Metrics,
        Is_Loading: Company_Loading,
        Error: Company_Error
    }), [Processed_Data, Company_Loading, Company_Error]);

    // Debug log
    console.log('CompanyChartDataProvider - Providing context:', {
        Has_Chart_Data: !!Context_Value.Chart_Data,
        Has_Latest_Metrics: !!Context_Value.Latest_Metrics,
        Has_Previous_Metrics: !!Context_Value.Previous_Metrics,
        Is_Loading: Context_Value.Is_Loading,
        Error: Context_Value.Error
    });

    return (
        <CompanyChartDataContext.Provider value={Context_Value}>
            {children}
        </CompanyChartDataContext.Provider>
    );
};

export const useCompanyChartData = () => {
    const Context = useContext(CompanyChartDataContext);
    if (!Context) {
        console.error('useCompanyChartData must be used within a CompanyChartDataProvider');
        throw new Error('useCompanyChartData must be used within a CompanyChartDataProvider');
    }
    return Context;
};

CompanyChartDataProvider.displayName = 'CompanyChartDataProvider';
