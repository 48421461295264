import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { StatusContext } from './contexts/Status';
import { CompanyDataProvider } from './contexts/CompanyContext';
import { CompanyChartDataProvider } from './contexts/CompanyChartDataContext';
import { OrganizationProvider } from './contexts/OrganizationContext';
import { LocationProvider } from './contexts/LocationContext';
import { DepartmentProvider } from './contexts/DepartmentContext';
import { JobsProvider } from './contexts/JobsContext';
import { EmployeeProvider } from './contexts/EmployeeContext';
import { DashboardProvider } from './contexts/DashboardContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import RoleRoute from './components/auth/RoleRoute';
import DashboardAdmin from './pages/admin/DashboardAdmin';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminDashboardData from './pages/admin/AdminDashboardData';
import WorkflowManagement from './pages/admin/WorkflowManagement';
import Actions from './pages/admin/Actions';
import ActionConfig from './pages/admin/ActionConfig';
import ApprovalGroups from './pages/admin/ApprovalGroups';
import Approvals from './pages/admin/Approvals';
import Users from './pages/admin/Users';
import CriticalJobsHeatMap from './pages/admin/CriticalJobsHeatMap';
import ReferenceData from './pages/admin/ReferenceData';
import DashboardSuper from './pages/super/Dashboard';
import NotFound from './global/NotFound';
import ChartExplorer from './components/charts/ChartExplorer';
import ChartGallery from './components/charts/ChartGallery';

const AdminRoutes = () => {
  const { Company_ID } = useContext(StatusContext);

  console.log('AdminRoutes - Initializing:', {
    Company_ID,
    Has_Company_ID: !!Company_ID
  });

  return (
    <CompanyDataProvider Company_ID={Company_ID}>
      <CompanyChartDataProvider>
        <OrganizationProvider>
          <LocationProvider Company_ID={Company_ID}>
            <DepartmentProvider Company_ID={Company_ID}>
              <JobsProvider Company_ID={Company_ID}>
                <EmployeeProvider Company_ID={Company_ID}>
                  <DashboardProvider>
                    <AnalyticsProvider>
                      <Routes>
                        <Route path="/*" element={<DashboardAdmin />}>
                          <Route index element={<AdminDashboard />} />
                          <Route path="data" element={<AdminDashboardData />} />
                          <Route path="workflows" element={<WorkflowManagement />} />
                          <Route path="actions" element={<Actions />} />
                          <Route path="action-config" element={<ActionConfig />} />
                          <Route path="approval-groups" element={<ApprovalGroups />} />
                          <Route path="approvals" element={<Approvals />} />
                          <Route path="users" element={<Users />} />
                          <Route path="critical-jobs" element={<CriticalJobsHeatMap />} />
                          <Route path="reference-data" element={<ReferenceData />} />
                          <Route path="chart-explorer" element={<ChartExplorer />} />
                          <Route path="chart-gallery" element={<ChartGallery />} />
                        </Route>
                      </Routes>
                    </AnalyticsProvider>
                  </DashboardProvider>
                </EmployeeProvider>
              </JobsProvider>
            </DepartmentProvider>
          </LocationProvider>
        </OrganizationProvider>
      </CompanyChartDataProvider>
    </CompanyDataProvider>
  );
};

const AuthenticatedApp = () => {
  const { Company_ID, Is_Loading, Is_Super_Admin } = useContext(StatusContext);

  console.log('AuthenticatedApp - Initializing:', {
    Company_ID,
    Is_Loading,
    Is_Super_Admin,
    Should_Show_Super_Dashboard: Is_Super_Admin && !Company_ID,
    Should_Show_Admin_Dashboard: Company_ID || (Is_Super_Admin && Company_ID)
  });

  if (Is_Loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <Routes>
      {/* Super Admin Routes */}
      <Route element={<RoleRoute Allowed_Roles={['SuperAdmin']} />}>
        <Route path="/super/*" element={
          !Company_ID ? (
            <Routes>
              <Route path="dashboard" element={<DashboardSuper />} />
              <Route path="*" element={<Navigate to="/super/dashboard" replace />} />
            </Routes>
          ) : (
            <Navigate to="/admin" replace />
          )
        } />
      </Route>

      {/* Admin Routes */}
      <Route element={<RoleRoute Allowed_Roles={['SuperAdmin', 'Client Admin']} />}>
        <Route path="/admin/*" element={
          Company_ID ? <AdminRoutes /> : <Navigate to="/super/dashboard" replace />
        } />
      </Route>

      {/* Default Route */}
      <Route path="*" element={
        Company_ID ? (
          <Navigate to="/admin" replace />
        ) : Is_Super_Admin ? (
          <Navigate to="/super/dashboard" replace />
        ) : (
          <Navigate to="/" replace />
        )
      } />
    </Routes>
  );
};

export default AuthenticatedApp;
