import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const GaugeChart = ({
  Current_Value,
  Min_Value = 0,
  Max_Value = 100,
  Chart_Width = 300,
  Chart_Height = 300,
  Color_Ranges = [
    { Min_Value: 0, Max_Value: 33, Color: '#ef4444' },   // Red
    { Min_Value: 33, Max_Value: 66, Color: '#f59e0b' },  // Yellow
    { Min_Value: 66, Max_Value: 100, Color: '#22c55e' }  // Green
  ],
  Chart_Label = ''
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 40, Bottom: 40, Left: 40 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;
    const Chart_Radius = Math.min(Inner_Width, Inner_Height) / 2;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Gauge Chart")
      .append("g")
      .attr("transform", `translate(${Chart_Width / 2},${Chart_Height / 2})`);

    // Create scale for the gauge
    const Value_Scale = d3.scaleLinear()
      .domain([Min_Value, Max_Value])
      .range([-Math.PI / 2, Math.PI / 2]);

    // Create arc generator
    const Arc_Generator = d3.arc()
      .innerRadius(Chart_Radius * 0.6)
      .outerRadius(Chart_Radius)
      .startAngle(-Math.PI / 2);

    // Add background arcs for each color range
    Color_Ranges.forEach(Range => {
      SVG.append("path")
        .datum({
          startAngle: Value_Scale(Range.Min_Value),
          endAngle: Value_Scale(Range.Max_Value)
        })
        .attr("class", "gauge-background")
        .attr("d", Arc_Generator)
        .attr("fill", Range.Color)
        .attr("opacity", 0.3);
    });

    // Add needle
    const Needle_Length = Chart_Radius * 0.8;
    const Needle_Radius = Chart_Radius * 0.1;
    const Needle_Angle = Value_Scale(Current_Value);

    const Needle_Path = d3.path();
    Needle_Path.moveTo(0, -2);
    Needle_Path.lineTo(Needle_Length, 0);
    Needle_Path.lineTo(0, 2);
    Needle_Path.closePath();

    SVG.append("path")
      .attr("class", "needle")
      .attr("d", Needle_Path.toString())
      .attr("fill", "#374151")
      .attr("transform", `rotate(${(Needle_Angle * 180) / Math.PI})`);

    // Add needle center circle
    SVG.append("circle")
      .attr("class", "needle-center")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", Needle_Radius)
      .attr("fill", "#374151");

    // Add ticks
    const Tick_Values = Value_Scale.ticks(5);
    const Tick_Length = Chart_Radius * 0.15;

    SVG.selectAll(".tick")
      .data(Tick_Values)
      .enter()
      .append("line")
      .attr("class", "tick")
      .attr("x1", Value => Math.cos(Value_Scale(Value)) * (Chart_Radius - Tick_Length))
      .attr("y1", Value => Math.sin(Value_Scale(Value)) * (Chart_Radius - Tick_Length))
      .attr("x2", Value => Math.cos(Value_Scale(Value)) * Chart_Radius)
      .attr("y2", Value => Math.sin(Value_Scale(Value)) * Chart_Radius)
      .attr("stroke", "#374151")
      .attr("stroke-width", 2);

    // Add tick labels
    SVG.selectAll(".tick-label")
      .data(Tick_Values)
      .enter()
      .append("text")
      .attr("class", "tick-label")
      .attr("x", Value => Math.cos(Value_Scale(Value)) * (Chart_Radius - Tick_Length * 2))
      .attr("y", Value => Math.sin(Value_Scale(Value)) * (Chart_Radius - Tick_Length * 2))
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .text(Value => Value);

    // Add value label
    SVG.append("text")
      .attr("class", "value-label")
      .attr("x", 0)
      .attr("y", Chart_Radius * 0.3)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("font-size", "24px")
      .style("font-weight", "bold")
      .text(Current_Value.toFixed(1));

    // Add description label
    if (Chart_Label) {
      SVG.append("text")
        .attr("class", "description-label")
        .attr("x", 0)
        .attr("y", Chart_Radius * 0.5)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .style("font-size", "14px")
        .text(Chart_Label);
    }

    // Add color range labels
    Color_Ranges.forEach((Range, Index) => {
      const Range_Angle = Value_Scale((Range.Min_Value + Range.Max_Value) / 2);
      const Label_Radius = Chart_Radius + 20;
      
      SVG.append("text")
        .attr("class", "range-label")
        .attr("x", Math.cos(Range_Angle) * Label_Radius)
        .attr("y", Math.sin(Range_Angle) * Label_Radius)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .style("font-size", "12px")
        .style("fill", Range.Color)
        .text(`${Range.Min_Value}-${Range.Max_Value}`);
    });

  }, [Current_Value, Min_Value, Max_Value, Chart_Width, Chart_Height, Color_Ranges, Chart_Label]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

GaugeChart.propTypes = {
  Current_Value: PropTypes.number.isRequired,
  Min_Value: PropTypes.number,
  Max_Value: PropTypes.number,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Ranges: PropTypes.arrayOf(PropTypes.shape({
    Min_Value: PropTypes.number.isRequired,
    Max_Value: PropTypes.number.isRequired,
    Color: PropTypes.string.isRequired
  })),
  Chart_Label: PropTypes.string
};

GaugeChart.Display_Name = 'GaugeChart';

export default GaugeChart;
