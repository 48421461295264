import React from 'react';
import { Card, Spinner } from 'flowbite-react';
import D3SpiderChart from '../charts/D3SpiderChart';
import { useCompanyData } from '../../contexts/CompanyContext';

const ActionMetricsWidget = () => {
    const { Company_Metrics, Is_Loading, Error } = useCompanyData();

    if (Is_Loading) {
        return (
            <Card className="w-full h-96">
                <div className="flex justify-center items-center h-full">
                    <Spinner size="xl" />
                </div>
            </Card>
        );
    }

    if (Error || !Company_Metrics || Company_Metrics.length === 0) {
        return (
            <Card className="w-full h-96">
                <div className="flex justify-center items-center h-full text-red-500">
                    {Error || 'No data available'}
                </div>
            </Card>
        );
    }

    // Get latest metrics
    const Latest_Metrics = Company_Metrics[Company_Metrics.length - 1];

    // Prepare data for spider chart
    const Spider_Data = [
        {
            Label: 'Current Period',
            Values: {
                'Reward Actions': Latest_Metrics.Reward_Actions || 0,
                'Remediate Actions': Latest_Metrics.Remediate_Actions || 0,
                'Replace Actions': Latest_Metrics.Replace_Actions || 0,
                'Action Success': Latest_Metrics.Action_Success_Rate || 0,
                'Action Completion': Latest_Metrics.Action_Completion_Rate || 0
            }
        }
    ];

    // If we have previous period data, add it for comparison
    if (Company_Metrics.length > 1) {
        const Previous_Metrics = Company_Metrics[Company_Metrics.length - 2];
        Spider_Data.push({
            Label: 'Previous Period',
            Values: {
                'Reward Actions': Previous_Metrics.Reward_Actions || 0,
                'Remediate Actions': Previous_Metrics.Remediate_Actions || 0,
                'Replace Actions': Previous_Metrics.Replace_Actions || 0,
                'Action Success': Previous_Metrics.Action_Success_Rate || 0,
                'Action Completion': Previous_Metrics.Action_Completion_Rate || 0
            }
        });
    }

    // Custom color scale
    const Color_Scale = (index) => {
        return index === 0 ? '#3b82f6' : '#9ca3af';
    };

    return (
        <Card className="w-full h-96">
            <div className="flex flex-col h-full">
                <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white mb-4">
                    Action Metrics Distribution
                </h5>
                
                <div className="relative flex-grow">
                    <D3SpiderChart
                        data={Spider_Data}
                        Chart_Width={400}
                        Chart_Height={300}
                        Color_Scale={Color_Scale}
                        Show_Legend={true}
                        Show_Labels={true}
                        Show_Tooltips={true}
                    />
                </div>

                {/* Summary stats */}
                <div className="grid grid-cols-3 gap-4 mt-4">
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">Reward</div>
                        <div className="text-lg font-semibold">
                            {Latest_Metrics.Reward_Actions || 0}
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">Remediate</div>
                        <div className="text-lg font-semibold">
                            {Latest_Metrics.Remediate_Actions || 0}
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">Replace</div>
                        <div className="text-lg font-semibold">
                            {Latest_Metrics.Replace_Actions || 0}
                        </div>
                    </div>
                </div>

                {/* Success rates */}
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">Success Rate</div>
                        <div className="text-lg font-semibold">
                            {`${(Latest_Metrics.Action_Success_Rate || 0).toFixed(1)}%`}
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">Completion Rate</div>
                        <div className="text-lg font-semibold">
                            {`${(Latest_Metrics.Action_Completion_Rate || 0).toFixed(1)}%`}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ActionMetricsWidget;
