import React, { useState, useEffect } from "react";
import { Modal, Button, TextInput } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AdminTable from "../Components/AdminTable";

const formatDate = (date) => {
  const d = new Date(date);
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
};

const ClientCompanyModal = ({ show, onClose, clientId, clientComps, onSave, onDelete }) => {
  const [companies, setCompanies] = useState([]);

  // Update companies when clientComps changes
  useEffect(() => {
    if (clientId && clientComps && clientComps[clientId]) {
      setCompanies(clientComps[clientId]);
    } else {
      setCompanies([]);
    }
  }, [clientId, clientComps]);

  const addNewComp = () => {
    const newComp = {
      Client_ID: clientId,
      Company_ID: '',
      Company_Name: '',
      Create_Date: new Date(),
      Last_Updated: new Date(),
      isNew: true,
    };
    setCompanies([...companies, newComp]);
  };

  const handleCompanyChange = (index, field, value) => {
    const updatedCompanies = [...companies];
    updatedCompanies[index][field] = value;
    setCompanies(updatedCompanies);
  };

  const companyColumns = [
    {
      Header: "Company ID",
      accessor: "Company_ID",
      Cell: ({ row, value }) => (
        <TextInput
          type="text"
          value={value}
          onChange={(e) => handleCompanyChange(row.index, "Company_ID", e.target.value)}
        />
      ),
    },
    {
      Header: "Company Name",
      accessor: "Company_Name",
      Cell: ({ row, value }) => (
        <TextInput
          type="text"
          value={value}
          onChange={(e) => handleCompanyChange(row.index, "Company_Name", e.target.value)}
        />
      ),
    },
    {
      Header: "Create Date",
      accessor: "Create_Date",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "Last Updated",
      accessor: "Last_Updated",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex items-center space-x-2">
          <Button color="success" size="xs" onClick={() => onSave(row.original)}>
            Save
          </Button>
          <Button color="failure" size="xs" onClick={() => onDelete(clientId, row.original.Company_ID)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Modal show={show} onClose={onClose} size="xl">
      <Modal.Header>Client Companies</Modal.Header>
      <Modal.Body>
        <Button color="success" onClick={addNewComp} className="mb-4">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Company
        </Button>
        <div className="border border-gray-300 rounded-lg overflow-hidden">
          <AdminTable
            columns={companyColumns}
            data={companies}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ClientCompanyModal);
