import { API_Client, API_Error } from '../core/apiClient';
import { API_ENDPOINTS } from '../core/constants';

export const Structure_API = {
  getOrganization: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.ORGANIZATION, {
      params: { Company_ID }
    });
  },

  getDepartment: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.DEPARTMENT, {
      params: { Company_ID }
    });
  },

  getDepartmentDetails: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
      params: { Company_ID }
    });
  },

  addDepartment: async (Company_ID, Department_Data) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.post(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
      ...Department_Data,
      Company_ID
    });
  },

  updateDepartment: async (Company_ID, Department_ID, Department_Data) => {
    if (!Company_ID || !Department_ID) {
      throw new API_Error('Company ID and Department ID are required', 'MISSING_REQUIRED_FIELDS');
    }
    return await API_Client.put(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
      ...Department_Data,
      Company_ID,
      Department_ID
    });
  },

  deleteDepartment: async (Company_ID, Department_ID) => {
    if (!Company_ID || !Department_ID) {
      throw new API_Error('Company ID and Department ID are required', 'MISSING_REQUIRED_FIELDS');
    }
    return await API_Client.delete(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
      params: {
        Company_ID,
        Department_ID
      }
    });
  },

  getJobs: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.JOB, {
      params: { Company_ID }
    });
  },

  getJobDetails: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
      params: { Company_ID }
    });
  },

  addJob: async (Company_ID, Job_Data) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.post(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
      ...Job_Data,
      Company_ID
    });
  },

  updateJob: async (Company_ID, Job_ID, Job_Data) => {
    if (!Company_ID || !Job_ID) {
      throw new API_Error('Company ID and Job ID are required', 'MISSING_REQUIRED_FIELDS');
    }
    return await API_Client.put(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
      ...Job_Data,
      Company_ID,
      Job_ID
    });
  },

  deleteJob: async (Company_ID, Job_ID) => {
    if (!Company_ID || !Job_ID) {
      throw new API_Error('Company ID and Job ID are required', 'MISSING_REQUIRED_FIELDS');
    }
    return await API_Client.delete(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
      params: {
        Company_ID,
        Job_ID
      }
    });
  },

  getLocations: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.LOCATION, {
      params: { Company_ID }
    });
  },

  getLocationDetails: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
      params: { Company_ID }
    });
  },

  addLocation: async (Company_ID, Location_Data) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.post(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
      ...Location_Data,
      Company_ID
    });
  },

  updateLocation: async (Company_ID, Location_ID, Location_Data) => {
    if (!Company_ID || !Location_ID) {
      throw new API_Error('Company ID and Location ID are required', 'MISSING_REQUIRED_FIELDS');
    }
    return await API_Client.put(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
      ...Location_Data,
      Company_ID,
      Location_ID
    });
  },

  deleteLocation: async (Company_ID, Location_ID) => {
    if (!Company_ID || !Location_ID) {
      throw new API_Error('Company ID and Location ID are required', 'MISSING_REQUIRED_FIELDS');
    }
    return await API_Client.delete(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
      params: {
        Company_ID,
        Location_ID
      }
    });
  },

  getCriticalJobs: async (Company_ID) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.get(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
      params: {
        Company_ID,
        Critical: true
      }
    });
  },

  addCriticalJob: async (Company_ID, Job_Data) => {
    if (!Company_ID) {
      throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
    }
    return await API_Client.post(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
      ...Job_Data,
      Company_ID,
      Critical: true
    });
  },

  removeCriticalJob: async (Company_ID, Job_ID) => {
    if (!Company_ID || !Job_ID) {
      throw new API_Error('Company ID and Job ID are required', 'MISSING_REQUIRED_FIELDS');
    }
    return await API_Client.put(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
      Company_ID,
      Job_ID,
      Critical: false
    });
  }
};
