import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { organizationApi } from '../api';

export const DepartmentContext = createContext();

export const DepartmentProvider = ({ children, Company_ID }) => {
    const [State, setState] = useState({
        Departments: [],
        Department_Map: {},
        Department_Hierarchy: {},
        Is_Loading: true,
        Error: null,
        Meta: null
    });

    const fetchData = useCallback(async () => {
        if (!Company_ID) {
            setState(prev => ({ ...prev, Is_Loading: false, Error: "No company selected" }));
            return;
        }

        setState(prev => ({ ...prev, Is_Loading: true, Error: null }));

        try {
            const Response = await organizationApi.getDepartment(Company_ID);
            const Departments = Response.Data || [];

            // Process departments and create mapping
            const Department_Map = {};
            const Department_Hierarchy = {};

            // Group departments by period to get latest names
            const Department_Groups = Departments.reduce((Acc, Dept) => {
                if (!Acc[Dept.Department_ID]) {
                    Acc[Dept.Department_ID] = [];
                }
                Acc[Dept.Department_ID].push(Dept);
                return Acc;
            }, {});

            // Get latest department names
            Object.entries(Department_Groups).forEach(([Department_ID, Departments]) => {
                const Latest_Department = Departments.reduce((Latest, Current) => {
                    const Current_Date = new Date(Current.Period_Date);
                    const Latest_Date = new Date(Latest.Period_Date);
                    return Current_Date > Latest_Date ? Current : Latest;
                });
                Department_Map[Department_ID] = Latest_Department.Department_Name;

                // Build hierarchy
                if (Latest_Department.Parent_Department_ID) {
                    if (!Department_Hierarchy[Latest_Department.Parent_Department_ID]) {
                        Department_Hierarchy[Latest_Department.Parent_Department_ID] = [];
                    }
                    Department_Hierarchy[Latest_Department.Parent_Department_ID].push(Department_ID);
                }
            });

            setState(prev => ({
                ...prev,
                Departments,
                Department_Map,
                Department_Hierarchy,
                Is_Loading: false,
                Error: null,
                Meta: Response.Meta
            }));
        } catch (Error) {
            console.error('Error fetching department data:', Error);
            setState(prev => ({
                ...prev,
                Is_Loading: false,
                Error: Error.message || 'Failed to fetch department data',
                Meta: Error.details?.meta || null
            }));
        }
    }, [Company_ID]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Get department name
    const Get_Department_Name = useCallback((Department_ID) => {
        return State.Department_Map[Department_ID] || Department_ID || 'Unknown Department';
    }, [State.Department_Map]);

    // Get child departments
    const Get_Child_Departments = useCallback((Department_ID) => {
        return State.Department_Hierarchy[Department_ID] || [];
    }, [State.Department_Hierarchy]);

    // Get parent department
    const Get_Parent_Department = useCallback((Department_ID) => {
        const Department = State.Departments.find(
            dept => dept.Department_ID === Department_ID
        );
        return Department?.Parent_Department_ID || null;
    }, [State.Departments]);

    // Get department full path
    const Get_Department_Path = useCallback((Department_ID) => {
        const path = [];
        let current_id = Department_ID;

        while (current_id) {
            path.unshift(current_id);
            current_id = Get_Parent_Department(current_id);
        }

        return path;
    }, [Get_Parent_Department]);

    // Get all departments under a parent (including nested)
    const Get_All_Child_Departments = useCallback((Department_ID) => {
        const children = new Set();
        const toProcess = [Department_ID];

        while (toProcess.length > 0) {
            const current = toProcess.pop();
            const child_departments = Get_Child_Departments(current);
            
            child_departments.forEach(child => {
                if (!children.has(child)) {
                    children.add(child);
                    toProcess.push(child);
                }
            });
        }

        return Array.from(children);
    }, [Get_Child_Departments]);

    const Context_Value = useMemo(() => ({
        ...State,
        Get_Department_Name,
        Get_Child_Departments,
        Get_Parent_Department,
        Get_Department_Path,
        Get_All_Child_Departments,
        Refresh_Departments: fetchData
    }), [
        State,
        Get_Department_Name,
        Get_Child_Departments,
        Get_Parent_Department,
        Get_Department_Path,
        Get_All_Child_Departments,
        fetchData
    ]);

    return (
        <DepartmentContext.Provider value={Context_Value}>
            {children}
        </DepartmentContext.Provider>
    );
};
