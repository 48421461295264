import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const TreeMap = ({
  Data_Tree,
  Chart_Width = 300,
  Chart_Height = 300,
  Color_Scheme = d3.schemeCategory10,
  Value_Format = Value => Value.toLocaleString(),
  Is_Animated = true,
  Cell_Padding = 1
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_Tree) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 10, Bottom: 10, Left: 10 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Tree Map")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Create hierarchy and treemap layout
    const Root_Node = d3.hierarchy(Data_Tree)
      .sum(Item => Item.Value)
      .sort((A, B) => B.Value - A.Value);

    const Tree_Layout = d3.treemap()
      .size([Inner_Width, Inner_Height])
      .padding(Cell_Padding)
      .round(true);

    Tree_Layout(Root_Node);

    // Create color scale for depth
    const Color_Scale = d3.scaleOrdinal()
      .domain(Root_Node.descendants().map(Item => Item.depth))
      .range(Color_Scheme);

    // Create cell groups
    const Cell_Groups = SVG.selectAll("g")
      .data(Root_Node.descendants())
      .enter()
      .append("g")
      .attr("class", "cell")
      .attr("transform", Item => `translate(${Item.x0},${Item.y0})`);

    // Add rectangles
    const Cell_Rects = Cell_Groups.append("rect")
      .attr("width", Item => Item.x1 - Item.x0)
      .attr("height", Item => Item.y1 - Item.y0)
      .attr("fill", Item => Color_Scale(Item.depth))
      .attr("stroke", "#fff")
      .attr("stroke-width", 1)
      .style("opacity", 0.7);

    if (Is_Animated) {
      Cell_Rects.attr("opacity", 0)
        .transition()
        .duration(1000)
        .attr("opacity", 0.7);
    }

    // Add labels
    const Cell_Labels = Cell_Groups.append("text")
      .attr("x", 4)
      .attr("y", 14)
      .attr("dy", ".35em")
      .style("font-size", "12px")
      .style("fill", Item => d3.lab(Color_Scale(Item.depth)).l < 50 ? "#fff" : "#000");

    // Add clipping for text
    Cell_Labels.each(function(Item) {
      const Node = d3.select(this);
      const Rect_Width = Item.x1 - Item.x0;
      const Rect_Height = Item.y1 - Item.y0;

      // Only show label if rectangle is big enough
      if (Rect_Width < 30 || Rect_Height < 20) {
        Node.remove();
        return;
      }

      // Split text into multiple lines if needed
      const Words = Item.data.Name.split(/\s+/);
      let Line = [];
      let Line_Number = 0;
      const Line_Height = 1.1;
      const Max_Width = Rect_Width - 8;
      let Text_Span = Node.append("tspan")
        .attr("x", 4)
        .attr("dy", 0);

      Words.forEach(Word => {
        Line.push(Word);
        Text_Span.text(Line.join(" "));

        if (Text_Span.node().getComputedTextLength() > Max_Width) {
          Line.pop();
          Text_Span.text(Line.join(" "));
          Line = [Word];
          Text_Span = Node.append("tspan")
            .attr("x", 4)
            .attr("dy", `${Line_Height}em`)
            .text(Word);
          Line_Number++;
        }
      });

      // Add value on new line
      if (Item.Value) {
        Node.append("tspan")
          .attr("x", 4)
          .attr("dy", "1.2em")
          .style("font-weight", "bold")
          .text(Value_Format(Item.Value));
      }
    });

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Cell_Groups.on("mouseover", (Event, Item) => {
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>${Item.data.Name}</strong><br/>
        Value: ${Value_Format(Item.Value)}<br/>
        ${Item.parent ? `Parent: ${Item.parent.data.Name}` : ''}
      `)
        .style("left", `${Event.pageX}px`)
        .style("top", `${Event.pageY - 28}px`);

      d3.select(Event.currentTarget).select("rect")
        .transition()
        .duration(200)
        .style("opacity", 1);
    })
    .on("mouseout", (Event) => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);

      d3.select(Event.currentTarget).select("rect")
        .transition()
        .duration(200)
        .style("opacity", 0.7);
    });

    // Add breadcrumb navigation
    const Breadcrumb = SVG.append("text")
      .attr("class", "breadcrumb")
      .attr("x", 0)
      .attr("y", -20)
      .style("font-size", "12px");

    Cell_Groups.on("click", (Event, Item) => {
      // Update breadcrumb
      const Ancestors = Item.ancestors().reverse();
      Breadcrumb.text(Ancestors.map(Node => Node.data.Name).join(" > "));

      // Zoom to clicked cell
      const Transition = SVG.transition()
        .duration(750);

      const Scale_X = Inner_Width / (Item.x1 - Item.x0);
      const Scale_Y = Inner_Height / (Item.y1 - Item.y0);
      const X_Offset = Item.x0;
      const Y_Offset = Item.y0;

      Cell_Groups.transition(Transition)
        .attr("transform", Node => {
          const New_X = (Node.x0 - X_Offset) * Scale_X;
          const New_Y = (Node.y0 - Y_Offset) * Scale_Y;
          return `translate(${New_X},${New_Y})`;
        })
        .select("rect")
        .attr("width", Node => (Node.x1 - Node.x0) * Scale_X)
        .attr("height", Node => (Node.y1 - Node.y0) * Scale_Y);

      Cell_Groups.transition(Transition)
        .select("text")
        .attr("x", Node => 4 * Scale_X)
        .attr("y", Node => 14 * Scale_Y);
    });

  }, [Data_Tree, Chart_Width, Chart_Height, Color_Scheme, Value_Format, Is_Animated, Cell_Padding]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

TreeMap.propTypes = {
  Data_Tree: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Children: PropTypes.arrayOf(PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Value: PropTypes.number,
      Children: PropTypes.array
    }))
  }).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Scheme: PropTypes.arrayOf(PropTypes.string),
  Value_Format: PropTypes.func,
  Is_Animated: PropTypes.bool,
  Cell_Padding: PropTypes.number
};

TreeMap.Display_Name = 'TreeMap';

export default TreeMap;
