import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Users_API } from '../api';
import { AccountContext } from './Account';

const StatusContext = createContext();

// Helper to normalize company data fields
const normalizeCompanyFields = (company) => {
  if (!company) return null;

  // Handle legacy format with different field names
  if (company.Create_Date || company.Last_Updated) {
    return {
      ...company,
      Created_At: company.Create_Date || company.Created_At,
      Updated_At: company.Last_Updated || company.Updated_At,
      // Keep original fields for backward compatibility
      Create_Date: company.Create_Date,
      Last_Updated: company.Last_Updated
    };
  }

  return company;
};

// Helper to normalize companies response
const normalizeCompaniesResponse = (Response) => {
  console.log('Status: Normalizing companies response:', Response);

  // Handle direct array format (Response itself is the array)
  if (Array.isArray(Response)) {
    console.log('Status: Handling direct array response');
    return Response.map(normalizeCompanyFields);
  }
  
  // Handle standard format
  if (Response?.Data?.Companies) {
    console.log('Status: Handling standard format with Companies');
    return Response.Data.Companies.map(normalizeCompanyFields);
  }

  // Handle direct data array
  if (Array.isArray(Response?.Data)) {
    console.log('Status: Handling direct Data array');
    return Response.Data.map(normalizeCompanyFields);
  }

  // If Response is an object with expected company fields, treat it as a single company
  if (Response?.Company_ID || Response?.Create_Date) {
    console.log('Status: Handling single company object');
    return [normalizeCompanyFields(Response)];
  }

  console.log('Status: No valid company data found in response');
  return [];
};

// Helper to normalize client response
const normalizeClientResponse = (Response, Client_ID) => {
  console.log('Status: Normalizing client response:', { Response, Client_ID });

  // Handle standard format with nested Client
  if (Response?.Data?.Client) {
    console.log('Status: Found client in Data.Client');
    return Response.Data.Client;
  }

  // Handle standard format with Clients array
  if (Response?.Data?.Clients) {
    console.log('Status: Found client in Data.Clients array');
    const client = Response.Data.Clients.find(c => c.Client_ID === Client_ID);
    console.log('Status: Found client:', client);
    return client;
  }

  // Handle direct array format
  if (Array.isArray(Response?.Data)) {
    console.log('Status: Found client in Data array');
    const client = Response.Data.find(c => c.Client_ID === Client_ID);
    console.log('Status: Found client:', client);
    return client;
  }

  // Handle direct array response
  if (Array.isArray(Response)) {
    console.log('Status: Found client in direct array');
    const client = Response.find(c => c.Client_ID === Client_ID);
    console.log('Status: Found client:', client);
    return client;
  }

  console.log('Status: No valid client data found');
  return null;
};

const Status = (Props) => {
  const { Is_Authenticated, Session_Data, Get_Session } = useContext(AccountContext);
  const [State, setState] = useState({
    Is_Loading: true,
    Is_Signed_In: false,
    User_Data: null,
    User_Entitlements: null,
    Error_Message: null,
    Auth_Status: undefined,
    Is_Super_Admin: false,
    Client_ID: null,
    Company_ID: null,
    Client_Name: null,
    Company_Name: null,
    Initial_Route: null,
    Last_Refresh: null
  });

  const determineInitialRoute = useCallback((User_Data, User_Entitlements, Is_Super_Admin, Client_ID = null, Company_ID = null) => {
    console.log('Status: Determining route with:', {
      Is_Super_Admin,
      Is_Client_Admin: User_Data?.Roles?.Admin,
      Has_Client_ID: !!Client_ID,
      Has_Company_ID: !!Company_ID,
      User_Data
    });

    // Check super admin first
    if (Is_Super_Admin) {
      if (Client_ID && Company_ID) {
        console.log('Status: Super admin with context, routing to admin dashboard');
        return '/admin/dashboard';
      }
      console.log('Status: Super admin without context, routing to super dashboard');
      return '/super/dashboard';
    }

    // Then check client admin
    if (User_Data?.Roles?.Admin) {
      console.log('Status: User is client admin');
      if (Client_ID && Company_ID) {
        console.log('Status: Client admin with context, routing to admin dashboard');
        return '/admin/dashboard';
      }
      console.log('Status: Client admin without context, routing to super dashboard');
      return '/super/dashboard';
    }

    // Finally check entitlements
    if (!User_Entitlements || User_Entitlements.length === 0) {
      console.log('Status: No entitlements found');
      return '/unauthorized';
    }

    console.log('Status: Checking entitlements', User_Entitlements);

    // Check roles in order of priority
    const Role_Routes = {
      'Admin': '/admin/dashboard',
      'Manager': '/manager/dashboard',
      'Approver': '/approver/dashboard',
      'Auditor': '/auditor/dashboard',
      'HR': '/hr/dashboard',
      'Executive': '/executive/dashboard',
      'Recorder': '/recorder/dashboard'
    };

    for (const [Role_Name, Route_Path] of Object.entries(Role_Routes)) {
      if (User_Entitlements.some(E => E.Roles[Role_Name])) {
        console.log('Status: Found matching role', { Role_Name, Route_Path });
        return Route_Path;
      }
    }

    console.log('Status: No matching role found in entitlements');
    return '/unauthorized';
  }, []);

  const refreshUserStatus = useCallback(async () => {
    // Only proceed if we have both authentication and a valid session
    if (!Is_Authenticated || !Session_Data) {
      console.log('Status: No valid auth state, resetting');
      setState(Prev_State => ({
        ...Prev_State,
        Is_Loading: false,
        Auth_Status: false,
        Initial_Route: '/',
        Client_ID: null,
        Company_ID: null,
        Client_Name: null,
        Company_Name: null
      }));
      return;
    }

    try {
      // Verify session is still valid
      console.log('Status: Verifying session');
      await Get_Session();

      setState(Prev_State => ({ ...Prev_State, Is_Loading: true }));
      
      // Get user email from JWT token
      const User_Email = Session_Data.idToken.payload.email;
      console.log('Status: User email from JWT', User_Email);

      // Get user data first to check if super admin
      console.log('Status: Getting user data');
      const User_Response = await Users_API.getUsers({ Email_Address: User_Email });
      console.log('Status: User response:', User_Response);

      // Extract user data from response
      const User_Data = Array.isArray(User_Response.Data) ? User_Response.Data[0] : null;
      console.log('Status: Extracted user data:', User_Data);
      
      if (!User_Data) {
        console.error('Status: User data not found in response:', User_Response);
        throw new Error('User data not found');
      }

      const Is_Super_Admin = User_Data.Super_Admin === true;
      const Client_ID = User_Data.Client_ID;

      console.log('Status: User data processed', { 
        Is_Super_Admin,
        Is_Client_Admin: User_Data?.Roles?.Admin,
        Client_ID,
        User_Data 
      });

      if (Is_Super_Admin) {
        // For super admin, we don't need to fetch entitlements
        setState(Prev_State => ({
          ...Prev_State,
          Is_Loading: false,
          Is_Signed_In: true,
          User_Data,
          Auth_Status: true,
          Is_Super_Admin: true,
          Initial_Route: '/super/dashboard',
          Last_Refresh: Date.now()
        }));
        return;
      }

      if (!Client_ID) {
        throw new Error('No client ID found in user data');
      }

      // For regular users, get client details first
      console.log('Status: Getting client details for:', Client_ID);
      const Client_Response = await Users_API.getClients(Client_ID);
      console.log('Status: Client response:', Client_Response);

      const Client_Data = normalizeClientResponse(Client_Response, Client_ID);
      console.log('Status: Normalized client data:', Client_Data);

      if (!Client_Data) {
        throw new Error('Invalid client ID');
      }

      // Get companies for the client
      console.log('Status: Getting companies for client:', Client_ID);
      const Companies_Response = await Users_API.getClientCompanies(Client_ID);
      console.log('Status: Companies response:', Companies_Response);

      const Companies_List = normalizeCompaniesResponse(Companies_Response);
      console.log('Status: Companies list:', Companies_List);
      
      const Default_Company = Companies_List[0];
      if (!Default_Company) {
        console.error('Status: No companies found for client:', Client_ID);
        // For client admin without company context, send to super dashboard to select company
        if (User_Data?.Roles?.Admin) {
          setState(Prev_State => ({
            ...Prev_State,
            Is_Loading: false,
            Is_Signed_In: true,
            User_Data,
            Auth_Status: true,
            Is_Super_Admin: false,
            Client_ID,
            Client_Name: Client_Data.Client_Name,
            Initial_Route: '/super/dashboard',
            Last_Refresh: Date.now()
          }));
          return;
        }
        throw new Error('No company context available');
      }

      // Use existing or default company context
      const Company_ID = State.Company_ID || Default_Company.Company_ID;

      // Get user entitlements
      console.log('Status: Getting user entitlements');
      const Entitlements_Response = await Users_API.getUserEntitlements(User_Email, Client_ID);
      console.log('Status: Entitlements response:', Entitlements_Response);

      const User_Entitlements = Entitlements_Response.Data || [];
      console.log('Status: User entitlements:', User_Entitlements);

      // Determine route based on role and context
      const Route_Path = determineInitialRoute(User_Data, User_Entitlements, Is_Super_Admin, Client_ID, Company_ID);
      console.log('Status: Determined route path:', Route_Path);

      // Set state based on route and user type
      if (Route_Path === '/unauthorized' && !User_Data?.Roles?.Admin) {
        setState(Prev_State => ({
          ...Prev_State,
          Is_Loading: false,
          Is_Signed_In: true,
          User_Data,
          Auth_Status: false,
          Is_Super_Admin,
          Initial_Route: '/unauthorized',
          Last_Refresh: Date.now()
        }));
        return;
      }
      
      setState(Prev_State => ({
        ...Prev_State,
        Is_Loading: false,
        Is_Signed_In: true,
        User_Data,
        User_Entitlements,
        Auth_Status: true,
        Is_Super_Admin,
        Client_ID,
        Company_ID,
        Client_Name: Client_Data.Client_Name,
        Company_Name: Default_Company.Company_Name,
        Initial_Route: Route_Path,
        Last_Refresh: Date.now()
      }));
    } catch (Error) {
      console.error('Error refreshing user status:', Error);
      setState(Prev_State => ({
        ...Prev_State,
        Is_Loading: false,
        Error_Message: Error.message || 'Failed to fetch data',
        Auth_Status: false,
        Initial_Route: '/',
        Last_Refresh: Date.now(),
        Client_ID: null,
        Company_ID: null,
        Client_Name: null,
        Company_Name: null
      }));
    }
  }, [Is_Authenticated, Session_Data, Get_Session, determineInitialRoute, State.Company_ID]);

  const updateClientAndCompany = useCallback(async (Client_ID, Company_ID) => {
    console.log('Status: Updating client and company', { Client_ID, Company_ID });
    try {
      setState(Prev_State => ({
        ...Prev_State,
        Is_Loading: true,
        Error_Message: null
      }));

      // Get client details
      const Client_Response = await Users_API.getClients(Client_ID);
      console.log('Status: Client response:', Client_Response);

      const Client_Data = normalizeClientResponse(Client_Response, Client_ID);
      if (!Client_Data) {
        throw new Error('Invalid client selection');
      }

      // Get company details
      const Companies_Response = await Users_API.getClientCompanies(Client_ID);
      console.log('Status: Companies response:', Companies_Response);

      const Companies_List = normalizeCompaniesResponse(Companies_Response);
      console.log('Status: Companies list:', Companies_List);
      
      const Selected_Company = Companies_List.find(C => C.Company_ID === Company_ID);
      if (!Selected_Company) {
        throw new Error('Invalid company selection');
      }

      const New_Route = determineInitialRoute(State.User_Data, State.User_Entitlements, State.Is_Super_Admin, Client_ID, Company_ID);

      console.log('Status: Setting new state with context:', {
        Client_ID,
        Company_ID,
        Client_Name: Client_Data.Client_Name,
        Company_Name: Selected_Company.Company_Name,
        New_Route
      });

      setState(Prev_State => ({
        ...Prev_State,
        Client_ID,
        Company_ID,
        Client_Name: Client_Data.Client_Name,
        Company_Name: Selected_Company.Company_Name,
        Initial_Route: New_Route,
        Is_Loading: false,
        Last_Refresh: Date.now()
      }));

      return New_Route;
    } catch (Error) {
      console.error('Status: Failed to update client and company', Error);
      setState(Prev_State => ({
        ...Prev_State,
        Error_Message: Error.message || 'Failed to update client and company',
        Is_Loading: false,
        Client_ID: null,
        Company_ID: null,
        Client_Name: null,
        Company_Name: null
      }));
      throw Error;
    }
  }, [State.User_Data, State.User_Entitlements, State.Is_Super_Admin, determineInitialRoute]);

  // Only refresh status when auth state changes and we haven't refreshed recently
  useEffect(() => {
    console.log('Status: Checking auth state', { 
      Is_Authenticated, 
      Has_Session: !!Session_Data,
      Last_Refresh: State.Last_Refresh
    });

    const Should_Refresh = Is_Authenticated && 
                         Session_Data && 
                         (!State.Last_Refresh || Date.now() - State.Last_Refresh > 5000);

    if (Should_Refresh) {
      refreshUserStatus();
    }
  }, [Is_Authenticated, Session_Data, State.Last_Refresh, refreshUserStatus]);

  const Context_Value = useMemo(() => ({
    ...State,
    Is_Loading: State.Is_Loading,
    Refresh_User_Status: refreshUserStatus,
    Get_Initial_Route: () => State.Initial_Route,
    Update_Client_And_Company: updateClientAndCompany
  }), [State, refreshUserStatus, updateClientAndCompany]);

  return (
    <StatusContext.Provider value={Context_Value}>
      {Props.children}
    </StatusContext.Provider>
  );
};

export { Status, StatusContext, normalizeCompanyFields };
