import React, { useState } from 'react';
import { Button } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faBuilding } from '@fortawesome/free-solid-svg-icons';

const CompanyList = ({ companies = [] }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!companies.length) {
    return (
      <div className="text-gray-400 flex items-center">
        <FontAwesomeIcon icon={faBuilding} className="mr-2 opacity-50" />
        <span>No companies</span>
      </div>
    );
  }

  const displayCompanies = isExpanded ? companies : companies.slice(0, 1);
  const hasMore = companies.length > 1;
  const remainingCount = companies.length - 1;

  return (
    <div className="space-y-2 py-1">
      {displayCompanies.map((company, index) => (
        <div
          key={company.Company_ID}
          className={`flex items-center justify-between p-2 rounded-lg ${
            index > 0 ? 'mt-2 border-t border-gray-100' : ''
          } ${company.Status === 'Active' ? 'bg-green-50' : 'bg-gray-50'}`}
        >
          <div className="flex items-start space-x-3">
            <FontAwesomeIcon 
              icon={faBuilding} 
              className={`mt-1 ${company.Status === 'Active' ? 'text-green-600' : 'text-gray-400'}`}
            />
            <div className="flex flex-col">
              <span className="font-medium text-gray-900">{company.Company_Name}</span>
              <span className="text-sm text-gray-500">{company.Company_ID}</span>
            </div>
          </div>
          <span className={`text-sm px-2 py-1 rounded-full ${
            company.Status === 'Active' 
              ? 'bg-green-100 text-green-800' 
              : 'bg-gray-100 text-gray-800'
          }`}>
            {company.Status}
          </span>
        </div>
      ))}
      
      {hasMore && (
        <Button
          color="light"
          size="xs"
          className="w-full mt-1 group hover:bg-gray-100"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <FontAwesomeIcon
            icon={isExpanded ? faChevronUp : faChevronDown}
            className="mr-2 text-gray-500 group-hover:text-gray-700"
          />
          <span className="text-gray-700">
            {isExpanded 
              ? 'Show less' 
              : `Show ${remainingCount} more ${remainingCount === 1 ? 'company' : 'companies'}`
            }
          </span>
        </Button>
      )}
    </div>
  );
};

export default React.memo(CompanyList);
