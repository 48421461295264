import React from 'react';
import { useCompanyChartData } from '../../contexts/CompanyChartDataContext';
import SparkChart from './SparkChart';
import { fieldsConfig } from '../../configs/fieldsConfig';
import { formatMetricValue, calculateMetricChange } from '../data/prepareChartDataCompany';

const RenderCompanySparkCharts = () => {
    const { Chart_Data, Latest_Metrics, Previous_Metrics, Is_Loading, Error } = useCompanyChartData();

    // Debug log
    console.log('RenderCompanySparkCharts - Raw data:', {
        Has_Chart_Data: !!Chart_Data,
        Chart_Data_Fields: Chart_Data ? Object.keys(Chart_Data) : [],
        Latest_Fields: Latest_Metrics ? Object.keys(Latest_Metrics) : [],
        Is_Loading,
        Error
    });

    if (Is_Loading) {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {[1, 2, 3, 4, 5].map(key => (
                    <div key={key} className="bg-white rounded-lg shadow p-4">
                        <div className="animate-pulse">
                            <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                            <div className="h-8 bg-gray-200 rounded w-1/2"></div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    if (Error) {
        return (
            <div className="text-red-600 p-4">
                Error loading metrics: {Error}
            </div>
        );
    }

    const Spark_Chart_Config = [
        {
            Chart_Title: 'Total Risk',
            Field_Name: 'Total_Risk',
            Field_Type: fieldsConfig.fieldTypes.Total_Risk,
            Chart_Color: '#EF4444'
        },
        {
            Chart_Title: 'Critical Risk',
            Field_Name: 'Total_Risk_Critical',
            Field_Type: fieldsConfig.fieldTypes.Total_Risk_Critical,
            Chart_Color: '#8B5CF6'
        },
        {
            Chart_Title: 'Total Employees',
            Field_Name: 'Count_Total',
            Field_Type: fieldsConfig.fieldTypes.Count_Total,
            Chart_Color: '#3B82F6'
        },
        {
            Chart_Title: 'Red Count',
            Field_Name: 'Count_Red',
            Field_Type: fieldsConfig.fieldTypes.Count_Red,
            Chart_Color: '#EF4444'
        },
        {
            Chart_Title: 'Yellow Count',
            Field_Name: 'Count_Yellow',
            Field_Type: fieldsConfig.fieldTypes.Count_Yellow,
            Chart_Color: '#F59E0B'
        }
    ];

    // Debug log
    console.log('RenderCompanySparkCharts - Chart configurations:', 
        Spark_Chart_Config.map(config => ({
            Field_Name: config.Field_Name,
            Field_Type: config.Field_Type,
            Has_Data: Array.isArray(Chart_Data?.[config.Field_Name]),
            Data_Length: Chart_Data?.[config.Field_Name]?.length || 0,
            Latest_Value: Latest_Metrics?.[config.Field_Name],
            Previous_Value: Previous_Metrics?.[config.Field_Name]
        }))
    );

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Spark_Chart_Config.map(config => {
                const Current_Value = Latest_Metrics?.[config.Field_Name];
                const Previous_Value = Previous_Metrics?.[config.Field_Name];
                const Value_Change = calculateMetricChange(Current_Value, Previous_Value, config.Field_Type);
                const Chart_Series_Data = Chart_Data?.[config.Field_Name] || [];

                // Debug log for each chart
                console.log(`RenderCompanySparkCharts - Processing ${config.Field_Name}:`, {
                    Field_Name: config.Field_Name,
                    Field_Type: config.Field_Type,
                    Data_Points: Chart_Series_Data.length,
                    Current_Value,
                    Previous_Value,
                    Value_Change,
                    Formatted_Value: formatMetricValue(Current_Value, config.Field_Type)
                });

                return (
                    <SparkChart
                        key={config.Field_Name}
                        Chart_Title={config.Chart_Title}
                        Chart_Data={Chart_Series_Data}
                        Chart_Value={formatMetricValue(Current_Value, config.Field_Type)}
                        Value_Change={Value_Change.Text}
                        Chart_Color={config.Chart_Color}
                    />
                );
            })}
        </div>
    );
};

RenderCompanySparkCharts.displayName = 'RenderCompanySparkCharts';

export default RenderCompanySparkCharts;
