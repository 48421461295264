import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const HeatMap = ({ 
  Data_List, 
  Chart_Width = 300, 
  Chart_Height = 300,
  Color_Range = ['#f7fbff', '#08519c'], // Light blue to dark blue
  Legend_Title = 'Value',
  Critical_Items = [], // Array of {Row_Label, Column_Label} for critical items
  On_Cell_Click = null, // Callback for cell clicks
  Enable_Selection = false // Flag to enable/disable cell selection
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 50, Right: 100, Bottom: 30, Left: 60 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Heat Map")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Get unique row and column values
    const Row_Values = [...new Set(Data_List.map(Item => Item.Row_Label))];
    const Column_Values = [...new Set(Data_List.map(Item => Item.Column_Label))];

    // Create scales
    const X_Scale = d3.scaleBand()
      .domain(Column_Values)
      .range([0, Inner_Width])
      .padding(0.05);

    const Y_Scale = d3.scaleBand()
      .domain(Row_Values)
      .range([0, Inner_Height])
      .padding(0.05);

    // Create color scale
    const Color_Scale = d3.scaleSequential()
      .interpolator(d3.interpolateRgb(Color_Range[0], Color_Range[1]))
      .domain([
        d3.min(Data_List, Item => Item.Value),
        d3.max(Data_List, Item => Item.Value)
      ]);

    // Helper function to check if an item is critical
    const Is_Critical = (Row_Label, Column_Label) => {
      return Critical_Items.some(item => 
        item.Row_Label === Row_Label && 
        item.Column_Label === Column_Label
      );
    };

    // Add cells
    const Cells = SVG.selectAll(".cell")
      .data(Data_List)
      .enter()
      .append("rect")
      .attr("class", "cell")
      .attr("x", Item => X_Scale(Item.Column_Label))
      .attr("y", Item => Y_Scale(Item.Row_Label))
      .attr("width", X_Scale.bandwidth())
      .attr("height", Y_Scale.bandwidth())
      .attr("fill", Item => Color_Scale(Item.Value))
      .attr("stroke", Item => Is_Critical(Item.Row_Label, Item.Column_Label) ? "#ff0000" : "#fff")
      .attr("stroke-width", Item => Is_Critical(Item.Row_Label, Item.Column_Label) ? 3 : 1)
      .style("cursor", Enable_Selection ? "pointer" : "default");

    // Add value labels
    SVG.selectAll(".value-label")
      .data(Data_List)
      .enter()
      .append("text")
      .attr("class", "value-label")
      .attr("x", Item => X_Scale(Item.Column_Label) + X_Scale.bandwidth() / 2)
      .attr("y", Item => Y_Scale(Item.Row_Label) + Y_Scale.bandwidth() / 2)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("font-size", "10px")
      .style("fill", Item => d3.lab(Color_Scale(Item.Value)).l < 50 ? "#fff" : "#000")
      .text(Item => Item.Value.toFixed(1));

    // Add critical job indicator (star)
    if (Critical_Items.length > 0) {
      SVG.selectAll(".critical-indicator")
        .data(Critical_Items)
        .enter()
        .append("text")
        .attr("class", "critical-indicator")
        .attr("x", Item => X_Scale(Item.Column_Label) + X_Scale.bandwidth() - 10)
        .attr("y", Item => Y_Scale(Item.Row_Label) + 10)
        .attr("text-anchor", "end")
        .style("font-size", "12px")
        .style("fill", "#ff0000")
        .text("★");
    }

    // Add axes
    const X_Axis = d3.axisBottom(X_Scale);
    const Y_Axis = d3.axisLeft(Y_Scale);

    SVG.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${Inner_Height})`)
      .call(X_Axis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    SVG.append("g")
      .attr("class", "y-axis")
      .call(Y_Axis);

    // Add legend
    const Legend_Width = 20;
    const Legend_Height = Inner_Height;
    const Legend = SVG.append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${Inner_Width + 40},0)`);

    // Create gradient for legend
    const Defs = SVG.append("defs");
    const Gradient = Defs.append("linearGradient")
      .attr("id", "legend-gradient")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%");

    Gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", Color_Range[0]);

    Gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", Color_Range[1]);

    // Add legend rectangle
    Legend.append("rect")
      .attr("width", Legend_Width)
      .attr("height", Legend_Height)
      .style("fill", "url(#legend-gradient)");

    // Add legend scale
    const Legend_Scale = d3.scaleLinear()
      .domain(Color_Scale.domain())
      .range([Legend_Height, 0]);

    const Legend_Axis = d3.axisRight(Legend_Scale)
      .ticks(5);

    Legend.append("g")
      .attr("transform", `translate(${Legend_Width},0)`)
      .call(Legend_Axis);

    // Add legend title
    Legend.append("text")
      .attr("class", "legend-title")
      .attr("x", 0)
      .attr("y", -10)
      .style("text-anchor", "start")
      .text(Legend_Title);

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    // Add click handler if enabled
    if (Enable_Selection && On_Cell_Click) {
      Cells.on("click", (Event, Item) => {
        On_Cell_Click({
          Row_Label: Item.Row_Label,
          Column_Label: Item.Column_Label,
          Value: Item.Value,
          Is_Critical: Is_Critical(Item.Row_Label, Item.Column_Label)
        });
      });
    }

    // Add hover interactions
    Cells.on("mouseover", (Event, Item) => {
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>Row:</strong> ${Item.Row_Label}<br/>
        <strong>Column:</strong> ${Item.Column_Label}<br/>
        <strong>Value:</strong> ${Item.Value.toFixed(2)}
        ${Is_Critical(Item.Row_Label, Item.Column_Label) ? '<br/><strong style="color: #ff0000;">Critical Job</strong>' : ''}
      `)
        .style("left", `${Event.pageX}px`)
        .style("top", `${Event.pageY - 28}px`);
    })
    .on("mouseout", () => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);
    });

  }, [Data_List, Chart_Width, Chart_Height, Color_Range, Legend_Title, Critical_Items, Enable_Selection, On_Cell_Click]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

HeatMap.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Row_Label: PropTypes.string.isRequired,
    Column_Label: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Range: PropTypes.arrayOf(PropTypes.string),
  Legend_Title: PropTypes.string,
  Critical_Items: PropTypes.arrayOf(PropTypes.shape({
    Row_Label: PropTypes.string.isRequired,
    Column_Label: PropTypes.string.isRequired
  })),
  On_Cell_Click: PropTypes.func,
  Enable_Selection: PropTypes.bool
};

HeatMap.Display_Name = 'HeatMap';

export default HeatMap;
