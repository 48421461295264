import React, { useState, useEffect, useContext } from 'react';
import { organizationApi } from '../../api';
import { useCompanyData } from '../../contexts/CompanyContext';
import { LocationContext } from '../../contexts/LocationContext';
import { DepartmentContext } from '../../contexts/DepartmentContext';
import { Button, Table, TextInput, Select, Card, Alert, Spinner } from 'flowbite-react';
import { FiPlus, FiSave, FiTrash2 } from 'react-icons/fi';

const REFERENCE_TYPES = {
    LOCATION: {
        label: 'Locations',
        detailFields: [
            { key: 'Location_Name', label: 'Name', type: 'text' },
            { key: 'Post_Code', label: 'Post Code', type: 'text' },
            { key: 'Country', label: 'Country', type: 'text' },
            { key: 'Impact_Modifier', label: 'Impact Modifier', type: 'number' },
            { key: 'Address_1', label: 'Address 1', type: 'text' },
            { key: 'Address_2', label: 'Address 2', type: 'text' },
            { key: 'City', label: 'City', type: 'text' },
            { key: 'State', label: 'State', type: 'text' }
        ]
    },
    DEPARTMENT: {
        label: 'Departments',
        detailFields: [
            { key: 'Department_Name', label: 'Name', type: 'text' },
            { key: 'Impact_Modifier', label: 'Impact Modifier', type: 'number' }
        ]
    },
    JOB: {
        label: 'Jobs',
        detailFields: [
            { key: 'Job_Title', label: 'Title', type: 'text' },
            { key: 'Job_Number', label: 'Number', type: 'text' },
            { key: 'EEO_Lvl1', label: 'EEO Level', type: 'text' },
            { key: 'Salary_Min', label: 'Min Salary', type: 'number' },
            { key: 'Salary_Max', label: 'Max Salary', type: 'number' },
            { key: 'Exempt', label: 'Exempt', type: 'boolean' }
        ]
    }
};

const ReferenceDataManager = () => {
    const { Company_ID } = useCompanyData();
    const { Locations, Refresh_Locations } = useContext(LocationContext);
    const { Departments, Refresh_Departments } = useContext(DepartmentContext);
    const [Selected_Type, setSelectedType] = useState('LOCATION');
    const [Is_Loading, setIsLoading] = useState(true);
    const [Is_Saving, setIsSaving] = useState(false);
    const [Error, setError] = useState(null);
    const [Detail_Data, setDetailData] = useState([]);
    const [Occurrence_Data, setOccurrenceData] = useState([]);
    const [Edited_Rows, setEditedRows] = useState(new Set());
    const [New_Row, setNewRow] = useState(null);

    useEffect(() => {
        fetchData();
    }, [Company_ID, Selected_Type]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setError(null);

            let Detail_Response, Occurrence_Response;

            switch (Selected_Type) {
                case 'LOCATION':
                    [Detail_Response, Occurrence_Response] = await Promise.all([
                        organizationApi.getLocationDetails(Company_ID),
                        organizationApi.getLocations(Company_ID)
                    ]);
                    break;
                case 'DEPARTMENT':
                    [Detail_Response, Occurrence_Response] = await Promise.all([
                        organizationApi.getDepartmentDetails(Company_ID),
                        organizationApi.getDepartments(Company_ID)
                    ]);
                    break;
                case 'JOB':
                    [Detail_Response, Occurrence_Response] = await Promise.all([
                        organizationApi.getJobDetails(Company_ID),
                        organizationApi.getJobs(Company_ID)
                    ]);
                    break;
            }

            setDetailData(Detail_Response?.Data?.Records || []);
            setOccurrenceData(Occurrence_Response?.Data?.Records || []);
        } catch (err) {
            console.error('Error fetching reference data:', err);
            setError('Error fetching reference data');
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (row, field, value) => {
        const Updated_Data = Detail_Data.map(item => {
            if (item.ID === row.ID) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setDetailData(Updated_Data);
        setEditedRows(prev => new Set(prev).add(row.ID));
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            setError(null);

            const Updates = Detail_Data.filter(row => Edited_Rows.has(row.ID));
            const New_Rows = New_Row ? [New_Row] : [];

            let api;
            switch (Selected_Type) {
                case 'LOCATION':
                    api = organizationApi.updateLocationDetails;
                    break;
                case 'DEPARTMENT':
                    api = organizationApi.updateDepartmentDetails;
                    break;
                case 'JOB':
                    api = organizationApi.updateJobDetails;
                    break;
            }

            await Promise.all([
                ...Updates.map(row => api(Company_ID, row)),
                ...New_Rows.map(row => api(Company_ID, row))
            ]);

            setEditedRows(new Set());
            setNewRow(null);
            await fetchData();
        } catch (err) {
            console.error('Error saving changes:', err);
            setError('Error saving changes');
        } finally {
            setIsSaving(false);
        }
    };

    const handleAddRow = () => {
        const New_ID = `NEW_${Date.now()}`;
        const Empty_Row = {
            ID: New_ID,
            Company_ID,
            ...REFERENCE_TYPES[Selected_Type].detailFields.reduce((acc, field) => ({
                ...acc,
                [field.key]: field.type === 'boolean' ? false : ''
            }), {})
        };
        setNewRow(Empty_Row);
    };

    const handleDeleteRow = async (row) => {
        if (!window.confirm('Are you sure you want to delete this item?')) return;

        try {
            setIsSaving(true);
            setError(null);

            let api;
            switch (Selected_Type) {
                case 'LOCATION':
                    api = organizationApi.deleteLocation;
                    break;
                case 'DEPARTMENT':
                    api = organizationApi.deleteDepartment;
                    break;
                case 'JOB':
                    api = organizationApi.deleteJob;
                    break;
            }

            await api(Company_ID, row.ID);
            await fetchData();
        } catch (err) {
            console.error('Error deleting item:', err);
            setError('Error deleting item');
        } finally {
            setIsSaving(false);
        }
    };

    if (Is_Loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <Spinner size="xl" />
            </div>
        );
    }

    return (
        <div className="space-y-6">
            {Error && (
                <Alert color="failure">
                    <span className="font-medium">Error!</span> {Error}
                </Alert>
            )}

            <div className="flex justify-between items-center">
                <Select
                    value={Selected_Type}
                    onChange={(e) => setSelectedType(e.target.value)}
                    className="w-48"
                >
                    {Object.entries(REFERENCE_TYPES).map(([value, { label }]) => (
                        <option key={value} value={value}>{label}</option>
                    ))}
                </Select>

                <div className="space-x-2">
                    <Button
                        color="blue"
                        onClick={handleAddRow}
                        disabled={Is_Saving}
                    >
                        <FiPlus className="mr-2 h-4 w-4" />
                        Add New
                    </Button>
                    {(Edited_Rows.size > 0 || New_Row) && (
                        <Button
                            color="green"
                            onClick={handleSave}
                            disabled={Is_Saving}
                        >
                            <FiSave className="mr-2 h-4 w-4" />
                            Save Changes
                        </Button>
                    )}
                </div>
            </div>

            <Card>
                <div className="overflow-x-auto">
                    <Table>
                        <Table.Head>
                            {REFERENCE_TYPES[Selected_Type].detailFields.map(field => (
                                <Table.HeadCell key={field.key}>
                                    {field.label}
                                </Table.HeadCell>
                            ))}
                            <Table.HeadCell>Actions</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {New_Row && (
                                <Table.Row className="bg-blue-50">
                                    {REFERENCE_TYPES[Selected_Type].detailFields.map(field => (
                                        <Table.Cell key={field.key}>
                                            {field.type === 'boolean' ? (
                                                <input
                                                    type="checkbox"
                                                    checked={New_Row[field.key]}
                                                    onChange={(e) => setNewRow({
                                                        ...New_Row,
                                                        [field.key]: e.target.checked
                                                    })}
                                                    className="w-4 h-4"
                                                />
                                            ) : (
                                                <TextInput
                                                    type={field.type}
                                                    value={New_Row[field.key]}
                                                    onChange={(e) => setNewRow({
                                                        ...New_Row,
                                                        [field.key]: e.target.value
                                                    })}
                                                    className="w-full"
                                                />
                                            )}
                                        </Table.Cell>
                                    ))}
                                    <Table.Cell>
                                        <Button
                                            size="xs"
                                            color="failure"
                                            onClick={() => setNewRow(null)}
                                        >
                                            <FiTrash2 className="h-4 w-4" />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {Detail_Data.map(row => (
                                <Table.Row key={row.ID} className={Edited_Rows.has(row.ID) ? 'bg-yellow-50' : ''}>
                                    {REFERENCE_TYPES[Selected_Type].detailFields.map(field => (
                                        <Table.Cell key={field.key}>
                                            {field.type === 'boolean' ? (
                                                <input
                                                    type="checkbox"
                                                    checked={row[field.key]}
                                                    onChange={(e) => handleEdit(row, field.key, e.target.checked)}
                                                    className="w-4 h-4"
                                                />
                                            ) : (
                                                <TextInput
                                                    type={field.type}
                                                    value={row[field.key]}
                                                    onChange={(e) => handleEdit(row, field.key, e.target.value)}
                                                    className="w-full"
                                                />
                                            )}
                                        </Table.Cell>
                                    ))}
                                    <Table.Cell>
                                        <Button
                                            size="xs"
                                            color="failure"
                                            onClick={() => handleDeleteRow(row)}
                                            disabled={Is_Saving}
                                        >
                                            <FiTrash2 className="h-4 w-4" />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Card>

            {/* Occurrence Data Table */}
            <Card>
                <h3 className="text-lg font-semibold mb-4">Historical Data</h3>
                <div className="overflow-x-auto">
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>Period Date</Table.HeadCell>
                            <Table.HeadCell>Total Count</Table.HeadCell>
                            <Table.HeadCell>Male Count</Table.HeadCell>
                            <Table.HeadCell>Female Count</Table.HeadCell>
                            <Table.HeadCell>Total Risk</Table.HeadCell>
                            <Table.HeadCell>Critical Count</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {Occurrence_Data.map(row => (
                                <Table.Row key={`${row.ID}_${row.Period_Date}`}>
                                    <Table.Cell>{new Date(row.Period_Date).toLocaleDateString()}</Table.Cell>
                                    <Table.Cell>{row.count_total}</Table.Cell>
                                    <Table.Cell>{row.count_male}</Table.Cell>
                                    <Table.Cell>{row.count_female}</Table.Cell>
                                    <Table.Cell>{row.total_risk}</Table.Cell>
                                    <Table.Cell>{row.count_total_critical}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Card>
        </div>
    );
};

export default ReferenceDataManager;
