import React from "react";
import { Footer } from "flowbite-react";

const AppFooter = () => {
  return (
    <Footer container>
      <Footer.Copyright href="#" by="HappyHippo AI" year={2024} />
    </Footer>
  );
};

AppFooter.Display_Name = 'AppFooter';

export default AppFooter;
