import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import './D3WorkflowVisualizer.css';

const WorkflowStep = ({ step, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: step.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="workflow-step"
      {...attributes}
      {...listeners}
    >
      <div className="step-content">
        <span className="step-index">Step {index + 1}</span>
        <span className="step-title">{step.name || step}</span>
      </div>
    </div>
  );
};

const D3WorkflowVisualizer = ({ steps, onStepsReorder }) => {
  const svgRef = useRef(null);
  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    if (!steps?.length) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const width = svg.node().getBoundingClientRect().width;
    const height = steps.length * 100;
    
    svg.attr('height', height);

    // Draw connecting lines between steps
    const lineGroup = svg.append('g');
    steps.forEach((_, i) => {
      if (i < steps.length - 1) {
        lineGroup
          .append('path')
          .attr('class', 'connector-line')
          .attr('d', `M ${width/2} ${(i * 100) + 50} L ${width/2} ${((i + 1) * 100) + 30}`)
          .attr('stroke', '#e5e7eb')
          .attr('stroke-width', 2)
          .attr('marker-end', 'url(#arrow)');
      }
    });

    // Add arrow marker definition
    svg.append('defs')
      .append('marker')
      .attr('id', 'arrow')
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 8)
      .attr('refY', 0)
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto')
      .append('path')
      .attr('class', 'arrow-head')
      .attr('d', 'M0,-5L10,0L0,5')
      .attr('fill', '#e5e7eb');

  }, [steps]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = steps.findIndex(step => step.id === active.id);
      const newIndex = steps.findIndex(step => step.id === over.id);
      
      const newSteps = [...steps];
      const [removed] = newSteps.splice(oldIndex, 1);
      newSteps.splice(newIndex, 0, removed);
      
      onStepsReorder(newSteps);
    }
  };

  return (
    <div className="workflow-visualizer">
      <svg ref={svgRef} width="100%" />
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={steps.map(s => s.id)} strategy={verticalListSortingStrategy}>
          {steps.map((step, index) => (
            <WorkflowStep key={step.id} step={step} index={index} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default D3WorkflowVisualizer;
