import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const D3SpiderChart = ({
  data,
  Chart_Width = 400,
  Chart_Height = 400,
  Margin = { Top: 50, Right: 50, Bottom: 50, Left: 50 },
  Color_Scale = d3.scaleOrdinal(d3.schemeCategory10),
  Show_Legend = true,
  Show_Labels = true,
  Show_Tooltips = true
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;
    const Radius = Math.min(Inner_Width, Inner_Height) / 2;

    // Create SVG
    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Spider Chart")
      .append("g")
      .attr("transform", `translate(${Chart_Width/2},${Chart_Height/2})`);

    // Get all metrics (axes)
    const Metrics = Object.keys(data[0].Values);
    const Angle_Slice = Math.PI * 2 / Metrics.length;

    // Scale for axes
    const Radius_Scale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, Radius]);

    // Draw the grid lines
    const Grid_Lines = [20, 40, 60, 80, 100];
    Grid_Lines.forEach(level => {
      const Grid_Circle = SVG.append("circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", Radius_Scale(level))
        .attr("class", "grid-circle")
        .style("fill", "none")
        .style("stroke", "gray")
        .style("stroke-opacity", 0.2);

      // Add level labels
      SVG.append("text")
        .attr("x", 0)
        .attr("y", -Radius_Scale(level))
        .attr("dy", "-0.4em")
        .style("text-anchor", "middle")
        .style("font-size", "10px")
        .style("fill", "gray")
        .text(level.toString());
    });

    // Draw the axes
    const Axes = SVG.selectAll(".axis")
      .data(Metrics)
      .enter()
      .append("g")
      .attr("class", "axis");

    Axes.append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", (d, i) => Radius_Scale(100) * Math.cos(Angle_Slice * i - Math.PI/2))
      .attr("y2", (d, i) => Radius_Scale(100) * Math.sin(Angle_Slice * i - Math.PI/2))
      .style("stroke", "gray")
      .style("stroke-width", "1px");

    // Add axis labels
    if (Show_Labels) {
      Axes.append("text")
        .attr("class", "axis-label")
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .attr("x", (d, i) => Radius_Scale(100 * 1.1) * Math.cos(Angle_Slice * i - Math.PI/2))
        .attr("y", (d, i) => Radius_Scale(100 * 1.1) * Math.sin(Angle_Slice * i - Math.PI/2))
        .text(d => d);
    }

    // Draw the data paths
    const Line = d3.lineRadial()
      .radius(d => Radius_Scale(d.Value))
      .angle((d, i) => i * Angle_Slice);

    const Area = d3.areaRadial()
      .radius(d => Radius_Scale(d.Value))
      .angle((d, i) => i * Angle_Slice);

    data.forEach((dataPoint, index) => {
      const Data_Points = Metrics.map(metric => ({
        Metric: metric,
        Value: dataPoint.Values[metric]
      }));

      // Draw area
      SVG.append("path")
        .datum(Data_Points)
        .attr("class", `area-${index}`)
        .attr("d", Area)
        .style("fill", Color_Scale(index))
        .style("fill-opacity", 0.2);

      // Draw line
      SVG.append("path")
        .datum(Data_Points)
        .attr("class", `line-${index}`)
        .attr("d", Line)
        .style("fill", "none")
        .style("stroke", Color_Scale(index))
        .style("stroke-width", "2px");

      // Add points
      SVG.selectAll(`.point-${index}`)
        .data(Data_Points)
        .enter()
        .append("circle")
        .attr("class", `point-${index}`)
        .attr("cx", (d, i) => Radius_Scale(d.Value) * Math.cos(Angle_Slice * i - Math.PI/2))
        .attr("cy", (d, i) => Radius_Scale(d.Value) * Math.sin(Angle_Slice * i - Math.PI/2))
        .attr("r", 4)
        .style("fill", Color_Scale(index));
    });

    // Add legend
    if (Show_Legend) {
      const Legend = SVG.append("g")
        .attr("class", "legend")
        .attr("transform", `translate(${Radius + 20},-${Radius})`);

      data.forEach((dataPoint, index) => {
        const Legend_Item = Legend.append("g")
          .attr("transform", `translate(0,${index * 20})`);

        Legend_Item.append("rect")
          .attr("width", 15)
          .attr("height", 15)
          .attr("fill", Color_Scale(index));

        Legend_Item.append("text")
          .attr("x", 20)
          .attr("y", 12)
          .text(dataPoint.Label)
          .style("font-size", "12px");
      });
    }

    // Add tooltips
    if (Show_Tooltips) {
      const Tooltip = d3.select(SVG_Ref.current.parentNode)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("background-color", "white")
        .style("padding", "8px")
        .style("border-radius", "4px")
        .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

      SVG.selectAll("circle")
        .on("mouseover", (Event, d) => {
          Tooltip.transition()
            .duration(200)
            .style("opacity", 0.9);
          Tooltip.html(`
            <strong>${d.Metric}</strong><br/>
            Value: ${d.Value}
          `)
            .style("left", `${Event.pageX + 10}px`)
            .style("top", `${Event.pageY - 28}px`);
        })
        .on("mouseout", () => {
          Tooltip.transition()
            .duration(500)
            .style("opacity", 0);
        });
    }

  }, [data, Chart_Width, Chart_Height, Margin, Color_Scale, Show_Legend, Show_Labels, Show_Tooltips]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

D3SpiderChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Values: PropTypes.object.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Margin: PropTypes.shape({
    Top: PropTypes.number,
    Right: PropTypes.number,
    Bottom: PropTypes.number,
    Left: PropTypes.number
  }),
  Color_Scale: PropTypes.func,
  Show_Legend: PropTypes.bool,
  Show_Labels: PropTypes.bool,
  Show_Tooltips: PropTypes.bool
};

export default D3SpiderChart;
