import React from 'react';
import { useCompanyChartData } from '../../contexts/CompanyChartDataContext';
import { fieldsConfig } from '../../configs/fieldsConfig';
import { formatMetricValue, calculateMetricChange } from '../data/prepareChartDataCompany';

const RenderCompanyCards = () => {
    const { Latest_Metrics, Previous_Metrics, Is_Loading, Error } = useCompanyChartData();

    // Debug log
    console.log('RenderCompanyCards - Raw data:', {
        Latest: Latest_Metrics,
        Previous: Previous_Metrics,
        Is_Loading,
        Error,
        Available_Fields: Latest_Metrics ? Object.keys(Latest_Metrics) : []
    });

    const Card_Config = [
        {
            Card_Title: 'Total Employees',
            Field_Name: 'Count_Total',
            Field_Type: fieldsConfig.fieldTypes.Count_Total,
            Text_Color: 'text-blue-600'
        },
        {
            Card_Title: 'Total Risk',
            Field_Name: 'Total_Risk',
            Field_Type: fieldsConfig.fieldTypes.Total_Risk,
            Text_Color: 'text-red-600'
        },
        {
            Card_Title: 'Critical Risk',
            Field_Name: 'Total_Risk_Critical',
            Field_Type: fieldsConfig.fieldTypes.Total_Risk_Critical,
            Text_Color: 'text-purple-600'
        }
    ];

    if (Is_Loading) {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Card_Config.map(config => (
                    <div key={config.Field_Name} className="bg-white rounded-lg shadow p-4">
                        <div className="animate-pulse">
                            <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                            <div className="h-8 bg-gray-200 rounded w-1/2"></div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    if (Error) {
        return (
            <div className="text-red-600 p-4">
                Error loading metrics: {Error}
            </div>
        );
    }

    if (!Latest_Metrics) {
        return (
            <div className="text-gray-600 p-4">
                No metrics data available
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Card_Config.map(config => {
                const Current_Value = Latest_Metrics[config.Field_Name] ?? 0;
                const Previous_Value = Previous_Metrics?.[config.Field_Name] ?? 0;
                const Value_Change = calculateMetricChange(Current_Value, Previous_Value, config.Field_Type);

                // Debug log
                console.log(`RenderCompanyCards - Processing ${config.Field_Name}:`, {
                    Field_Name: config.Field_Name,
                    Field_Type: config.Field_Type,
                    Current_Value,
                    Previous_Value,
                    Value_Change,
                    Formatted_Value: formatMetricValue(Current_Value, config.Field_Type)
                });

                return (
                    <div key={config.Field_Name} className="bg-white rounded-lg shadow p-4">
                        <h3 className="text-sm font-medium text-gray-500">{config.Card_Title}</h3>
                        <p className="mt-1 text-2xl font-semibold">
                            {formatMetricValue(Current_Value, config.Field_Type)}
                        </p>
                        <p className={`mt-1 text-sm ${Value_Change.Value > 0 ? 'text-green-600' : Value_Change.Value < 0 ? 'text-red-600' : 'text-gray-500'}`}>
                            {Value_Change.Text} from previous
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

RenderCompanyCards.displayName = 'RenderCompanyCards';

export default RenderCompanyCards;
