import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const D3StackedBarChart = ({
  Data_List,
  Chart_Width = 800,
  Chart_Height = 400,
  Margin = { Top: 20, Right: 80, Bottom: 30, Left: 120 },
  Is_Horizontal = true,
  Use_Percentage = true,
  Colors = ['#008450', '#EFB700', '#B81D13'], // Green, Yellow, Red
  Keys = ['green', 'yellow', 'red'],
  Labels = ['Low Risk', 'Medium Risk', 'High Risk']
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    // Create SVG
    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Process data for stacking
    const Stack = d3.stack()
      .keys(Keys)
      .order(d3.stackOrderNone)
      .offset(Use_Percentage ? d3.stackOffsetExpand : d3.stackOffsetNone);

    const Series = Stack(Data_List);

    // Create scales
    const Y_Scale = d3.scaleBand()
      .domain(Data_List.map(d => d.name))
      .range([0, Inner_Height])
      .padding(0.1);

    const X_Scale = d3.scaleLinear()
      .domain([0, Use_Percentage ? 1 : d3.max(Series, d => d3.max(d, d => d[1]))])
      .range([0, Inner_Width]);

    const Color_Scale = d3.scaleOrdinal()
      .domain(Keys)
      .range(Colors);

    // Add bars
    SVG.append("g")
      .selectAll("g")
      .data(Series)
      .join("g")
        .attr("fill", d => Color_Scale(d.key))
      .selectAll("rect")
      .data(d => d)
      .join("rect")
        .attr("y", d => Y_Scale(d.data.name))
        .attr("x", d => X_Scale(d[0]))
        .attr("width", d => X_Scale(d[1]) - X_Scale(d[0]))
        .attr("height", Y_Scale.bandwidth());

    // Add axes
    const Y_Axis = d3.axisLeft(Y_Scale);
    SVG.append("g")
      .attr("class", "y-axis")
      .call(Y_Axis);

    const X_Axis = d3.axisBottom(X_Scale)
      .tickFormat(Use_Percentage ? d3.format(".0%") : d3.format("d"));
    SVG.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${Inner_Height})`)
      .call(X_Axis);

    // Add legend
    const Legend = SVG.append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${Inner_Width + 10}, 0)`);

    Keys.forEach((Key, Index) => {
      const Legend_Item = Legend.append("g")
        .attr("transform", `translate(0, ${Index * 20})`);

      Legend_Item.append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", Color_Scale(Key));

      Legend_Item.append("text")
        .attr("x", 20)
        .attr("y", 12)
        .text(Labels[Index])
        .style("font-size", "12px");
    });

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    SVG.selectAll("rect")
      .on("mouseover", (Event, d) => {
        const Risk_Level = d3.select(Event.target.parentNode).datum().key;
        const Value = d[1] - d[0];
        const Percentage = ((Value) * 100).toFixed(1);

        Tooltip.transition()
          .duration(200)
          .style("opacity", 0.9);
        Tooltip.html(`
          <strong>${d.data.name}</strong><br/>
          ${Labels[Keys.indexOf(Risk_Level)]}: ${Percentage}%
        `)
          .style("left", `${Event.pageX}px`)
          .style("top", `${Event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        Tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }, [Data_List, Chart_Width, Chart_Height, Margin, Is_Horizontal, Use_Percentage, Colors, Keys, Labels]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

D3StackedBarChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    red: PropTypes.number.isRequired,
    yellow: PropTypes.number.isRequired,
    green: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Margin: PropTypes.shape({
    Top: PropTypes.number,
    Right: PropTypes.number,
    Bottom: PropTypes.number,
    Left: PropTypes.number
  }),
  Is_Horizontal: PropTypes.bool,
  Use_Percentage: PropTypes.bool,
  Colors: PropTypes.arrayOf(PropTypes.string),
  Keys: PropTypes.arrayOf(PropTypes.string),
  Labels: PropTypes.arrayOf(PropTypes.string)
};

export default D3StackedBarChart;
