import { API_Client } from '../core/apiClient';
import { API_ENDPOINTS } from '../core/constants';

/**
 * Organization structure related API calls
 */
export const Structure_API = {
    // Organization structure methods
    getOrganization: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.ORGANIZATION, {
            params: { Company_ID }
        });
    },

    // Department methods
    getDepartment: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.DEPARTMENT, {
            params: { Company_ID }
        });
    },

    getDepartmentDetails: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
            params: { Company_ID }
        });
    },

    addDepartment: async (Company_ID, Department_Data) => {
        return await API_Client.post(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
            ...Department_Data,
            Company_ID
        });
    },

    updateDepartment: async (Company_ID, Department_ID, Department_Data) => {
        return await API_Client.put(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
            ...Department_Data,
            Company_ID,
            Department_ID
        });
    },

    deleteDepartment: async (Company_ID, Department_ID) => {
        return await API_Client.delete(API_ENDPOINTS.COMPANY.DEPARTMENT_DETAIL, {
            params: {
                Company_ID,
                Department_ID
            }
        });
    },

    // Job methods
    getJobs: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.JOB, {
            params: { Company_ID }
        });
    },

    getJobDetails: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
            params: { Company_ID }
        });
    },

    addJob: async (Company_ID, Job_Data) => {
        return await API_Client.post(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
            ...Job_Data,
            Company_ID
        });
    },

    updateJob: async (Company_ID, Job_ID, Job_Data) => {
        return await API_Client.put(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
            ...Job_Data,
            Company_ID,
            Job_ID
        });
    },

    deleteJob: async (Company_ID, Job_ID) => {
        return await API_Client.delete(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
            params: {
                Company_ID,
                Job_ID
            }
        });
    },

    // Location methods
    getLocations: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.LOCATION, {
            params: { Company_ID }
        });
    },

    getLocationDetails: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
            params: { Company_ID }
        });
    },

    addLocation: async (Company_ID, Location_Data) => {
        return await API_Client.post(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
            ...Location_Data,
            Company_ID
        });
    },

    updateLocation: async (Company_ID, Work_Location_ID, Location_Data) => {
        return await API_Client.put(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
            ...Location_Data,
            Company_ID,
            Work_Location_ID
        });
    },

    deleteLocation: async (Company_ID, Work_Location_ID) => {
        return await API_Client.delete(API_ENDPOINTS.COMPANY.LOCATION_DETAIL, {
            params: {
                Company_ID,
                Work_Location_ID
            }
        });
    },

    // Critical jobs methods
    getCriticalJobs: async (Company_ID) => {
        return await API_Client.get(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
            params: {
                Company_ID,
                Critical: true
            }
        });
    },

    addCriticalJob: async (Company_ID, Job_Data) => {
        return await API_Client.post(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
            ...Job_Data,
            Company_ID,
            Critical: true
        });
    },

    removeCriticalJob: async (Company_ID, Job_ID) => {
        return await API_Client.put(API_ENDPOINTS.COMPANY.JOB_DETAIL, {
            Company_ID,
            Job_ID,
            Critical: false
        });
    }
};

// Export for backward compatibility
export const organizationApi = Structure_API;
