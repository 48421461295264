import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { StatusContext } from '../../contexts/Status';
import TopNavigation from '../../components/common/TopNavigation';
import AdminSecondaryNav from '../../components/common/AdminSecondaryNav';

const DashboardAdmin = () => {
  const { Company_ID, Is_Loading } = useContext(StatusContext);

  // Debug log
  console.log('DashboardAdmin - Initializing:', {
    Company_ID,
    Is_Loading
  });

  if (Is_Loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // Debug log successful render
  console.log('DashboardAdmin - Rendering with Company_ID:', Company_ID);

  return (
    <div className="min-h-screen bg-gray-50">
      <TopNavigation />
      <AdminSecondaryNav />
      <div className="container mx-auto px-4 py-8">
        <Outlet />
      </div>
    </div>
  );
};

DashboardAdmin.Display_Name = 'DashboardAdmin';

export default DashboardAdmin;
