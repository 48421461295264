import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BubbleChart = ({
  Data_List,
  Chart_Width = 300,
  Chart_Height = 300,
  Color_Scheme = d3.schemeCategory10,
  Is_Animated = true,
  Show_Labels = true
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 40, Bottom: 40, Left: 40 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Bubble Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Create scales
    const X_Scale = d3.scaleLinear()
      .domain([0, d3.max(Data_List, Item => Item.X_Value)])
      .range([0, Inner_Width])
      .nice();

    const Y_Scale = d3.scaleLinear()
      .domain([0, d3.max(Data_List, Item => Item.Y_Value)])
      .range([Inner_Height, 0])
      .nice();

    const Radius_Scale = d3.scaleSqrt()
      .domain([0, d3.max(Data_List, Item => Item.Value)])
      .range([5, 50]);

    const Color_Scale = d3.scaleOrdinal()
      .domain(Data_List.map(Item => Item.Category))
      .range(Color_Scheme);

    // Add grid lines
    SVG.append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,${Inner_Height})`)
      .call(d3.axisBottom(X_Scale)
        .tickSize(-Inner_Height)
        .tickFormat("")
      );

    SVG.append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(Y_Scale)
        .tickSize(-Inner_Width)
        .tickFormat("")
      );

    // Add bubbles
    const Bubbles = SVG.selectAll(".bubble")
      .data(Data_List)
      .enter()
      .append("circle")
      .attr("class", "bubble")
      .attr("cx", Item => X_Scale(Item.X_Value))
      .attr("cy", Item => Y_Scale(Item.Y_Value))
      .attr("fill", Item => Color_Scale(Item.Category))
      .attr("opacity", 0.7)
      .attr("stroke", "#fff")
      .attr("stroke-width", 1);

    if (Is_Animated) {
      Bubbles.attr("r", 0)
        .transition()
        .duration(1000)
        .attr("r", Item => Radius_Scale(Item.Value));
    } else {
      Bubbles.attr("r", Item => Radius_Scale(Item.Value));
    }

    // Add labels if enabled
    if (Show_Labels) {
      const Labels = SVG.selectAll(".label")
        .data(Data_List)
        .enter()
        .append("text")
        .attr("class", "label")
        .attr("x", Item => X_Scale(Item.X_Value))
        .attr("y", Item => Y_Scale(Item.Y_Value))
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .style("font-size", "12px")
        .style("pointer-events", "none")
        .style("opacity", 0)
        .text(Item => Item.Label);

      if (Is_Animated) {
        Labels.transition()
          .delay(1000)
          .duration(500)
          .style("opacity", 1);
      } else {
        Labels.style("opacity", 1);
      }
    }

    // Add axes
    const X_Axis = d3.axisBottom(X_Scale);
    const Y_Axis = d3.axisLeft(Y_Scale);

    SVG.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${Inner_Height})`)
      .call(X_Axis);

    SVG.append("g")
      .attr("class", "y-axis")
      .call(Y_Axis);

    // Add axis labels
    SVG.append("text")
      .attr("class", "x-label")
      .attr("x", Inner_Width / 2)
      .attr("y", Inner_Height + Margin.Bottom - 5)
      .attr("text-anchor", "middle")
      .text("X Axis");

    SVG.append("text")
      .attr("class", "y-label")
      .attr("transform", "rotate(-90)")
      .attr("x", -Inner_Height / 2)
      .attr("y", -Margin.Left + 15)
      .attr("text-anchor", "middle")
      .text("Y Axis");

    // Add legend
    const Legend = SVG.append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${Inner_Width - 100}, 0)`);

    const Categories = [...new Set(Data_List.map(Item => Item.Category))];
    Categories.forEach((Category, Index) => {
      const Legend_Item = Legend.append("g")
        .attr("transform", `translate(0, ${Index * 20})`);

      Legend_Item.append("circle")
        .attr("r", 6)
        .attr("fill", Color_Scale(Category));

      Legend_Item.append("text")
        .attr("x", 15)
        .attr("y", 4)
        .text(Category)
        .style("font-size", "12px");
    });

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Bubbles.on("mouseover", (Event, Item) => {
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>${Item.Label}</strong><br/>
        Category: ${Item.Category}<br/>
        Value: ${Item.Value}<br/>
        X: ${Item.X_Value}<br/>
        Y: ${Item.Y_Value}
      `)
        .style("left", `${Event.pageX}px`)
        .style("top", `${Event.pageY - 28}px`);

      d3.select(Event.target)
        .transition()
        .duration(200)
        .attr("opacity", 1)
        .attr("stroke-width", 2);
    })
    .on("mouseout", (Event) => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);

      d3.select(Event.target)
        .transition()
        .duration(200)
        .attr("opacity", 0.7)
        .attr("stroke-width", 1);
    });

  }, [Data_List, Chart_Width, Chart_Height, Color_Scheme, Is_Animated, Show_Labels]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

BubbleChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Category: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired,
    X_Value: PropTypes.number.isRequired,
    Y_Value: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Scheme: PropTypes.arrayOf(PropTypes.string),
  Is_Animated: PropTypes.bool,
  Show_Labels: PropTypes.bool
};

BubbleChart.Display_Name = 'BubbleChart';

export default BubbleChart;
