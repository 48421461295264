import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WaterfallChart = ({
  Data_List,
  Chart_Width = 300,
  Chart_Height = 300,
  Positive_Color = '#22c55e',  // Green
  Negative_Color = '#ef4444',  // Red
  Total_Color = '#3b82f6',     // Blue
  Show_Connectors = true
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 40, Bottom: 60, Left: 60 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Waterfall Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Calculate running total and determine y-axis domain
    let Running_Total = 0;
    const Enriched_Data = Data_List.map((Item, Index) => {
      const Start_Value = Running_Total;
      Running_Total += Item.Value;
      return {
        ...Item,
        Start_Value,
        End_Value: Running_Total,
        Is_Total: Item.Is_Total || Index === Data_List.length - 1
      };
    });

    // Create scales
    const X_Scale = d3.scaleBand()
      .domain(Data_List.map(Item => Item.Label))
      .range([0, Inner_Width])
      .padding(0.3);

    const Y_Scale = d3.scaleLinear()
      .domain([
        Math.min(0, d3.min(Enriched_Data, Item => Math.min(Item.Start_Value, Item.End_Value))),
        Math.max(0, d3.max(Enriched_Data, Item => Math.max(Item.Start_Value, Item.End_Value)))
      ])
      .range([Inner_Height, 0])
      .nice();

    // Add bars
    const Bars = SVG.selectAll(".bar")
      .data(Enriched_Data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", Item => X_Scale(Item.Label))
      .attr("width", X_Scale.bandwidth())
      .attr("y", Item => Y_Scale(Math.max(Item.Start_Value, Item.End_Value)))
      .attr("height", Item => Math.abs(Y_Scale(Item.Start_Value) - Y_Scale(Item.End_Value)))
      .attr("fill", Item => {
        if (Item.Is_Total) return Total_Color;
        return Item.Value >= 0 ? Positive_Color : Negative_Color;
      });

    // Add connectors if enabled
    if (Show_Connectors) {
      const Connectors = SVG.selectAll(".connector")
        .data(Enriched_Data.slice(0, -1))
        .enter()
        .append("line")
        .attr("class", "connector")
        .attr("x1", Item => X_Scale(Item.Label) + X_Scale.bandwidth())
        .attr("x2", (Item, Index) => X_Scale(Enriched_Data[Index + 1].Label))
        .attr("y1", Item => Y_Scale(Item.End_Value))
        .attr("y2", (Item, Index) => Y_Scale(Enriched_Data[Index + 1].Start_Value))
        .attr("stroke", "#9ca3af")
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "4,4");
    }

    // Add value labels
    SVG.selectAll(".value-label")
      .data(Enriched_Data)
      .enter()
      .append("text")
      .attr("class", "value-label")
      .attr("x", Item => X_Scale(Item.Label) + X_Scale.bandwidth() / 2)
      .attr("y", Item => {
        const Y_Position = Y_Scale(Math.max(Item.Start_Value, Item.End_Value));
        const Bar_Height = Math.abs(Y_Scale(Item.Start_Value) - Y_Scale(Item.End_Value));
        return Y_Position + Bar_Height / 2;
      })
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("fill", "white")
      .style("font-size", "12px")
      .text(Item => Item.Value.toFixed(1));

    // Add axes
    const X_Axis = d3.axisBottom(X_Scale);
    const Y_Axis = d3.axisLeft(Y_Scale);

    SVG.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${Y_Scale(0)})`)
      .call(X_Axis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    SVG.append("g")
      .attr("class", "y-axis")
      .call(Y_Axis);

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Bars.on("mouseover", (Event, Item) => {
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>${Item.Label}</strong><br/>
        Start: ${Item.Start_Value.toFixed(2)}<br/>
        End: ${Item.End_Value.toFixed(2)}<br/>
        Change: ${Item.Value.toFixed(2)}
      `)
        .style("left", `${Event.pageX}px`)
        .style("top", `${Event.pageY - 28}px`);
    })
    .on("mouseout", () => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);
    });

  }, [Data_List, Chart_Width, Chart_Height, Positive_Color, Negative_Color, Total_Color, Show_Connectors]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

WaterfallChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired,
    Is_Total: PropTypes.bool
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Positive_Color: PropTypes.string,
  Negative_Color: PropTypes.string,
  Total_Color: PropTypes.string,
  Show_Connectors: PropTypes.bool
};

WaterfallChart.Display_Name = 'WaterfallChart';

export default WaterfallChart;
