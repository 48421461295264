/**
 * API Constants and Configuration
 * @module API_Constants
 */

/**
 * API Endpoints organized by category
 * @constant {Object}
 */
export const API_ENDPOINTS = {
    // Organization/Structure Endpoints
    COMPANY: {
        BASE: '/prod/tim/company',
        DEPARTMENT: '/prod/tim/company/department',
        DEPARTMENT_DETAIL: '/prod/tim/company/deptdetail',
        LOCATION: '/prod/tim/company/location',
        LOCATION_DETAIL: '/prod/tim/company/locationdetail',
        ORGANIZATION: '/prod/tim/company/org',
        JOB: '/prod/tim/company/job',
        JOB_DETAIL: '/prod/tim/company/jobdetail',
        ACTION: '/prod/tim/company/action'
    },

    // Employee/Performance Management System Endpoints
    EMPLOYEE: {
        BASE: '/prod/tim/emp',
        PMS: {
            BASE: '/prod/tim/pms',
            CONTRIBUTION: '/prod/tim/pms/contribution',
            SUPERVISION: '/prod/tim/pms/supervision',
            WORKSTYLE: '/prod/tim/pms/workstyle',
            CAREER: '/prod/tim/pms/career',
            REHIRE: '/prod/tim/pms/rehire',
            REGRETTABLE: '/prod/tim/pms/regrettable',
            RECOMMENDATIONS: '/prod/tim/pms/recommendations',
            PROGRESS: '/prod/tim/pms/progress'
        }
    },

    // Analytics Endpoints
    ANALYTICS: {
        ATTRITION: {
            COMPANY: '/prod/tim/attrit/co',
            LOCATION: '/prod/tim/attrit/loc',
            DEPARTMENT: '/prod/tim/attrit/dept',
            JOB: '/prod/tim/attrit/job'
        },
        TENURE: '/prod/tim/ten',
        WAGE: '/prod/tim/wa'
    },

    // Workflow/Actions Endpoints
    WORKFLOW: {
        APPROVALS: '/prod/tim/approvalgroup',
        ACTIONS: '/prod/tim/config/actions',
        WORKFLOWS: '/prod/tim/config/approvals'
    },

    // User Management Endpoints
    USERS: {
        BASE: '/prod/tim/user',
        ENTITLEMENTS: '/prod/tim/user/entitle'
    },

    // Client Management Endpoints
    CLIENTS: {
        BASE: '/prod/tim/clients',
        COMPANIES: '/prod/tim/clients/companies'
    },

    // Communication Endpoints
    EMAIL: {
        BASE: '/prod/tim/email',
        TEMPLATE: '/prod/tim/email/template',
        SEND: '/prod/tim/email/send'
    },

    // Data Loading Endpoints
    LOAD: {
        BASE: '/prod/tim/load',
        CSV: '/prod/tim/load/csv',
        DEPARTMENT: '/prod/tim/load/dept',
        LOCATION: '/prod/tim/load/loc',
        JOB: '/prod/tim/load/job',
        ORGANIZATION: '/prod/tim/load/org',
        EMPLOYEE: '/prod/tim/load/emp',
        DELETE: '/prod/tim/load/delete'
    }
};

// Log endpoint initialization
console.log('API_Constants: Endpoints initialized successfully');

/**
 * Helper function to validate endpoint URL
 * @param {string} URL - Endpoint URL to validate
 * @returns {boolean} True if URL is valid
 */
export const Is_Valid_Endpoint = (URL) => {
    try {
        // For relative URLs, just check if they start with /
        if (URL.startsWith('/')) {
            return true;
        }

        // For absolute URLs, use standard URL validation
        const url = new URL(URL);
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch {
        console.error('API_Constants: Invalid endpoint URL:', URL);
        return false;
    }
};

// Validate all endpoints
Object.entries(API_ENDPOINTS).forEach(([Category, Endpoints]) => {
    const Validate_Category = (Obj, Path = '') => {
        Object.entries(Obj).forEach(([Key, Value]) => {
            const Current_Path = Path ? `${Path}.${Key}` : Key;
            if (typeof Value === 'string') {
                if (!Is_Valid_Endpoint(Value)) {
                    console.error(`API_Constants: Invalid endpoint in ${Current_Path}:`, Value);
                }
            } else if (typeof Value === 'object') {
                Validate_Category(Value, Current_Path);
            }
        });
    };

    Validate_Category(Endpoints, Category);
});
