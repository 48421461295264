import React from 'react';
import { Chip } from "@material-tailwind/react";

const UserRoleChips = ({ roles, isEditing = false, onToggle = () => {} }) => {
  const roleConfig = [
    { key: 'Admin', value: roles.Admin },
    { key: 'Manager', value: roles.Manager },
    { key: 'Approver', value: roles.Approver },
    { key: 'Auditor', value: roles.Auditor },
    { key: 'HR', value: roles.HR },
    { key: 'Executive', value: roles.Executive },
    { key: 'Recorder', value: roles.Recorder }
  ];

  return (
    <div className="flex flex-row space-x-5">
      {roleConfig.map(({ key, value }) => (
        value ? (
          <div key={key} className="w-max">
            <Chip
              variant="ghost"
              size="sm"
              value={key}
              color={value ? "green" : "red"}
              onClick={isEditing ? () => onToggle(key.toLowerCase()) : undefined}
              className={isEditing ? "cursor-pointer" : ""}
            />
          </div>
        ) : null
      ))}
    </div>
  );
};

export default UserRoleChips;
