import React from 'react';
import { Card, Spinner } from 'flowbite-react';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useCompanyData } from '../../contexts/CompanyContext';

ChartJS.register(ArcElement, Tooltip, Legend);

const CompanyStatsWidget = () => {
    const { Company_Metrics, Is_Loading, Error } = useCompanyData();

    if (Is_Loading) {
        return (
            <Card className="w-full h-96">
                <div className="flex justify-center items-center h-full">
                    <Spinner size="xl" />
                </div>
            </Card>
        );
    }

    if (Error || !Company_Metrics || Company_Metrics.length < 2) {
        return (
            <Card className="w-full h-96">
                <div className="flex justify-center items-center h-full text-red-500">
                    {Error || 'Insufficient data available'}
                </div>
            </Card>
        );
    }

    // Get current and previous period data
    const Current_Stats = Company_Metrics[Company_Metrics.length - 1];
    const Previous_Stats = Company_Metrics[Company_Metrics.length - 2];

    // Calculate headcount change
    const Current_Total = Current_Stats.Count_Total;
    const Previous_Total = Previous_Stats.Count_Total;
    const Headcount_Change = Current_Total - Previous_Total;
    const Change_Percentage = ((Headcount_Change / Previous_Total) * 100).toFixed(1);

    // Calculate green count (total minus yellow and red)
    const Green_Count = Current_Total - Current_Stats.Count_Yellow - Current_Stats.Count_Red;

    // Prepare chart data
    const Chart_Data = {
        labels: ['Low Risk', 'Medium Risk', 'High Risk'],
        datasets: [{
            data: [
                Green_Count,
                Current_Stats.Count_Yellow,
                Current_Stats.Count_Red
            ],
            backgroundColor: [
                'rgb(34, 197, 94)', // green
                'rgb(234, 179, 8)', // yellow
                'rgb(239, 68, 68)'  // red
            ],
            borderWidth: 0,
            cutout: '75%'
        }]
    };

    const Chart_Options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    padding: 20
                }
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        const total = context.dataset.data.reduce((a, b) => a + b, 0);
                        const percentage = ((value / total) * 100).toFixed(1);
                        return `${context.label}: ${value} (${percentage}%)`;
                    }
                }
            }
        }
    };

    return (
        <Card className="w-full h-96">
            <div className="flex flex-col h-full">
                <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white mb-4">
                    Company Risk Distribution
                </h5>
                
                <div className="relative flex-grow">
                    <Doughnut data={Chart_Data} options={Chart_Options} />
                    
                    {/* Center content */}
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="text-center">
                            <div className="text-3xl font-bold">
                                {Math.abs(Headcount_Change)}
                            </div>
                            <div className={`flex items-center justify-center text-sm font-semibold ${
                                Headcount_Change > 0 ? 'text-green-500' : 
                                Headcount_Change < 0 ? 'text-red-500' : 'text-gray-500'
                            }`}>
                                {Headcount_Change > 0 ? (
                                    <FiArrowUp className="mr-1" />
                                ) : Headcount_Change < 0 ? (
                                    <FiArrowDown className="mr-1" />
                                ) : null}
                                {Change_Percentage}%
                            </div>
                        </div>
                    </div>
                </div>

                {/* Summary stats */}
                <div className="grid grid-cols-3 gap-4 mt-4">
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">Total</div>
                        <div className="text-lg font-semibold">{Current_Total}</div>
                    </div>
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">At Risk</div>
                        <div className="text-lg font-semibold">
                            {Current_Stats.Count_Yellow + Current_Stats.Count_Red}
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="text-sm font-medium text-gray-500">Critical</div>
                        <div className="text-lg font-semibold">
                            {Current_Stats.Total_Risk_Critical}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default CompanyStatsWidget;
