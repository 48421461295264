import React, { useContext, useEffect, useState } from 'react';
import { StatusContext } from '../../contexts/Status';
import { Card, Table, Spinner, Alert, Badge } from 'flowbite-react';
import { workflowApi } from '../../api';
import { FiMail, FiClock, FiUser, FiFileText } from 'react-icons/fi';
import ApprovalActionModal from '../../components/approver/ApprovalActionModal';
import { EmployeeProvider } from '../../contexts/EmployeeContext';

const Approvals = () => {
  const { Company_ID, Email_Address } = useContext(StatusContext);
  const [Approval_Groups, setApprovalGroups] = useState([]);
  const [Pending_Approvals, setPendingApprovals] = useState([]);
  const [Is_Loading, setIsLoading] = useState(true);
  const [Error_Message, setErrorMessage] = useState('');
  const [Selected_Action, setSelectedAction] = useState(null);
  const [Is_Action_Modal_Visible, setIsActionModalVisible] = useState(false);

  useEffect(() => {
    const Fetch_Approval_Data = async () => {
      setIsLoading(true);
      setErrorMessage('');

      try {
        // Get all approval groups for the user
        const Groups_Response = await workflowApi.getApprovalGroups(Company_ID);
        const Groups_Data = Groups_Response.Data?.Records || [];
        setApprovalGroups(Groups_Data);

        // Get pending approvals for each group
        const All_Pending_Approvals = [];
        for (const Group of Groups_Data) {
          const Pending_Response = await workflowApi.getPendingActions(Company_ID, Group.Approval_Group_ID);
          const Pending_Actions = Pending_Response.Data?.Records || [];
          if (Pending_Actions.length > 0) {
            All_Pending_Approvals.push(...Pending_Actions.map(Action => ({
              ...Action,
              Group_Name: Group.Approval_Group_Display_Name
            })));
          }
        }

        setPendingApprovals(All_Pending_Approvals);
      } catch (Error) {
        console.error('Error fetching approvals:', Error);
        setErrorMessage(Error.Message || 'Failed to fetch approvals. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (Company_ID && Email_Address) {
      Fetch_Approval_Data();
    }
  }, [Company_ID, Email_Address]);

  const Handle_Action_Click = (Action_Data) => {
    setSelectedAction(Action_Data);
    setIsActionModalVisible(true);
  };

  const Handle_Action_Complete = async () => {
    setIsActionModalVisible(false);
    setSelectedAction(null);
    // Refresh the approvals list
    try {
      const Groups_Response = await workflowApi.getApprovalGroups(Company_ID);
      const Groups_Data = Groups_Response.Data?.Records || [];
      const All_Pending_Approvals = [];
      for (const Group of Groups_Data) {
        const Pending_Response = await workflowApi.getPendingActions(Company_ID, Group.Approval_Group_ID);
        const Pending_Actions = Pending_Response.Data?.Records || [];
        if (Pending_Actions.length > 0) {
          All_Pending_Approvals.push(...Pending_Actions.map(Action => ({
            ...Action,
            Group_Name: Group.Approval_Group_Display_Name
          })));
        }
      }
      setPendingApprovals(All_Pending_Approvals);
    } catch (Error) {
      console.error('Error refreshing approvals:', Error);
    }
  };

  if (Is_Loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spinner size="xl" />
      </div>
    );
  }

  if (Error_Message) {
    return <Alert color="failure">{Error_Message}</Alert>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Approvals Inbox</h1>
        <div className="flex items-center space-x-2">
          <Badge color="gray" size="lg">
            Total Groups: {Approval_Groups.length}
          </Badge>
          <Badge color="blue" size="lg">
            Pending Approvals: {Pending_Approvals.length}
          </Badge>
        </div>
      </div>

      <Card>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="w-8"></Table.HeadCell>
              <Table.HeadCell>Action Name</Table.HeadCell>
              <Table.HeadCell>Employee</Table.HeadCell>
              <Table.HeadCell>Group</Table.HeadCell>
              <Table.HeadCell>Date</Table.HeadCell>
              <Table.HeadCell>Step</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {Pending_Approvals.map((Action) => (
                <Table.Row 
                  key={Action.Action_GUID}
                  className="cursor-pointer hover:bg-gray-50"
                  onClick={() => Handle_Action_Click(Action)}
                >
                  <Table.Cell>
                    <FiMail className="text-blue-500" />
                  </Table.Cell>
                  <Table.Cell className="font-medium">
                    <div className="flex items-center space-x-2">
                      <FiFileText className="text-gray-400" />
                      <span>{Action.Action_Name}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex items-center space-x-2">
                      <FiUser className="text-gray-400" />
                      <span>{Action.Employee_ID}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{Action.Group_Name}</Table.Cell>
                  <Table.Cell>
                    <div className="flex items-center space-x-2">
                      <FiClock className="text-gray-400" />
                      <span>{new Date(Action.Action_Init_Date).toLocaleDateString()}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <Badge color="warning">
                      Step {Action.Approval_Step}
                    </Badge>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Card>

      {Is_Action_Modal_Visible && Selected_Action && (
        <EmployeeProvider Company_ID={Company_ID}>
          <ApprovalActionModal
            Action={Selected_Action}
            On_Close={() => {
              setIsActionModalVisible(false);
              Handle_Action_Complete();
            }}
          />
        </EmployeeProvider>
      )}
    </div>
  );
};

Approvals.Display_Name = 'Approvals';

export default Approvals;
