// Field configuration for DB field types and formatting
export const fieldsConfig = {
    // Field types for validation and formatting
    Field_Types: {
        // Basic fields
        Period_Date: 'Date',
        Last_Update: 'Date',
        Create_Date: 'Date',
        Company_ID: 'String',
        Work_Location_ID: 'String',
        Location_Name: 'String',
        Department_ID: 'String',
        Department_Name: 'String',
        Job_ID: 'String',
        Job_Title: 'String',

        // Risk metrics
        Total_Risk: 'Currency',
        Total_Risk_Critical: 'Currency',

        // Count metrics
        Count_Total: 'Number',
        Count_Male: 'Number',
        Count_Female: 'Number',
        Count_Red: 'Number',
        Count_Yellow: 'Number',

        // Critical metrics
        Count_Total_Critical: 'Number',
        Count_Male_Critical: 'Number',
        Count_Female_Critical: 'Number',
        Count_Red_Critical: 'Number',
        Count_Yellow_Critical: 'Number',

        // Impact metrics
        Impact_Modifier: 'Number',

        // Address fields
        Address_1: 'String',
        Address_2: 'String',
        City: 'String',
        State: 'String',
        Post_Code: 'String',
        Country: 'String'
    },

    // Default values by type
    Default_Values: {
        String: '',
        Number: 0,
        Currency: '$0',
        Date: null
    }
};
