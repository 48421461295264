import React, { useState, useEffect } from "react";
import { Modal, Button, Label, TextInput, Select } from "flowbite-react";
import { usersApi } from "../../api";

const UserModal = ({ show, onClose, user, onSave, clients }) => {
  const [userData, setUserData] = useState({
    Email_Address: "",
    First_Name: "",
    Last_Name: "",
    Role: "",
    Client_ID: "",
    Company_ID: "",
  });
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      setUserData(user);
      if (user.Client_ID) {
        fetchCompanies(user.Client_ID);
      }
    } else {
      setUserData({
        Email_Address: "",
        First_Name: "",
        Last_Name: "",
        Role: "",
        Client_ID: "",
        Company_ID: "",
      });
      setCompanies([]);
    }
  }, [user]);

  const fetchCompanies = async (clientId) => {
    try {
      setLoading(true);
      setError(null);
      const response = await usersApi.getClientCompanies(clientId);
      const companyList = response?.data?.Records || [];
      setCompanies(companyList);
    } catch (err) {
      setError("Failed to fetch companies");
      console.error("Error fetching companies:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    
    if (name === "Client_ID") {
      // Reset company when client changes
      setUserData(prev => ({
        ...prev,
        [name]: value,
        Company_ID: ""  // Reset company selection
      }));
      
      if (value) {
        await fetchCompanies(value);
      } else {
        setCompanies([]);
      }
    } else {
      setUserData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = () => {
    onSave(userData);
  };

  const roles = [
    { value: "Client Admin", label: "Client Admin" },
    { value: "Manager", label: "Manager" },
    { value: "Approver", label: "Approver" },
    { value: "Auditor", label: "Auditor" },
    { value: "HR", label: "HR" },
    { value: "Executive", label: "Executive" },
    { value: "Recorder", label: "Recorder" }
  ];

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>{user ? "Edit User" : "Add User"}</Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          <div>
            <Label htmlFor="email">Email</Label>
            <TextInput
              id="email"
              name="Email_Address"
              type="email"
              value={userData.Email_Address}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="firstName">First Name</Label>
            <TextInput
              id="firstName"
              name="First_Name"
              type="text"
              value={userData.First_Name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="lastName">Last Name</Label>
            <TextInput
              id="lastName"
              name="Last_Name"
              type="text"
              value={userData.Last_Name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="role">Role</Label>
            <Select
              id="role"
              name="Role"
              value={userData.Role}
              onChange={handleChange}
              required
            >
              <option value="">Select a role</option>
              {roles.map(role => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Label htmlFor="clientId">Client</Label>
            <Select
              id="clientId"
              name="Client_ID"
              value={userData.Client_ID}
              onChange={handleChange}
              required
            >
              <option value="">Select a client</option>
              {clients.map((client) => (
                <option key={client.Client_ID} value={client.Client_ID}>
                  {client.Client_Name}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Label htmlFor="companyId">Company</Label>
            <Select
              id="companyId"
              name="Company_ID"
              value={userData.Company_ID}
              onChange={handleChange}
              disabled={!userData.Client_ID || loading}
              required
            >
              <option value="">Select a company</option>
              {companies.map((company) => (
                <option key={company.Company_ID} value={company.Company_ID}>
                  {company.Company_Name}
                </option>
              ))}
            </Select>
            {loading && <div className="text-sm text-gray-500">Loading companies...</div>}
            {error && <div className="text-sm text-red-500">{error}</div>}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          color="blue" 
          onClick={handleSubmit}
          disabled={!userData.Email_Address || !userData.Role || !userData.Client_ID || !userData.Company_ID}
        >
          {user ? "Update" : "Add"}
        </Button>
        <Button color="gray" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(UserModal);
