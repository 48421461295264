import React, { useState, useEffect, useContext } from 'react';
import { StatusContext } from '../../contexts/Status';
import { workflowApi } from '../../api';
import { Button, Card, Modal, Select, Alert, Spinner, Table, TextInput, Textarea } from 'flowbite-react';
import { FiPlus, FiEdit2, FiTrash2, FiSettings } from 'react-icons/fi';

const PMS_CATEGORIES = {
    REWARD: { label: 'Reward', color: 'bg-green-100 text-green-800' },
    REPLACE: { label: 'Replace', color: 'bg-red-100 text-red-800' },
    REMEDIATE: { label: 'Remediate', color: 'bg-orange-100 text-orange-800' }
};

const ActionConfig = () => {
    const { Company_ID } = useContext(StatusContext);
    const [Action_Configs, setActionConfigs] = useState([]);
    const [Selected_Config, setSelectedConfig] = useState(null);
    const [Is_Modal_Open, setIsModalOpen] = useState(false);
    const [Is_Loading, setIsLoading] = useState(true);
    const [Is_Updating, setIsUpdating] = useState(false);
    const [Error_Message, setErrorMessage] = useState('');
    const [Form_Data, setFormData] = useState({
        Action_Type: '',
        Action_Name: '',
        Action_Description: '',
        PMS_Category: '',
        Required_Fields: {},
        Optional_Fields: {},
        PMS_Metrics_Required: {},
        PMS_Default_Milestones: []
    });

    useEffect(() => {
        const Fetch_Data = async () => {
            try {
                setIsLoading(true);
                setErrorMessage('');
                const Response = await workflowApi.getActionConfigs(Company_ID);

                if (!Response.Success) {
                    throw new Error('Failed to fetch action configurations');
                }

                setActionConfigs(Response.Data?.Records || []);
            } catch (Error) {
                console.error('Error fetching action configs:', Error);
                setErrorMessage('Failed to load action configurations');
            } finally {
                setIsLoading(false);
            }
        };

        Fetch_Data();
    }, [Company_ID]);

    const Handle_Modal_Open = (config = null) => {
        if (config) {
            setFormData({
                Action_Type: config.Action_Type,
                Action_Name: config.Action_Name,
                Action_Description: config.Action_Description,
                PMS_Category: config.PMS_Category,
                Required_Fields: config.Required_Fields || {},
                Optional_Fields: config.Optional_Fields || {},
                PMS_Metrics_Required: config.PMS_Metrics_Required || {},
                PMS_Default_Milestones: config.PMS_Default_Milestones || []
            });
            setSelectedConfig(config);
        } else {
            setFormData({
                Action_Type: '',
                Action_Name: '',
                Action_Description: '',
                PMS_Category: '',
                Required_Fields: {},
                Optional_Fields: {},
                PMS_Metrics_Required: {},
                PMS_Default_Milestones: []
            });
            setSelectedConfig(null);
        }
        setIsModalOpen(true);
    };

    const Handle_Submit = async () => {
        try {
            setIsUpdating(true);
            setErrorMessage('');

            const Config_Data = {
                Company_ID,
                ...Form_Data
            };

            if (Selected_Config) {
                Config_Data.Config_ID = Selected_Config.Config_ID;
                await workflowApi.updateActionConfig(Config_Data);
            } else {
                await workflowApi.createActionConfig(Config_Data);
            }

            const Updated_Configs = await workflowApi.getActionConfigs(Company_ID);
            setActionConfigs(Updated_Configs.Data?.Records || []);
            setIsModalOpen(false);
        } catch (Error) {
            console.error('Error saving action config:', Error);
            setErrorMessage('Failed to save action configuration');
        } finally {
            setIsUpdating(false);
        }
    };

    const Handle_Delete = async (Config_ID) => {
        if (!window.confirm('Are you sure you want to delete this configuration?')) return;

        try {
            setIsUpdating(true);
            setErrorMessage('');
            await workflowApi.deleteActionConfig(Company_ID, Config_ID);
            const Updated_Configs = await workflowApi.getActionConfigs(Company_ID);
            setActionConfigs(Updated_Configs.Data?.Records || []);
        } catch (Error) {
            console.error('Error deleting action config:', Error);
            setErrorMessage('Failed to delete action configuration');
        } finally {
            setIsUpdating(false);
        }
    };

    const Get_Configs_By_Category = (Category) => {
        return Action_Configs.filter(config => config.PMS_Category === Category);
    };

    if (Is_Loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spinner size="xl" />
            </div>
        );
    }

    return (
        <div className="p-6">
            {Error_Message && (
                <Alert color="failure" className="mb-4">
                    <span className="font-medium">Error!</span> {Error_Message}
                </Alert>
            )}

            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Action Configurations</h2>
                <Button
                    color="blue"
                    onClick={() => Handle_Modal_Open()}
                    className="flex items-center gap-2"
                >
                    <FiPlus className="h-5 w-5" />
                    New Configuration
                </Button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {Object.entries(PMS_CATEGORIES).map(([category, { label, color }]) => (
                    <Card key={category}>
                        <div className="flex justify-between items-center mb-4">
                            <h3 className={`font-semibold px-3 py-1 rounded ${color}`}>
                                {label}
                            </h3>
                            <span className="text-sm text-gray-500">
                                {Get_Configs_By_Category(category).length} Actions
                            </span>
                        </div>
                        <div className="overflow-x-auto">
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>Action Type</Table.HeadCell>
                                    <Table.HeadCell>Name</Table.HeadCell>
                                    <Table.HeadCell>Actions</Table.HeadCell>
                                </Table.Head>
                                <Table.Body>
                                    {Get_Configs_By_Category(category).map(config => (
                                        <Table.Row key={config.Config_ID}>
                                            <Table.Cell className="whitespace-nowrap font-medium">
                                                {config.Action_Type}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {config.Action_Name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="flex items-center gap-2">
                                                    <Button
                                                        size="xs"
                                                        color="light"
                                                        onClick={() => Handle_Modal_Open(config)}
                                                        title="Edit configuration"
                                                    >
                                                        <FiEdit2 className="h-4 w-4" />
                                                    </Button>
                                                    <Button
                                                        size="xs"
                                                        color="failure"
                                                        onClick={() => Handle_Delete(config.Config_ID)}
                                                        title="Delete configuration"
                                                    >
                                                        <FiTrash2 className="h-4 w-4" />
                                                    </Button>
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                    </Card>
                ))}
            </div>

            <Modal
                show={Is_Modal_Open}
                onClose={() => setIsModalOpen(false)}
                size="xl"
            >
                <Modal.Header>
                    {Selected_Config ? 'Edit Action Configuration' : 'New Action Configuration'}
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">
                                    Action Type
                                </label>
                                <TextInput
                                    type="text"
                                    value={Form_Data.Action_Type}
                                    onChange={(e) => setFormData({ ...Form_Data, Action_Type: e.target.value })}
                                    placeholder="Enter action type"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">
                                    Action Name
                                </label>
                                <TextInput
                                    type="text"
                                    value={Form_Data.Action_Name}
                                    onChange={(e) => setFormData({ ...Form_Data, Action_Name: e.target.value })}
                                    placeholder="Enter action name"
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Description
                            </label>
                            <Textarea
                                value={Form_Data.Action_Description}
                                onChange={(e) => setFormData({ ...Form_Data, Action_Description: e.target.value })}
                                placeholder="Enter action description"
                                rows={3}
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                PMS Category
                            </label>
                            <Select
                                value={Form_Data.PMS_Category}
                                onChange={(e) => setFormData({ ...Form_Data, PMS_Category: e.target.value })}
                                required
                            >
                                <option value="">Select Category</option>
                                {Object.entries(PMS_CATEGORIES).map(([value, { label }]) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Select>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Required Fields (JSON)
                            </label>
                            <Textarea
                                value={JSON.stringify(Form_Data.Required_Fields, null, 2)}
                                onChange={(e) => {
                                    try {
                                        const parsed = JSON.parse(e.target.value);
                                        setFormData({ ...Form_Data, Required_Fields: parsed });
                                    } catch (err) {
                                        // Allow invalid JSON while typing
                                        setFormData({ ...Form_Data, Required_Fields: e.target.value });
                                    }
                                }}
                                placeholder="Enter required fields as JSON"
                                rows={3}
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Optional Fields (JSON)
                            </label>
                            <Textarea
                                value={JSON.stringify(Form_Data.Optional_Fields, null, 2)}
                                onChange={(e) => {
                                    try {
                                        const parsed = JSON.parse(e.target.value);
                                        setFormData({ ...Form_Data, Optional_Fields: parsed });
                                    } catch (err) {
                                        setFormData({ ...Form_Data, Optional_Fields: e.target.value });
                                    }
                                }}
                                placeholder="Enter optional fields as JSON"
                                rows={3}
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                PMS Metrics Required (JSON)
                            </label>
                            <Textarea
                                value={JSON.stringify(Form_Data.PMS_Metrics_Required, null, 2)}
                                onChange={(e) => {
                                    try {
                                        const parsed = JSON.parse(e.target.value);
                                        setFormData({ ...Form_Data, PMS_Metrics_Required: parsed });
                                    } catch (err) {
                                        setFormData({ ...Form_Data, PMS_Metrics_Required: e.target.value });
                                    }
                                }}
                                placeholder="Enter PMS metrics as JSON"
                                rows={3}
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Default Milestones (JSON Array)
                            </label>
                            <Textarea
                                value={JSON.stringify(Form_Data.PMS_Default_Milestones, null, 2)}
                                onChange={(e) => {
                                    try {
                                        const parsed = JSON.parse(e.target.value);
                                        setFormData({ ...Form_Data, PMS_Default_Milestones: parsed });
                                    } catch (err) {
                                        setFormData({ ...Form_Data, PMS_Default_Milestones: e.target.value });
                                    }
                                }}
                                placeholder="Enter default milestones as JSON array"
                                rows={3}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex justify-end gap-2">
                        <Button color="gray" onClick={() => setIsModalOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            color="blue"
                            onClick={Handle_Submit}
                            disabled={Is_Updating || !Form_Data.Action_Type || !Form_Data.Action_Name || !Form_Data.PMS_Category}
                        >
                            {Selected_Config ? 'Update' : 'Create'}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

ActionConfig.Display_Name = 'ActionConfig';

export default ActionConfig;
