import React, { useState, useContext, useEffect } from 'react';
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from 'react';
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { v4 as uuidv4 } from "uuid";
import { StatusContext } from "../../contexts/Status";
import { usersApi, organizationApi } from '../../api';

const UserAddModal = ({ onClose }) => {
  const { Client_ID } = useContext(StatusContext);
  const [formData, setFormData] = useState({
    email: "",
    clientID: "",
    client: "Client Name",
    companyID: "",
    company: "",
    admin: false,
    superAdmin: false,
    manager: false,
    approver: false,
    auditor: false,
    HR: false,
    executive: false,
    recorder: false,
    locationID: null,
    departmentID: null
  });

  const [companies, setCompanies] = useState(null);
  const [locations, setLocations] = useState(null);
  const [departments, setDepartments] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      const clients = await usersApi.getClients(Client_ID);
      const filteredClients = clients.filter(x => x.Client_ID === Client_ID);
      
      if (filteredClients.length > 0) {
        setFormData(prev => ({
          ...prev,
          clientID: filteredClients[0].Client_ID,
          client: filteredClients[0].Client_Name
        }));

        const companyData = await usersApi.getClientCompanies(filteredClients[0].Client_ID);
        setCompanies(companyData);
      }
    };
    fetchInitialData();
  }, [Client_ID]);

  const handleCompanySelect = async (company) => {
    setFormData(prev => ({
      ...prev,
      companyID: company.Company_ID,
      company: company.Company_Name
    }));

    const [locData, deptData] = await Promise.all([
      organizationApi.getLocations(company.Company_ID),
      organizationApi.getDepartment(company.Company_ID)
    ]);

    setLocations(locData);
    setDepartments(deptData);
  };

  const handleSubmit = async () => {
    try {
      const existingUser = await usersApi.getUsers(`Email_Address=${formData.email}`);
      
      if (existingUser.length === 0) {
        await usersApi.createUser({
          Email_Address: formData.email,
          Client_ID: formData.clientID,
          User_Status: "A",
          User_Role: "admin",
          Super_Admin: formData.superAdmin,
        });
      }

      await usersApi.createUserEntitlement({
        ID: uuidv4(),
        Email_Address: formData.email,
        Client_ID: formData.clientID,
        Company_ID: formData.companyID,
        Department_ID: formData.departmentID,
        Location_ID: formData.locationID,
        Admin: formData.admin,
        Approver: formData.approver,
        Executive: formData.executive,
        Recorder: formData.recorder,
        Manager: formData.manager,
        Auditor: formData.auditor,
        HR: formData.HR,
      });

      onClose();
      window.location.reload();
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const toggleRole = (role) => {
    setFormData(prev => ({
      ...prev,
      [role]: !prev[role]
    }));
  };

  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative my-6 mx-auto w-2/5">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-3xl font-semibold">Add New User</h3>
          </div>
          
          <div className="relative p-6 flex-auto">
            <form className="space-y-4">
              <div className="flex items-center">
                <label className="w-1/3 text-right mr-4">Email Address</label>
                <input
                  type="email"
                  className="w-2/3 p-2 border rounded"
                  value={formData.email}
                  onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                />
              </div>

              {companies && (
                <div className="flex items-center">
                  <label className="w-1/3 text-right mr-4">Company</label>
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      {formData.company || "Select Company"}
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {companies.map(company => (
                          <Menu.Item key={company.Company_ID}>
                            <button
                              className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                              onClick={() => handleCompanySelect(company)}
                            >
                              {company.Company_Name}
                            </button>
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}

              <div className="space-y-2">
                <h4 className="font-medium">User Roles</h4>
                {[
                  { key: 'admin', label: 'Admin' },
                  { key: 'manager', label: 'Manager' },
                  { key: 'approver', label: 'Approver' },
                  { key: 'auditor', label: 'Auditor' },
                  { key: 'HR', label: 'HR' },
                  { key: 'executive', label: 'Executive' },
                  { key: 'recorder', label: 'Recorder' }
                ].map(role => (
                  <label key={role.key} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={formData[role.key]}
                      onChange={() => toggleRole(role.key)}
                      className="form-checkbox"
                    />
                    <span>{role.label}</span>
                  </label>
                ))}
              </div>
            </form>
          </div>

          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={handleSubmit}
            >
              Add User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAddModal;
