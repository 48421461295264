import { useState, useEffect, useContext, useCallback } from 'react';
import { StatusContext } from '../contexts/Status';
import { workflowApi } from '../api';

export const useWorkflowManagement = () => {
  const [Workflows_Data, setWorkflowsData] = useState([]);
  const [Action_Analytics, setActionAnalytics] = useState({
    Total_Action_Types: 0,
    PMS_Enabled_Types: 0,
    Active_Workflows: 0,
    Completed_Workflows: 0
  });
  const [Query_Context, setQueryContext] = useState({
    Company_ID: '',
    Total_Records: 0
  });
  const [Is_Loading, setIsLoading] = useState(true);
  const [Error_Message, setErrorMessage] = useState(null);
  const { Company_ID } = useContext(StatusContext);

  const fetchWorkflows = useCallback(async () => {
    if (!Company_ID) return;

    try {
      setIsLoading(true);
      const [Workflows_Response, Actions_Response] = await Promise.all([
        workflowApi.getWorkflows(Company_ID),
        workflowApi.getActions(Company_ID)
      ]);

      console.log('Workflows Response:', Workflows_Response);
      console.log('Actions Response:', Actions_Response);

      // Handle normalized response format for workflows
      const Workflows_Records = Array.isArray(Workflows_Response) ? 
        Workflows_Response : 
        Workflows_Response.Data?.Records || [];

      // Handle new actions response format
      const Actions_Records = Actions_Response?.Data?.Records || [];
      const Analytics_Data = Actions_Response?.Data?.Analytics || {
        Total_Action_Types: 0,
        PMS_Enabled_Types: 0,
        Active_Workflows: 0,
        Completed_Workflows: 0
      };
      const Context_Data = Actions_Response?.Data?.Query_Context || {
        Company_ID: Company_ID,
        Total_Records: 0
      };

      console.log('Processed Workflows:', Workflows_Records);
      console.log('Processed Actions:', Actions_Records);
      console.log('Actions Analytics:', Analytics_Data);
      console.log('Query Context:', Context_Data);

      // Combine workflow data with action details
      const Enriched_Workflows = Workflows_Records.map(Workflow => ({
        ...Workflow,
        Action: Actions_Records.find(Action => Action.Action_ID === Workflow.Action_Group_ID)
      }));

      console.log('Enriched Workflows:', Enriched_Workflows);
      setWorkflowsData(Enriched_Workflows);
      setActionAnalytics(Analytics_Data);
      setQueryContext(Context_Data);
    } catch (Error) {
      console.error('Error fetching workflows:', Error);
      setErrorMessage(Error.Message);
    } finally {
      setIsLoading(false);
    }
  }, [Company_ID]);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const createWorkflow = async (Workflow_Data) => {
    try {
      const Response = await workflowApi.createWorkflow(Company_ID, Workflow_Data);
      console.log('Create Workflow Response:', Response);
      await fetchWorkflows();
      return true;
    } catch (Error) {
      console.error('Error creating workflow:', Error);
      setErrorMessage(Error.Message);
      return false;
    }
  };

  const updateWorkflow = async (Action_ID, Workflow_Data) => {
    try {
      const Response = await workflowApi.updateWorkflow(Company_ID, Action_ID, Workflow_Data);
      console.log('Update Workflow Response:', Response);
      await fetchWorkflows();
      return true;
    } catch (Error) {
      console.error('Error updating workflow:', Error);
      setErrorMessage(Error.Message);
      return false;
    }
  };

  const deleteWorkflow = async (Action_ID) => {
    try {
      const Response = await workflowApi.deleteWorkflow(Company_ID, Action_ID);
      console.log('Delete Workflow Response:', Response);
      await fetchWorkflows();
      return true;
    } catch (Error) {
      console.error('Error deleting workflow:', Error);
      setErrorMessage(Error.Message);
      return false;
    }
  };

  return {
    Workflows: Workflows_Data,
    Action_Analytics,
    Query_Context,
    Is_Loading,
    Error: Error_Message,
    Create_Workflow: createWorkflow,
    Update_Workflow: updateWorkflow,
    Delete_Workflow: deleteWorkflow,
    Refresh_Workflows: fetchWorkflows
  };
};
