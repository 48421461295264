import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Account } from './contexts/Account';
import { Status } from './contexts/Status';
import { ClientCompanyProvider } from './contexts/ClientCompanyContext';
import AuthenticatedApp from './AuthenticatedApp';
import SignIn from './components/auth/SignIn';
import Reset from './components/auth/Reset';
import { ThemeProvider } from '@material-tailwind/react';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Account>
          <Status>
            <ClientCompanyProvider>
              <div className="min-h-screen bg-gray-50">
                <Routes>
                  <Route path="/" element={
                    <div className="min-h-screen flex flex-col items-center justify-center px-4">
                      <div className="max-w-4xl w-full text-center mb-8">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">Welcome to HappyHippo</h1>
                        <p className="text-xl text-gray-600">Transform your talent retention and DEI initiatives into strategic competitive advantages</p>
                      </div>
                      <SignIn />
                    </div>
                  } />
                  <Route path="/reset" element={<Reset />} />
                  <Route path="/*" element={<AuthenticatedApp />} />
                </Routes>
              </div>
            </ClientCompanyProvider>
          </Status>
        </Account>
      </Router>
    </ThemeProvider>
  );
}

export default App;
