import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const D3FunnelChart = ({
  Data_List,
  Chart_Width = 600,
  Chart_Height = 400,
  Margin = { Top: 40, Right: 120, Bottom: 40, Left: 120 },
  Color_Scale = d3.scaleOrdinal(d3.schemeBlues[9]),
  Show_Percentages = true,
  Show_Values = true,
  Show_Labels = true,
  Transition_Duration = 750
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    // Create SVG
    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Funnel Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Calculate percentages and metrics
    const Max_Value = d3.max(Data_List, d => d.Value);
    const Total_Height = Inner_Height;
    const Section_Height = Total_Height / Data_List.length;
    
    // Calculate cumulative percentages
    let Previous_Value = Data_List[0].Value;
    const Enhanced_Data = Data_List.map((item, index) => {
      const Percentage = index === 0 ? 100 : (item.Value / Previous_Value) * 100;
      Previous_Value = item.Value;
      return {
        ...item,
        Percentage,
        Cumulative_Percentage: (item.Value / Data_List[0].Value) * 100
      };
    });

    // Create scales
    const Y_Scale = d3.scaleLinear()
      .domain([0, Data_List.length - 1])
      .range([0, Total_Height]);

    const Width_Scale = d3.scaleLinear()
      .domain([0, Max_Value])
      .range([0, Inner_Width]);

    // Create funnel sections
    Enhanced_Data.forEach((item, index) => {
      const Next_Item = Enhanced_Data[index + 1];
      const Current_Width = Width_Scale(item.Value);
      const Next_Width = Next_Item ? Width_Scale(Next_Item.Value) : Width_Scale(item.Value * 0.1);
      
      // Create trapezoid path
      const Path_Data = [
        `M${-Current_Width/2},${Y_Scale(index)}`,
        `L${Current_Width/2},${Y_Scale(index)}`,
        `L${Next_Width/2},${Y_Scale(index + 1)}`,
        `L${-Next_Width/2},${Y_Scale(index + 1)}`,
        'Z'
      ].join(' ');

      // Add section
      const Section = SVG.append("path")
        .attr("d", Path_Data)
        .attr("fill", Color_Scale(index))
        .attr("stroke", "white")
        .attr("stroke-width", 2)
        .style("opacity", 0)
        .transition()
        .duration(Transition_Duration)
        .style("opacity", 1);

      // Add labels
      if (Show_Labels) {
        SVG.append("text")
          .attr("class", "label")
          .attr("x", -Current_Width/2 - 10)
          .attr("y", Y_Scale(index) + Section_Height/2)
          .attr("text-anchor", "end")
          .attr("dominant-baseline", "middle")
          .style("font-size", "14px")
          .text(item.Label);
      }

      // Add values
      if (Show_Values) {
        SVG.append("text")
          .attr("class", "value")
          .attr("x", Current_Width/2 + 10)
          .attr("y", Y_Scale(index) + Section_Height/2)
          .attr("dominant-baseline", "middle")
          .style("font-size", "14px")
          .text(item.Value.toLocaleString());
      }

      // Add percentages
      if (Show_Percentages) {
        SVG.append("text")
          .attr("class", "percentage")
          .attr("x", 0)
          .attr("y", Y_Scale(index) + Section_Height/2)
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "middle")
          .style("font-size", "14px")
          .style("fill", "white")
          .style("font-weight", "bold")
          .text(`${item.Cumulative_Percentage.toFixed(1)}%`);
      }
    });

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    SVG.selectAll("path")
      .on("mouseover", (Event, d) => {
        const Index = d3.select(Event.target).datum();
        const Data = Enhanced_Data[Index];
        
        Tooltip.transition()
          .duration(200)
          .style("opacity", 0.9);
        
        Tooltip.html(`
          <strong>${Data.Label}</strong><br/>
          Value: ${Data.Value.toLocaleString()}<br/>
          Stage Conversion: ${Data.Percentage.toFixed(1)}%<br/>
          Overall Conversion: ${Data.Cumulative_Percentage.toFixed(1)}%
        `)
          .style("left", `${Event.pageX + 10}px`)
          .style("top", `${Event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        Tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }, [Data_List, Chart_Width, Chart_Height, Margin, Color_Scale, Show_Percentages, Show_Values, Show_Labels, Transition_Duration]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

D3FunnelChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Margin: PropTypes.shape({
    Top: PropTypes.number,
    Right: PropTypes.number,
    Bottom: PropTypes.number,
    Left: PropTypes.number
  }),
  Color_Scale: PropTypes.func,
  Show_Percentages: PropTypes.bool,
  Show_Values: PropTypes.bool,
  Show_Labels: PropTypes.bool,
  Transition_Duration: PropTypes.number
};

export default D3FunnelChart;
