import React, { createContext, useState, useEffect, useCallback } from 'react';
import { cookieManager } from '../utils/cookieManager';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const [state, setState] = useState({
        dashboards: [],
        activeDashboard: null,
        savedCharts: [],
        chartConfigs: [],
        filterContext: {
            Applied_Filters: {},
            Available_Filters: {}
        },
        sortContext: {
            Current_Sort: {
                field: 'Create_Date',
                direction: 'DESC'
            },
            Available_Sort_Fields: []
        },
        pagination: {
            Total_Records: 0,
            Page_Size: 10,
            Current_Page: 1,
            Total_Pages: 0,
            Has_Next: false,
            Has_Previous: false
        },
        queryContext: {
            Request_ID: '',
            Timestamp: ''
        },
        isLoading: true,
        error: null
    });

    // Load saved data and context
    useEffect(() => {
        try {
            const dashboards = cookieManager.get('dashboards') || [];
            const chartConfigs = cookieManager.get('chartConfigs') || [];
            const savedCharts = cookieManager.get('savedCharts') || [];
            const filterContext = cookieManager.get('filterContext') || {
                Applied_Filters: {},
                Available_Filters: {}
            };
            const sortContext = cookieManager.get('sortContext') || {
                Current_Sort: {
                    field: 'Create_Date',
                    direction: 'DESC'
                },
                Available_Sort_Fields: []
            };

            setState(prev => ({
                ...prev,
                dashboards,
                chartConfigs,
                savedCharts,
                filterContext,
                sortContext,
                activeDashboard: dashboards[0] || null,
                queryContext: {
                    Request_ID: `dash_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                },
                isLoading: false
            }));
        } catch (error) {
            setState(prev => ({
                ...prev,
                error: {
                    message: 'Failed to load dashboard data',
                    errors: [error.message]
                },
                isLoading: false
            }));
        }
    }, []);

    // Update filters
    const updateFilters = useCallback(async (filters) => {
        try {
            const newFilterContext = {
                ...state.filterContext,
                Applied_Filters: {
                    ...state.filterContext.Applied_Filters,
                    ...filters
                }
            };

            setState(prev => ({
                ...prev,
                filterContext: newFilterContext,
                queryContext: {
                    Request_ID: `filter_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                }
            }));

            await cookieManager.set('filterContext', newFilterContext);
            return true;
        } catch (error) {
            setState(prev => ({
                ...prev,
                error: {
                    message: 'Failed to update filters',
                    errors: [error.message]
                }
            }));
            return false;
        }
    }, [state.filterContext]);

    // Update sorting
    const updateSort = useCallback(async (field, direction) => {
        try {
            const newSortContext = {
                ...state.sortContext,
                Current_Sort: { field, direction }
            };

            setState(prev => ({
                ...prev,
                sortContext: newSortContext,
                queryContext: {
                    Request_ID: `sort_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                }
            }));

            await cookieManager.set('sortContext', newSortContext);
            return true;
        } catch (error) {
            setState(prev => ({
                ...prev,
                error: {
                    message: 'Failed to update sorting',
                    errors: [error.message]
                }
            }));
            return false;
        }
    }, [state.sortContext]);

    // Update pagination
    const updatePagination = useCallback((pageData) => {
        setState(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                ...pageData
            },
            queryContext: {
                Request_ID: `page_${Date.now()}`,
                Timestamp: new Date().toISOString()
            }
        }));
    }, []);

    // Save dashboard with context
    const saveDashboard = useCallback(async (dashboard) => {
        try {
            const dashboardWithContext = {
                ...dashboard,
                Organization_Context: {
                    Org_ID: dashboard.Org_ID,
                    Parent_Org_ID: dashboard.Parent_Org_ID,
                    Path_Array: dashboard.Path_Array || []
                },
                Department_Context: {
                    Department_ID: dashboard.Department_ID,
                    Department_Name: dashboard.Department_Name
                },
                Location_Context: {
                    Location_ID: dashboard.Location_ID,
                    Location_Name: dashboard.Location_Name
                },
                Audit_Context: {
                    Created: {
                        Timestamp: new Date().toISOString(),
                        Email: dashboard.Created_By
                    },
                    Last_Modified: {
                        Timestamp: new Date().toISOString(),
                        Email: dashboard.Modified_By
                    },
                    Version: dashboard.Version || 1
                }
            };

            const updatedDashboards = state.dashboards.map(d =>
                d.id === dashboard.id ? dashboardWithContext : d
            );

            setState(prev => ({
                ...prev,
                dashboards: updatedDashboards,
                activeDashboard: dashboardWithContext,
                queryContext: {
                    Request_ID: `save_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                }
            }));

            await cookieManager.set('dashboards', updatedDashboards);
            return true;
        } catch (error) {
            setState(prev => ({
                ...prev,
                error: {
                    message: 'Failed to save dashboard',
                    errors: [error.message]
                }
            }));
            return false;
        }
    }, [state.dashboards]);

    const contextValue = {
        ...state,
        updateFilters,
        updateSort,
        updatePagination,
        saveDashboard,
        setActiveDashboard: (dashboard) => setState(prev => ({
            ...prev,
            activeDashboard: dashboard,
            queryContext: {
                Request_ID: `activate_${Date.now()}`,
                Timestamp: new Date().toISOString()
            }
        }))
    };

    return (
        <DashboardContext.Provider value={contextValue}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboard = () => {
    const context = React.useContext(DashboardContext);
    if (!context) {
        throw new Error('useDashboard must be used within a DashboardProvider');
    }
    return context;
};
