import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from 'flowbite-react';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const D3SparkChart = ({
  Title,
  Data,
  Chart_Height = 200,
  Chart_Width = 300,
  Custom_Colors,
  Additional_Data
}) => {
  const SVG_Ref = useRef(null);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatValue = (value, isCurrency = false) => {
    if (isNaN(value) || value === null || value === undefined) {
      return 'N/A';
    }
    if (isCurrency) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    }
    return new Intl.NumberFormat('en-US').format(value);
  };

  const isCurrencySeries = (seriesName) => {
    if (typeof seriesName !== 'string') return false;
    return seriesName.toLowerCase().includes('risk') || seriesName.toLowerCase().includes('value');
  };

  const getSeriesColor = (seriesName) => {
    if (typeof seriesName !== 'string') return '#3b82f6'; // default blue
    const lowerName = seriesName.toLowerCase();
    if (lowerName.includes('red') || lowerName.includes('high')) return '#ef4444';
    if (lowerName.includes('yellow') || lowerName.includes('medium')) return '#f59e0b';
    if (lowerName.includes('green') || lowerName.includes('low')) return '#22c55e';
    if (lowerName.includes('critical')) return '#7f1d1d'; // dark red for critical
    return '#3b82f6'; // default blue
  };

  useEffect(() => {
    if (!Data || !Data.series || !Data.labels) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 20, Right: 40, Bottom: 20, Left: 40 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    // Create SVG
    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Create scales
    const X_Scale = d3.scalePoint()
      .domain(Data.labels)
      .range([0, Inner_Width]);

    const Y_Scale = d3.scaleLinear()
      .domain([
        d3.min(Data.series, s => d3.min(s.data)) * 0.9,
        d3.max(Data.series, s => d3.max(s.data)) * 1.1
      ])
      .range([Inner_Height, 0]);

    // Create line generator
    const Line_Generator = d3.line()
      .x((d, i) => X_Scale(Data.labels[i]))
      .y(d => Y_Scale(d))
      .curve(d3.curveMonotoneX);

    // Add lines and points for each series
    Data.series.forEach((series, seriesIndex) => {
      const Series_Color = Custom_Colors?.[seriesIndex] || getSeriesColor(series.name);

      // Add line
      SVG.append("path")
        .datum(series.data)
        .attr("fill", "none")
        .attr("stroke", Series_Color)
        .attr("stroke-width", 2)
        .attr("d", Line_Generator);

      // Add points
      SVG.selectAll(`dot-${seriesIndex}`)
        .data(series.data)
        .enter()
        .append("circle")
        .attr("cx", (d, i) => X_Scale(Data.labels[i]))
        .attr("cy", d => Y_Scale(d))
        .attr("r", 3)
        .attr("fill", Series_Color);
    });

    // Add tooltip
    const Tooltip_Div = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    // Add invisible overlay for tooltip interaction
    const Overlay_Width = Inner_Width / Data.labels.length;
    Data.labels.forEach((label, index) => {
      SVG.append("rect")
        .attr("x", X_Scale(label) - Overlay_Width / 2)
        .attr("y", 0)
        .attr("width", Overlay_Width)
        .attr("height", Inner_Height)
        .attr("fill", "transparent")
        .on("mouseover", (Event) => {
          Tooltip_Div.transition()
            .duration(200)
            .style("opacity", 0.9);

          let tooltipContent = `<div><strong>${formatDate(label)}</strong><br/>`;
          Data.series.forEach((series, seriesIndex) => {
            const Series_Color = Custom_Colors?.[seriesIndex] || getSeriesColor(series.name);
            tooltipContent += `<span style="color:${Series_Color}">
              ${series.name}: ${formatValue(series.data[index], isCurrencySeries(series.name))}
            </span><br/>`;
          });

          if (Additional_Data && Additional_Data[index]) {
            Object.entries(Additional_Data[index]).forEach(([key, value]) => {
              tooltipContent += `<span>${key}: ${formatValue(value)}</span><br/>`;
            });
          }

          tooltipContent += '</div>';

          Tooltip_Div.html(tooltipContent)
            .style("left", `${Event.pageX + 10}px`)
            .style("top", `${Event.pageY - 28}px`);
        })
        .on("mouseout", () => {
          Tooltip_Div.transition()
            .duration(500)
            .style("opacity", 0);
        });
    });

  }, [Data, Chart_Width, Chart_Height, Custom_Colors, Additional_Data]);

  return (
    <Card className="w-full h-full" style={{ width: `${Chart_Width}px`, height: `${Chart_Height + 60}px` }}>
      <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white mb-2">
        {Title}
      </h5>
      <Chart_Container>
        <svg ref={SVG_Ref} />
      </Chart_Container>
    </Card>
  );
};

D3SparkChart.propTypes = {
  Title: PropTypes.string.isRequired,
  Data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    series: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number).isRequired
    })).isRequired
  }).isRequired,
  Chart_Height: PropTypes.number,
  Chart_Width: PropTypes.number,
  Custom_Colors: PropTypes.arrayOf(PropTypes.string),
  Additional_Data: PropTypes.arrayOf(PropTypes.object)
};

export default D3SparkChart;
