import React from 'react';
import { Card, Button } from 'flowbite-react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import D3WorkflowVisualizer from './D3WorkflowVisualizer';

const WorkflowCard = ({ workflow, onEdit, onDelete, onStepsReorder }) => {
  const handleStepsReorder = (newSteps) => {
    onStepsReorder(workflow.id, newSteps);
  };

  // Transform approvers array to include IDs for drag and drop
  const stepsWithIds = workflow.approvers?.map((step, index) => ({
    id: `${workflow.id}-step-${index}`,
    name: step
  })) || [];

  return (
    <Card>
      <div className="flex justify-between items-start mb-4">
        <div>
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            {workflow.name}
          </h5>
          <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
            {workflow.description}
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
            <span className="font-semibold">Action Type:</span> {workflow.actionType}
          </p>
        </div>
        <div className="flex gap-2">
          <Button
            color="light"
            size="sm"
            onClick={() => onEdit(workflow)}
          >
            <FiEdit2 className="h-4 w-4" />
          </Button>
          <Button
            color="failure"
            size="sm"
            onClick={() => onDelete(workflow)}
          >
            <FiTrash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <h6 className="text-sm font-semibold mb-2">Approval Steps</h6>
        <D3WorkflowVisualizer
          steps={stepsWithIds}
          onStepsReorder={(newSteps) => handleStepsReorder(newSteps.map(step => step.name))}
        />
      </div>
    </Card>
  );
};

export default WorkflowCard;
