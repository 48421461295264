import { useState, useEffect, useContext } from 'react';
import { StatusContext } from '../contexts/Status';
import { workflowApi } from '../api';

export const useWorkflowActions = () => {
  const [pendingCount, setPendingCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { CompanyID, Email } = useContext(StatusContext);

  useEffect(() => {
    const fetchPendingActions = async () => {
      if (!CompanyID || !Email) return;

      try {
        setLoading(true);
        const pendingActions = await workflowApi.getPendingActions(CompanyID, Email);
        setPendingCount(pendingActions.length);
      } catch (err) {
        console.error('Error fetching pending actions:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingActions();
    
    // Poll for updates every 5 minutes
    const interval = setInterval(fetchPendingActions, 5 * 60 * 1000);
    
    return () => clearInterval(interval);
  }, [CompanyID, Email]);

  return { pendingCount, loading, error };
};
