import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { employeeApi, performanceApi } from '../api';

export const EmployeeContext = createContext();

const processEmployeeData = (Employees_Response, PMS_Response = {}) => {
    // Process PMS data
    const PMS_Map = {};
    if (PMS_Response.Contribution) {
        PMS_Response.Contribution.forEach(Rating => {
            if (!PMS_Map[Rating.Employee_ID]) {
                PMS_Map[Rating.Employee_ID] = {};
            }
            if (!PMS_Map[Rating.Employee_ID].Contribution || 
                new Date(Rating.Period_Date) > new Date(PMS_Map[Rating.Employee_ID].Contribution.Period_Date)) {
                PMS_Map[Rating.Employee_ID].Contribution = Rating;
            }
        });
    }
    if (PMS_Response.WorkStyle) {
        PMS_Response.WorkStyle.forEach(Style => {
            if (!PMS_Map[Style.Employee_ID]) {
                PMS_Map[Style.Employee_ID] = {};
            }
            if (!PMS_Map[Style.Employee_ID].WorkStyle || 
                new Date(Style.Period_Date) > new Date(PMS_Map[Style.Employee_ID].WorkStyle.Period_Date)) {
                PMS_Map[Style.Employee_ID].WorkStyle = Style;
            }
        });
    }
    if (PMS_Response.Career) {
        PMS_Response.Career.forEach(Track => {
            if (!PMS_Map[Track.Employee_ID]) {
                PMS_Map[Track.Employee_ID] = {};
            }
            if (!PMS_Map[Track.Employee_ID].Career || 
                new Date(Track.Period_Date) > new Date(PMS_Map[Track.Employee_ID].Career.Period_Date)) {
                PMS_Map[Track.Employee_ID].Career = Track;
            }
        });
    }
    if (PMS_Response.Supervision) {
        PMS_Response.Supervision.forEach(Supervision => {
            if (!PMS_Map[Supervision.Employee_ID]) {
                PMS_Map[Supervision.Employee_ID] = {};
            }
            if (!PMS_Map[Supervision.Employee_ID].Supervision || 
                new Date(Supervision.Period_Date) > new Date(PMS_Map[Supervision.Employee_ID].Supervision.Period_Date)) {
                PMS_Map[Supervision.Employee_ID].Supervision = Supervision;
            }
        });
    }

    return {
        Employees: Array.isArray(Employees_Response) ? Employees_Response : [],
        PMS_Map
    };
};

export const EmployeeProvider = ({ children, Company_ID }) => {
    const [State, setState] = useState({
        Employees: [],
        PMS_Map: {},
        Is_Loading: true,
        Error: null,
        Meta: null
    });

    const fetchData = useCallback(async () => {
        if (!Company_ID) {
            setState(Prev_State => ({ ...Prev_State, Is_Loading: false, Error: "No company selected" }));
            return;
        }

        setState(Prev_State => ({ ...Prev_State, Is_Loading: true, Error: null }));

        try {
            const [
                Employees_Response,
                Contribution_Response,
                WorkStyle_Response,
                Career_Response,
                Supervision_Response
            ] = await Promise.all([
                employeeApi.getEmployees(Company_ID),
                performanceApi.getContributionRatings(Company_ID),
                performanceApi.getWorkStyles(Company_ID),
                performanceApi.getCareerTracks(Company_ID),
                performanceApi.getSupervisionRequired(Company_ID)
            ]);

            const PMS_Response = {
                Contribution: Contribution_Response.Data || [],
                WorkStyle: WorkStyle_Response.Data || [],
                Career: Career_Response.Data || [],
                Supervision: Supervision_Response.Data || []
            };

            const Processed_Data = processEmployeeData(
                Employees_Response.Data,
                PMS_Response
            );

            setState(Prev_State => ({
                ...Prev_State,
                ...Processed_Data,
                Is_Loading: false,
                Error: null,
                Meta: {
                    Employee: Employees_Response.Meta,
                    Contribution: Contribution_Response.Meta,
                    WorkStyle: WorkStyle_Response.Meta,
                    Career: Career_Response.Meta,
                    Supervision: Supervision_Response.Meta
                }
            }));
        } catch (Error) {
            console.error('Error fetching employee data:', Error);
            setState(Prev_State => ({
                ...Prev_State,
                Is_Loading: false,
                Error: Error.message || 'Failed to fetch data',
                Meta: Error.details?.meta || null
            }));
        }
    }, [Company_ID]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const Context_Value = useMemo(() => ({
        ...State,
        Get_Employees_By_Department: (Department_ID) => 
            State.Employees.filter(Employee => Employee.Department_ID === Department_ID),
        Get_Employees_By_Location: (Location_ID) => 
            State.Employees.filter(Employee => Employee.Work_Location_ID?.trim() === Location_ID?.trim()),
        Get_Employees_By_Job: (Job_ID) => 
            State.Employees.filter(Employee => Employee.Job_ID === Job_ID),
        Get_Employees_By_Manager: (Manager_ID) =>
            State.Employees.filter(Employee => Employee.Reports_To_ID === Manager_ID),
        Get_Active_Employees: () =>
            State.Employees.filter(Employee => !Employee.Termination_Date),
        Get_Terminated_Employees: () =>
            State.Employees.filter(Employee => Employee.Termination_Date),
        Get_Employee_PMS: (Employee_ID) => State.PMS_Map[Employee_ID] || {},
        Get_Department_Metrics: (Department_ID) => {
            const Department_Employees = State.Employees.filter(Employee => Employee.Department_ID === Department_ID);
            return {
                Employee_Count: Department_Employees.length,
                Active_Employee_Count: Department_Employees.filter(Employee => !Employee.Termination_Date).length,
                Male_Employee_Count: Department_Employees.filter(Employee => Employee.Gender?.trim() === 'Male').length,
                Female_Employee_Count: Department_Employees.filter(Employee => Employee.Gender?.trim() === 'Female').length,
                Average_Annual_Salary: Department_Employees.reduce((Sum, Employee) => {
                    const Salary = parseFloat(Employee.Annual_Salary.replace(/[$,]/g, ''));
                    return Sum + (isNaN(Salary) ? 0 : Salary);
                }, 0) / Department_Employees.length || 0
            };
        }
    }), [State]);

    return (
        <EmployeeContext.Provider value={Context_Value}>
            {children}
        </EmployeeContext.Provider>
    );
};
