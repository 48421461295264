import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AccountContext } from '../../contexts/Account';
import { StatusContext } from '../../contexts/Status';

const SignIn = () => {
  const [Email_Address, setEmailAddress] = useState('');
  const [Password_Value, setPasswordValue] = useState('');
  const [Error_Message, setErrorMessage] = useState(null);
  const [Is_Loading, setIsLoading] = useState(false);

  const { Authenticate, Is_Authenticated, Session_Data } = useContext(AccountContext);
  const { Is_Loading: Status_Loading, Auth_Status, Get_Initial_Route } = useContext(StatusContext);
  const Navigate = useNavigate();
  const Location = useLocation();

  // Handle navigation after authentication and status are ready
  useEffect(() => {
    console.log('SignIn: Checking navigation state', {
      Is_Authenticated,
      Has_Session: !!Session_Data,
      Is_Loading: Status_Loading,
      Auth_Status,
      Initial_Route: Get_Initial_Route()
    });

    if (Is_Authenticated && Session_Data && !Status_Loading && Auth_Status === true) {
      const Route_Path = Get_Initial_Route();
      console.log('SignIn: Navigating to', Route_Path);
      if (Route_Path) {
        const From_Path = Location.state?.from?.pathname || Route_Path;
        Navigate(From_Path, { replace: true });
      }
    }
  }, [Is_Authenticated, Session_Data, Status_Loading, Auth_Status, Navigate, Location, Get_Initial_Route]);

  const Handle_Submit = async (Event) => {
    Event.preventDefault();
    setErrorMessage(null);
    setIsLoading(true);

    if (!Email_Address || !Password_Value) {
      setErrorMessage('Please enter both email and password');
      setIsLoading(false);
      return;
    }

    try {
      await Authenticate(Email_Address, Password_Value);
      // Status context will automatically update via its useEffect
    } catch (Error) {
      console.error('SignIn error:', Error);
      setErrorMessage(Error.message || 'An error occurred during sign in');
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <form onSubmit={Handle_Submit}>
        <div className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7">
          <h3 className="mb-6 text-2xl font-medium text-center">
            Sign in to your Account
          </h3>
          <input
            type="email"
            name="email"
            className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
            placeholder="Email address"
            value={Email_Address}
            onChange={(Event) => setEmailAddress(Event.target.value)}
            required
          />
          <input
            type="password"
            name="password"
            className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
            placeholder="Password"
            value={Password_Value}
            onChange={(Event) => setPasswordValue(Event.target.value)}
            required
          />
          {Error_Message && <div className="text-md text-red-600 mb-4">{Error_Message}</div>}
          <div className="block">
            <button
              type="submit"
              className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg disabled:opacity-50 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              disabled={Is_Loading}
            >
              {Is_Loading ? 'Signing In...' : 'Log In'}
            </button>
          </div>
          <p className="w-full mt-4 text-sm text-center text-gray-500">
            Don't have an account?{' '}
            <a href="/signup" className="text-blue-500 hover:text-blue-600 underline">
              Sign up here
            </a>
          </p>
          <p className="w-full mt-4 text-sm text-center text-gray-500">
            Forgot your password?{' '}
            <a href="/reset" className="text-blue-500 hover:text-blue-600 underline">
              Reset Password
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

SignIn.Display_Name = 'SignIn';

export default SignIn;
