import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from "prop-types";
import * as d3 from 'd3';
import { Card } from 'flowbite-react';
import styled from 'styled-components';

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Tooltip = styled.div`
  position: absolute;
  padding: 8px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 100;
  font-size: 12px;
`;

const Controls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  display: flex;
  gap: 8px;
  background: rgba(255, 255, 255, 0.9);
  padding: 4px;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 2px 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  font-size: 12px;
  cursor: pointer;
  
  &:hover {
    background: #f0f0f0;
  }
  
  &:focus {
    outline: 2px solid #0066cc;
    outline-offset: 2px;
  }
`;

export function D3StatisticsChart({
  Chart_Data,
  Chart_Title,
  Chart_Description,
  Class_Name = "",
  Custom_Colors,
  Chart_Height = 350
}) {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const [Is_Brush_Enabled, setIsBrushEnabled] = useState(false);
  const [Dimensions, setDimensions] = useState({ Width: 0, Height: Chart_Height });

  const updateDimensions = useCallback(() => {
    if (containerRef.current) {
      setDimensions({
        Width: containerRef.current.clientWidth,
        Height: Chart_Height
      });
    }
  }, [Chart_Height]);

  // Handle resize
  useEffect(() => {
    updateDimensions();
    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [updateDimensions]);

  useEffect(() => {
    if (!Chart_Data?.Options || !Chart_Data?.Series || !Dimensions.Width) return;

    // Clear previous chart
    if (svgRef.current) {
      d3.select(svgRef.current).selectAll("*").remove();
    }

    const margin = { top: 20, right: 60, bottom: 30, left: 60 };
    const width = Dimensions.Width - margin.left - margin.right;
    const chartHeight = Dimensions.Height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3.select(svgRef.current)
      .attr("width", Dimensions.Width)
      .attr("height", Dimensions.Height)
      .attr("role", "img")
      .attr("aria-label", Chart_Title)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create scales
    const xScale = d3.scalePoint()
      .domain(Chart_Data.Options.X_Axis.Categories)
      .range([0, width]);

    const yScale = d3.scaleLinear()
      .domain([
        d3.min(Chart_Data.Series, s => d3.min(s.Data)) * 0.9,
        d3.max(Chart_Data.Series, s => d3.max(s.Data)) * 1.1
      ])
      .range([chartHeight, 0])
      .nice();

    // Create line generator
    const line = d3.line()
      .x((d, i) => xScale(Chart_Data.Options.X_Axis.Categories[i]))
      .y(d => yScale(d))
      .curve(d3.curveMonotoneX);

    // Add grid lines
    svg.append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(xScale)
        .tickSize(-chartHeight)
        .tickFormat("")
      )
      .style("stroke-opacity", 0.1);

    svg.append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale)
        .tickSize(-width)
        .tickFormat("")
      )
      .style("stroke-opacity", 0.1);

    // Add axes
    svg.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    svg.append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(yScale));

    // Create clip path
    svg.append("defs").append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", width)
      .attr("height", chartHeight);

    // Create chart group with clip path
    const chartGroup = svg.append("g")
      .attr("clip-path", "url(#clip)");

    // Add lines and points
    const colors = Custom_Colors || d3.schemeCategory10;
    Chart_Data.Series.forEach((series, i) => {
      // Add line
      chartGroup.append("path")
        .datum(series.Data)
        .attr("fill", "none")
        .attr("stroke", colors[i])
        .attr("stroke-width", 2)
        .attr("d", line)
        .attr("class", "line")
        .attr("data-series", series.Series_Name);

      // Add points
      chartGroup.selectAll(`dot-${i}`)
        .data(series.Data)
        .enter()
        .append("circle")
        .attr("r", 4)
        .attr("fill", colors[i])
        .attr("cx", (d, j) => xScale(Chart_Data.Options.X_Axis.Categories[j]))
        .attr("cy", d => yScale(d))
        .attr("data-series", series.Series_Name)
        .on("mouseover", (event, d) => {
          if (!tooltipRef.current) return;
          const series = event.target.getAttribute("data-series");
          const category = Chart_Data.Options.X_Axis.Categories[
            Chart_Data.Series.find(s => s.Series_Name === series).Data.indexOf(d)
          ];
          
          d3.select(tooltipRef.current)
            .style("opacity", 1)
            .html(`
              <strong>${series}</strong><br/>
              ${category}: ${d}
            `)
            .style("left", `${event.pageX + 10}px`)
            .style("top", `${event.pageY - 10}px`);
        })
        .on("mouseout", () => {
          if (tooltipRef.current) {
            d3.select(tooltipRef.current).style("opacity", 0);
          }
        });
    });

    // Add legend
    const legend = svg.append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${width + 10}, 0)`);

    Chart_Data.Series.forEach((series, i) => {
      const legendItem = legend.append("g")
        .attr("transform", `translate(0, ${i * 20})`);

      legendItem.append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", colors[i]);

      legendItem.append("text")
        .attr("x", 20)
        .attr("y", 12)
        .text(series.Series_Name)
        .style("font-size", "12px");
    });

    // Add brush if enabled
    if (Is_Brush_Enabled) {
      const brush = d3.brushX()
        .extent([[0, 0], [width, chartHeight]])
        .on("end", (event) => {
          if (!event.selection) return;
          const [x0, x1] = event.selection;
          const domain = Chart_Data.Options.X_Axis.Categories;
          const i0 = Math.floor(x0 / width * (domain.length - 1));
          const i1 = Math.ceil(x1 / width * (domain.length - 1));
          xScale.domain(domain.slice(i0, i1 + 1));
          svg.select(".x-axis").call(d3.axisBottom(xScale));
          
          // Update lines and points
          chartGroup.selectAll(".line")
            .attr("d", function() {
              const series = d3.select(this).attr("data-series");
              const data = Chart_Data.Series.find(s => s.Series_Name === series).Data;
              return line(data.slice(i0, i1 + 1));
            });

          chartGroup.selectAll("circle")
            .attr("cx", function(d, i) {
              const series = d3.select(this).attr("data-series");
              const data = Chart_Data.Series.find(s => s.Series_Name === series).Data;
              return xScale(Chart_Data.Options.X_Axis.Categories[i]);
            });
        });

      chartGroup.append("g")
        .attr("class", "brush")
        .call(brush);
    }

    // Add zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([0.5, 5])
      .extent([[0, 0], [width, chartHeight]])
      .on("zoom", (event) => {
        chartGroup.attr("transform", event.transform);
        svg.select(".x-axis").call(
          d3.axisBottom(event.transform.rescaleX(xScale))
        );
      });

    if (!Is_Brush_Enabled) {
      svg.call(zoom);
    }

    // Cleanup function
    return () => {
      if (svgRef.current) {
        d3.select(svgRef.current).selectAll("*").remove();
      }
    };
  }, [Chart_Data, Dimensions, Custom_Colors, Is_Brush_Enabled, Chart_Title]);

  if (!Chart_Data?.Options || !Chart_Data?.Series) {
    return (
      <Card className={`${Class_Name} h-full`}>
        <div>
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {Chart_Title}
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            {Chart_Description}
          </p>
        </div>
        <div className="mt-4">
          Error: Invalid chart data
        </div>
      </Card>
    );
  }

  return (
    <Card className={`${Class_Name} h-full`}>
      <div>
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {Chart_Title}
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {Chart_Description}
        </p>
      </div>
      <ChartContainer ref={containerRef}>
        <Controls>
          <Button
            onClick={() => setIsBrushEnabled(!Is_Brush_Enabled)}
            aria-label={Is_Brush_Enabled ? "Disable brush" : "Enable brush"}
          >
            {Is_Brush_Enabled ? "Disable Brush" : "Enable Brush"}
          </Button>
        </Controls>
        <svg ref={svgRef} />
        <Tooltip ref={tooltipRef} style={{ opacity: 0 }} />
      </ChartContainer>
    </Card>
  );
}

D3StatisticsChart.propTypes = {
  Chart_Data: PropTypes.shape({
    Options: PropTypes.object.isRequired,
    Series: PropTypes.array.isRequired,
  }).isRequired,
  Chart_Title: PropTypes.string.isRequired,
  Chart_Description: PropTypes.string.isRequired,
  Class_Name: PropTypes.string,
  Custom_Colors: PropTypes.arrayOf(PropTypes.string),
  Chart_Height: PropTypes.number,
};

export default D3StatisticsChart;
