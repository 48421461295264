import React from 'react';
import { Typography } from "@material-tailwind/react";
import UserRoleChips from './UserRoleChips';

const UserTableRow = ({ 
  user, 
  isEditing, 
  onEdit, 
  onSave, 
  onRoleToggle,
  classes 
}) => {
  const {
    Email_Address,
    Client_ID,
    Company_ID,
    Admin,
    Super_Admin,
    Manager,
    Approver,
    Auditor,
    HR,
    Executive,
    Recorder
  } = user;

  return (
    <tr className={isEditing ? "bg-blue-gray-50" : "hover:bg-blue-gray-50 hover:cursor-pointer"}>
      <td className={classes}>
        <div>
          <Typography variant="small" color="blue-gray" className="font-normal">
            {Email_Address}
          </Typography>
        </div>
      </td>
      <td className={classes}>
        <div>
          <Typography variant="small" color="blue-gray" className="font-normal">
            {Client_ID}
          </Typography>
        </div>
      </td>
      <td className={classes}>
        <div>
          <Typography variant="small" color="blue-gray" className="font-normal">
            {Company_ID}
          </Typography>
        </div>
      </td>
      <td className={classes}>
        <UserRoleChips
          roles={{ Admin, Manager, Approver, Auditor, HR, Executive, Recorder }}
          isEditing={isEditing}
          onToggle={onRoleToggle}
        />
      </td>
      <td className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-6 h-6 ${isEditing ? 'animate-pulse' : ''}`}
          onClick={isEditing ? onSave : onEdit}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
          />
        </svg>
      </td>
    </tr>
  );
};

export default UserTableRow;
