import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { organizationApi } from '../api';

export const LocationContext = createContext();

export const LocationProvider = ({ children, Company_ID }) => {
    const [State, setState] = useState({
        Locations: [],
        Location_Map: {},
        Location_Hierarchy: {}, // For locations that might have parent/child relationships
        Region_Map: {}, // For grouping locations by region
        Is_Loading: true,
        Error: null
    });

    const fetchData = useCallback(async () => {
        if (!Company_ID) {
            setState(prev => ({ ...prev, Is_Loading: false, Error: "No company selected" }));
            return;
        }

        setState(prev => ({ ...prev, Is_Loading: true, Error: null }));

        try {
            const Locations_Response = await organizationApi.getLocations(Company_ID);
            const Locations = Locations_Response?.Data?.Records || [];

            // Process locations and create mappings
            const Location_Map = {};
            const Location_Hierarchy = {};
            const Region_Map = {};

            Locations.forEach(Location => {
                if (Location.Work_Location_ID) {
                    const Trimmed_Location_ID = Location.Work_Location_ID.trim();
                    Location_Map[Trimmed_Location_ID] = Location.Location_Name || 'Unknown Location';

                    // Build region mapping
                    if (Location.Region) {
                        if (!Region_Map[Location.Region]) {
                            Region_Map[Location.Region] = [];
                        }
                        Region_Map[Location.Region].push(Trimmed_Location_ID);
                    }

                    // Build hierarchy if parent/child relationships exist
                    if (Location.Parent_Location_ID) {
                        if (!Location_Hierarchy[Location.Parent_Location_ID]) {
                            Location_Hierarchy[Location.Parent_Location_ID] = [];
                        }
                        Location_Hierarchy[Location.Parent_Location_ID].push(Trimmed_Location_ID);
                    }
                }
            });

            setState(prev => ({
                ...prev,
                Locations,
                Location_Map,
                Location_Hierarchy,
                Region_Map,
                Is_Loading: false,
                Error: null
            }));
        } catch (Error) {
            console.error('Error fetching location data:', Error);
            setState(prev => ({
                ...prev,
                Is_Loading: false,
                Error: Error.Message || 'Failed to fetch location data'
            }));
        }
    }, [Company_ID]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Get location name
    const Get_Location_Name = useCallback((Location_ID) => {
        return State.Location_Map[Location_ID?.trim()] || Location_ID || 'Unknown Location';
    }, [State.Location_Map]);

    // Get child locations
    const Get_Child_Locations = useCallback((Location_ID) => {
        return State.Location_Hierarchy[Location_ID] || [];
    }, [State.Location_Hierarchy]);

    // Get parent location
    const Get_Parent_Location = useCallback((Location_ID) => {
        const Location = State.Locations.find(
            loc => loc.Work_Location_ID.trim() === Location_ID?.trim()
        );
        return Location?.Parent_Location_ID || null;
    }, [State.Locations]);

    // Get locations by region
    const Get_Locations_By_Region = useCallback((Region) => {
        return State.Region_Map[Region] || [];
    }, [State.Region_Map]);

    // Get all regions
    const Get_All_Regions = useCallback(() => {
        return Object.keys(State.Region_Map);
    }, [State.Region_Map]);

    // Get location full path
    const Get_Location_Path = useCallback((Location_ID) => {
        const path = [];
        let current_id = Location_ID;

        while (current_id) {
            path.unshift(current_id);
            current_id = Get_Parent_Location(current_id);
        }

        return path;
    }, [Get_Parent_Location]);

    // Get all locations under a parent (including nested)
    const Get_All_Child_Locations = useCallback((Location_ID) => {
        const children = new Set();
        const toProcess = [Location_ID];

        while (toProcess.length > 0) {
            const current = toProcess.pop();
            const child_locations = Get_Child_Locations(current);
            
            child_locations.forEach(child => {
                if (!children.has(child)) {
                    children.add(child);
                    toProcess.push(child);
                }
            });
        }

        return Array.from(children);
    }, [Get_Child_Locations]);

    const Context_Value = useMemo(() => ({
        ...State,
        Get_Location_Name,
        Get_Child_Locations,
        Get_Parent_Location,
        Get_Locations_By_Region,
        Get_All_Regions,
        Get_Location_Path,
        Get_All_Child_Locations,
        Refresh_Locations: fetchData
    }), [
        State,
        Get_Location_Name,
        Get_Child_Locations,
        Get_Parent_Location,
        Get_Locations_By_Region,
        Get_All_Regions,
        Get_Location_Path,
        Get_All_Child_Locations,
        fetchData
    ]);

    return (
        <LocationContext.Provider value={Context_Value}>
            {children}
        </LocationContext.Provider>
    );
};
