import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Card } from 'flowbite-react';
import styled from 'styled-components';

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Tooltip = styled.div`
  position: absolute;
  padding: 8px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 100;
  font-size: 12px;
`;

const Controls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  display: flex;
  gap: 8px;
  background: rgba(255, 255, 255, 0.9);
  padding: 4px;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 2px 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  font-size: 12px;
  cursor: pointer;
  
  &:hover {
    background: #f0f0f0;
  }
`;

export function D3OrgChart({
  Chart_Data,
  Chart_Title,
  Chart_Description,
  Class_Name = "",
  Chart_Height = 500
}) {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const [Dimensions, setDimensions] = useState({ Width: 0, Height: Chart_Height });

  const updateDimensions = useCallback(() => {
    if (containerRef.current) {
      setDimensions({
        Width: containerRef.current.clientWidth,
        Height: Chart_Height
      });
    }
  }, [Chart_Height]);

  useEffect(() => {
    updateDimensions();
    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [updateDimensions]);

  useEffect(() => {
    if (!Chart_Data || !Dimensions.Width) return;

    // Clear previous chart
    if (svgRef.current) {
      d3.select(svgRef.current).selectAll("*").remove();
    }

    const margin = { top: 20, right: 20, bottom: 20, left: 20 };
    const width = Dimensions.Width - margin.left - margin.right;
    const chartHeight = Dimensions.Height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3.select(svgRef.current)
      .attr("width", Dimensions.Width)
      .attr("height", Dimensions.Height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create hierarchy
    const root = d3.hierarchy(Chart_Data);
    
    // Create tree layout
    const treeLayout = d3.tree()
      .size([width, chartHeight]);

    // Apply layout
    const treeData = treeLayout(root);

    // Create links
    svg.selectAll(".link")
      .data(treeData.links())
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("fill", "none")
      .attr("stroke", "#ccc")
      .attr("d", d3.linkVertical()
        .x(d => d.x)
        .y(d => d.y));

    // Create nodes
    const nodes = svg.selectAll(".node")
      .data(treeData.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", d => `translate(${d.x},${d.y})`);

    // Add node circles
    nodes.append("circle")
      .attr("r", d => {
        // Size based on power score or reports count
        const size = d.data.Power_Score || (d.data.Total_Reports || 5);
        return Math.max(5, Math.min(15, size));
      })
      .attr("fill", d => {
        // Color based on power level
        switch (d.data.Power_Level) {
          case 'Executive': return '#ef4444';
          case 'Senior': return '#f59e0b';
          case 'Middle': return '#3b82f6';
          default: return '#6b7280';
        }
      })
      .on("mouseover", (event, d) => {
        if (!tooltipRef.current) return;
        
        d3.select(tooltipRef.current)
          .style("opacity", 1)
          .html(`
            <strong>${d.data.Organization_Name}</strong><br/>
            Level: ${d.data.Organization_Level}<br/>
            Power Score: ${d.data.Power_Score || 'N/A'}<br/>
            Direct Reports: ${d.data.Direct_Reports || 0}<br/>
            Total Reports: ${d.data.Total_Reports || 0}
          `)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 10}px`);
      })
      .on("mouseout", () => {
        if (tooltipRef.current) {
          d3.select(tooltipRef.current).style("opacity", 0);
        }
      });

    // Add labels
    nodes.append("text")
      .attr("dy", "0.31em")
      .attr("x", d => d.Children ? -6 : 6)
      .attr("text-anchor", d => d.Children ? "end" : "start")
      .text(d => d.data.Organization_Name)
      .clone(true).lower()
      .attr("stroke", "white")
      .attr("stroke-width", 3);

    // Add zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([0.5, 2])
      .on("zoom", (event) => {
        svg.attr("transform", event.transform);
      });

    d3.select(svgRef.current).call(zoom);

    return () => {
      if (svgRef.current) {
        d3.select(svgRef.current).selectAll("*").remove();
      }
    };
  }, [Chart_Data, Dimensions]);

  if (!Chart_Data) {
    return (
      <Card className={`${Class_Name} h-full`}>
        <div>
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {Chart_Title}
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            {Chart_Description}
          </p>
        </div>
        <div className="mt-4">
          Error: No organization data available
        </div>
      </Card>
    );
  }

  return (
    <Card className={`${Class_Name} h-full`}>
      <div>
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {Chart_Title}
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {Chart_Description}
        </p>
      </div>
      <ChartContainer ref={containerRef}>
        <Controls>
          <Button onClick={() => {
            if (svgRef.current) {
              d3.select(svgRef.current).transition().call(
                d3.zoom().transform,
                d3.zoomIdentity
              );
            }
          }}>
            Reset Zoom
          </Button>
        </Controls>
        <svg ref={svgRef} />
        <Tooltip ref={tooltipRef} style={{ opacity: 0 }} />
      </ChartContainer>
    </Card>
  );
}

D3OrgChart.propTypes = {
  Chart_Data: PropTypes.object.isRequired,
  Chart_Title: PropTypes.string.isRequired,
  Chart_Description: PropTypes.string.isRequired,
  Class_Name: PropTypes.string,
  Chart_Height: PropTypes.number
};

export default D3OrgChart;
