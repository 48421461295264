import React, { useState, useContext } from 'react';
import { Card, Button, Select, TextInput, Alert } from 'flowbite-react';
import { DashboardContext } from '../../contexts/DashboardContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import * as Charts from './index';

const ChartExplorer = () => {
    const { saveDashboard } = useContext(DashboardContext);
    const { getChartData, getPredictions, isLoading, error } = useContext(AnalyticsContext);
    
    const [selectedChart, setSelectedChart] = useState(null);
    const [selectedMetric, setSelectedMetric] = useState('');
    const [chartConfig, setChartConfig] = useState({});

    // Available chart types based on analytics data
    const chartTypes = [
        {
            id: 'power-score-distribution',
            title: 'Power Score Distribution',
            component: Charts.D3StackedBarChart,
            description: 'Shows distribution of power scores across organization'
        },
        {
            id: 'pms-category-distribution',
            title: 'PMS Category Distribution',
            component: Charts.DonutChart,
            description: 'Shows distribution of PMS categories'
        },
        {
            id: 'workflow-metrics',
            title: 'Workflow Metrics',
            component: Charts.D3FunnelChart,
            description: 'Shows workflow progression and metrics'
        },
        {
            id: 'performance-metrics',
            title: 'Performance Metrics',
            component: Charts.D3SpiderChart,
            description: 'Shows multiple performance metrics'
        },
        {
            id: 'correlation-impact',
            title: 'Correlation Impact',
            component: Charts.D3GaugeChart,
            description: 'Shows impact correlations between metrics'
        }
    ];

    // Available metrics based on selected chart
    const getAvailableMetrics = (chartType) => {
        switch (chartType) {
            case 'power-score-distribution':
                return [
                    { id: 'overall', label: 'Overall Distribution' },
                    { id: 'by-level', label: 'Distribution by Level' }
                ];
            case 'pms-category-distribution':
                return [
                    { id: 'current', label: 'Current Distribution' },
                    { id: 'trend', label: 'Distribution Trend' }
                ];
            case 'workflow-metrics':
                return [
                    { id: 'volume', label: 'Volume Metrics' },
                    { id: 'performance', label: 'Performance Metrics' },
                    { id: 'pms-impact', label: 'PMS Impact' }
                ];
            case 'performance-metrics':
                return [
                    { id: 'overall', label: 'Overall Progress' },
                    { id: 'by-type', label: 'Progress by Type' }
                ];
            case 'correlation-impact':
                return [
                    { id: 'power-score', label: 'Power Score Impact' },
                    { id: 'pms', label: 'PMS Impact' },
                    { id: 'workflow', label: 'Workflow Impact' }
                ];
            default:
                return [];
        }
    };

    // Save chart configuration
    const handleSave = async (name) => {
        const config = {
            name,
            chartType: selectedChart,
            metric: selectedMetric,
            config: chartConfig,
            predictions: getPredictions(selectedMetric)
        };

        await saveDashboard({
            ...config,
            Created_By: 'current_user@example.com', // Replace with actual user
            Modified_By: 'current_user@example.com'
        });
    };

    // Render chart preview
    const renderChart = () => {
        if (!selectedChart) return null;

        const ChartComponent = chartTypes.find(c => c.id === selectedChart)?.component;
        if (!ChartComponent) return null;

        const data = getChartData(selectedChart, {
            metric: selectedMetric,
            ...chartConfig
        });

        return (
            <div className="h-96">
                <ChartComponent {...data} />
            </div>
        );
    };

    if (isLoading) {
        return <div className="text-center p-4">Loading analytics data...</div>;
    }

    if (error) {
        return (
            <Alert color="failure">
                <h4 className="font-bold">Error Loading Analytics</h4>
                <p>{error.message}</p>
            </Alert>
        );
    }

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <div className="max-w-7xl mx-auto">
                <header className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">Chart Explorer</h1>
                    <p className="mt-2 text-gray-600">
                        Explore and customize analytics visualizations
                    </p>
                </header>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Chart Selection */}
                    <div className="lg:col-span-2">
                        <div className="grid grid-cols-2 gap-4">
                            {chartTypes.map(chart => (
                                <Card
                                    key={chart.id}
                                    className={`cursor-pointer transition-all duration-200 hover:shadow-md ${
                                        selectedChart === chart.id ? 'ring-2 ring-blue-500' : ''
                                    }`}
                                    onClick={() => setSelectedChart(chart.id)}
                                >
                                    <h3 className="text-lg font-semibold mb-2">{chart.title}</h3>
                                    <p className="text-sm text-gray-600">{chart.description}</p>
                                </Card>
                            ))}
                        </div>
                    </div>

                    {/* Configuration Panel */}
                    <div className="space-y-6">
                        <Card>
                            <h3 className="text-lg font-semibold mb-4">Chart Configuration</h3>
                            
                            {selectedChart && (
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Metric Type
                                        </label>
                                        <Select
                                            value={selectedMetric}
                                            onChange={(e) => setSelectedMetric(e.target.value)}
                                            className="w-full"
                                        >
                                            <option value="">Select a metric</option>
                                            {getAvailableMetrics(selectedChart).map(metric => (
                                                <option key={metric.id} value={metric.id}>
                                                    {metric.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>

                                    <div>
                                        <TextInput
                                            type="text"
                                            placeholder="Chart Name"
                                            className="mb-2"
                                            onChange={(e) => handleSave(e.target.value)}
                                        />
                                        <Button
                                            color="blue"
                                            className="w-full"
                                            onClick={() => handleSave('New Chart')}
                                        >
                                            Save Configuration
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Card>

                        {selectedChart && selectedMetric && (
                            <Card>
                                <h3 className="text-lg font-semibold mb-4">Predictions</h3>
                                <div className="space-y-2">
                                    {getPredictions(selectedMetric) ? (
                                        <pre className="text-sm bg-gray-50 p-2 rounded">
                                            {JSON.stringify(getPredictions(selectedMetric), null, 2)}
                                        </pre>
                                    ) : (
                                        <p className="text-gray-500">No predictions available</p>
                                    )}
                                </div>
                            </Card>
                        )}
                    </div>
                </div>

                {/* Chart Preview */}
                {selectedChart && (
                    <Card className="mt-8">
                        <h2 className="text-2xl font-bold mb-4">
                            {chartTypes.find(c => c.id === selectedChart)?.title} Preview
                        </h2>
                        {renderChart()}
                    </Card>
                )}
            </div>
        </div>
    );
};

export default ChartExplorer;
