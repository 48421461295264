import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PyramidChart = ({ Data_List, Chart_Width = 300, Chart_Height = 300, Color_Scheme = d3.schemeCategory10 }) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 20, Right: 20, Bottom: 30, Left: 60 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Pyramid Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Create scales
    const X_Scale = d3.scaleLinear()
      .domain([0, d3.max(Data_List, Item => Item.Value)])
      .range([0, Inner_Width]);

    const Y_Scale = d3.scaleBand()
      .domain(Data_List.map(Item => Item.Label))
      .range([0, Inner_Height])
      .padding(0.1);

    // Create color scale
    const Color_Scale = d3.scaleOrdinal()
      .domain(Data_List.map(Item => Item.Label))
      .range(Color_Scheme);

    // Add bars
    SVG.selectAll(".bar")
      .data(Data_List)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("y", Item => Y_Scale(Item.Label))
      .attr("height", Y_Scale.bandwidth())
      .attr("x", Inner_Width / 2)
      .attr("width", 0)
      .attr("fill", Item => Color_Scale(Item.Label))
      .transition()
      .duration(1000)
      .attr("x", Item => Inner_Width / 2 - X_Scale(Item.Value) / 2)
      .attr("width", Item => X_Scale(Item.Value));

    // Add labels
    SVG.selectAll(".label")
      .data(Data_List)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("y", Item => Y_Scale(Item.Label) + Y_Scale.bandwidth() / 2)
      .attr("x", Inner_Width / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .text(Item => `${Item.Label}: ${Item.Value}`)
      .style("fill", "white");

    // Add axes
    const Y_Axis = d3.axisLeft(Y_Scale);
    SVG.append("g")
      .attr("class", "y-axis")
      .call(Y_Axis);

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    SVG.selectAll(".bar")
      .on("mouseover", (Event, Item) => {
        Tooltip.transition()
          .duration(200)
          .style("opacity", 0.9);
        Tooltip.html(`
          <strong>${Item.Label}</strong><br/>
          Value: ${Item.Value}<br/>
          Percentage: ${(Item.Value * 100 / d3.sum(Data_List, D => D.Value)).toFixed(1)}%
        `)
          .style("left", `${Event.pageX}px`)
          .style("top", `${Event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        Tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }, [Data_List, Chart_Width, Chart_Height, Color_Scheme]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

PyramidChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Scheme: PropTypes.arrayOf(PropTypes.string)
};

PyramidChart.Display_Name = 'PyramidChart';

export default PyramidChart;
