import React, { createContext, useState, useCallback, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../auth/UserPool";

export const AccountContext = createContext();

export const Account = (props) => {
  const [Session_Data, setSessionData] = useState(null);
  const [Is_Authenticated, setIsAuthenticated] = useState(false);

  const Is_Valid_Session = useCallback((Session) => {
    if (!Session || !Session.idToken || !Session.idToken.payload) {
      console.log("Account: Invalid session structure");
      return false;
    }

    const Expiration = Session.idToken.payload.exp * 1000; // Convert to milliseconds
    const Current_Time = Date.now();
    const Is_Valid = Current_Time < Expiration;

    console.log("Account: Session validation", {
      Expires_At: new Date(Expiration).toISOString(),
      Current_Time: new Date(Current_Time).toISOString(),
      Is_Valid
    });

    return Is_Valid;
  }, []);

  const Get_Session = useCallback(() => {
    return new Promise((resolve, reject) => {
      const Current_User = Pool.getCurrentUser();
      console.log("Account: Getting current user", Current_User);
      
      if (Current_User) {
        Current_User.getSession((Error, Session) => {
          if (Error) {
            console.error("Account: Error getting session", Error);
            setIsAuthenticated(false);
            setSessionData(null);
            reject(Error);
          } else {
            console.log("Account: Session retrieved", Session);
            
            if (Is_Valid_Session(Session)) {
              console.log("Account: Valid session found");
              setSessionData(Session);
              setIsAuthenticated(true);
              resolve(Session);
            } else {
              console.log("Account: Session expired or invalid");
              setIsAuthenticated(false);
              setSessionData(null);
              reject(new Error("Session expired"));
            }
          }
        });
      } else {
        console.log("Account: No current user found");
        setIsAuthenticated(false);
        setSessionData(null);
        reject(new Error("No current user"));
      }
    });
  }, [Is_Valid_Session]);

  useEffect(() => {
    console.log("Account: Running initial getSession");
    Get_Session().catch((Error) => {
      console.error("Account: Initial getSession failed", Error);
      setIsAuthenticated(false);
      setSessionData(null);
    });
  }, [Get_Session]);

  const Authenticate = useCallback((Username, Password) => {
    console.log("Account: Attempting to authenticate", Username);
    return new Promise((resolve, reject) => {
      const User = new CognitoUser({ Username, Pool });
      const Auth_Details = new AuthenticationDetails({ Username, Password });

      User.authenticateUser(Auth_Details, {
        onSuccess: (Data) => {
          console.log("Account: Authentication successful", Data);
          if (Is_Valid_Session(Data)) {
            setSessionData(Data);
            setIsAuthenticated(true);
            resolve(Data);
          } else {
            console.error("Account: Invalid session after authentication");
            setIsAuthenticated(false);
            setSessionData(null);
            reject(new Error("Invalid session after authentication"));
          }
        },
        onFailure: (Error) => {
          console.error("Account: Authentication failed", Error);
          setIsAuthenticated(false);
          setSessionData(null);
          reject(Error);
        },
        newPasswordRequired: (Data) => {
          console.log("Account: New password required", Data);
          resolve(Data);
        },
      });
    });
  }, [Is_Valid_Session]);

  const Logout = useCallback(() => {
    const Current_User = Pool.getCurrentUser();
    if (Current_User) {
      console.log("Account: Logging out user");
      Current_User.signOut();
      setSessionData(null);
      setIsAuthenticated(false);
    } else {
      console.log("Account: No user to log out");
    }
  }, []);

  // Check session validity periodically
  useEffect(() => {
    if (Is_Authenticated) {
      const Check_Interval = setInterval(() => {
        console.log("Account: Checking session validity");
        if (Session_Data && !Is_Valid_Session(Session_Data)) {
          console.log("Account: Session expired during check");
          setIsAuthenticated(false);
          setSessionData(null);
        }
      }, 60000); // Check every minute

      return () => clearInterval(Check_Interval);
    }
  }, [Is_Authenticated, Session_Data, Is_Valid_Session]);

  const Context_Value = {
    Authenticate,
    Get_Session,
    Logout,
    Session_Data,
    Is_Authenticated,
  };

  return (
    <AccountContext.Provider value={Context_Value}>
      {props.children}
    </AccountContext.Provider>
  );
};
