import React from 'react';
import { ReferenceDataManager } from '../../components/data';

const ReferenceData = () => {
  return (
    <div className="bg-white shadow rounded-lg">
      <ReferenceDataManager />
    </div>
  );
};

export default ReferenceData;
