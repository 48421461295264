import React, { useState } from 'react';
import { Card, Button, Spinner } from 'flowbite-react';
import * as Charts from './index';

const ChartGallery = () => {
  const [loading, setLoading] = useState(false);

  // Sample data for each chart type
  const Sample_Data = {
    spider: {
      data: [
        {
          Label: 'Current',
          Values: {
            'Productivity': 85,
            'Quality': 92,
            'Engagement': 78,
            'Innovation': 65,
            'Leadership': 88
          }
        },
        {
          Label: 'Target',
          Values: {
            'Productivity': 90,
            'Quality': 95,
            'Engagement': 85,
            'Innovation': 80,
            'Leadership': 90
          }
        }
      ]
    },
    gauge: {
      Value: 7.5,
      Min_Value: 0,
      Max_Value: 10,
      Title: 'Power Score'
    },
    timeline: {
      Data_List: [
        {
          Category: 'Phase 1',
          Label: 'Planning',
          Start_Date: new Date('2024-01-01'),
          End_Date: new Date('2024-02-15'),
          Status: 'Completed',
          Progress: 100,
          Milestones: [
            { Date: new Date('2024-01-15'), Label: 'Review', Status: 'Completed' }
          ]
        },
        {
          Category: 'Phase 2',
          Label: 'Development',
          Start_Date: new Date('2024-02-16'),
          End_Date: new Date('2024-04-30'),
          Status: 'In_Progress',
          Progress: 60,
          Milestones: [
            { Date: new Date('2024-03-15'), Label: 'Milestone 1', Status: 'Completed' }
          ]
        }
      ]
    },
    funnel: {
      Data_List: [
        { Label: 'Total Actions', Value: 1000 },
        { Label: 'In Progress', Value: 750 },
        { Label: 'Completed', Value: 500 },
        { Label: 'Successful', Value: 250 }
      ]
    },
    flow: {
      Data: {
        nodes: [
          { 
            id: 'start',
            label: 'Start Process',
            status: 'Completed',
            completion_rate: 100,
            average_time: 1,
            bottleneck: false
          },
          { 
            id: 'review',
            label: 'Review',
            status: 'In_Progress',
            completion_rate: 60,
            average_time: 3,
            bottleneck: false
          },
          { 
            id: 'approve',
            label: 'Approve',
            status: 'Pending',
            completion_rate: 0,
            average_time: 2,
            bottleneck: true
          }
        ],
        links: [
          { source: 'start', target: 'review' },
          { source: 'review', target: 'approve' }
        ]
      }
    }
  };

  const ChartCard = ({ title, description, children, className = '' }) => (
    <Card className={`transition-all duration-300 hover:shadow-lg ${className}`}>
      <div className="flex flex-col h-full">
        <div className="mb-4">
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-sm text-gray-600 mt-1">{description}</p>
        </div>
        <div className="flex-grow relative min-h-[300px] w-full">
          {loading ? (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner size="xl" />
            </div>
          ) : (
            <div className="w-full h-full">{children}</div>
          )}
        </div>
      </div>
    </Card>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Chart Gallery</h1>
          <p className="mt-2 text-gray-600">
            Explore our collection of interactive data visualizations
          </p>
        </div>

        <div className="space-y-12">
          {/* Performance Metrics Section */}
          <section>
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-2xl font-semibold text-gray-800">Performance Metrics</h2>
              <Button color="light" size="sm" onClick={() => setLoading(!loading)}>
                {loading ? 'Show Charts' : 'Test Loading'}
              </Button>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <ChartCard
                title="Spider Chart"
                description="Compare multiple metrics across categories"
              >
                <Charts.D3SpiderChart {...Sample_Data.spider} />
              </ChartCard>
              <ChartCard
                title="Gauge Chart"
                description="Show single metric with thresholds"
              >
                <Charts.D3GaugeChart {...Sample_Data.gauge} />
              </ChartCard>
            </div>
          </section>

          {/* Process Visualization Section */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Process Visualization</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <ChartCard
                title="Timeline Chart"
                description="Show progression and milestones"
              >
                <Charts.D3TimelineChart {...Sample_Data.timeline} />
              </ChartCard>
              <ChartCard
                title="Funnel Chart"
                description="Show stage progression and conversion"
              >
                <Charts.D3FunnelChart {...Sample_Data.funnel} />
              </ChartCard>
              <ChartCard
                title="Flow Chart"
                description="Show approval paths and workflows"
              >
                <Charts.D3FlowChart {...Sample_Data.flow} />
              </ChartCard>
            </div>
          </section>

          {/* Usage Guidelines */}
          <section>
            <Card>
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold text-gray-800">Usage Guidelines</h2>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold">Color Standards</h3>
                    <div className="grid grid-cols-2 gap-3">
                      {[
                        { color: 'bg-green-500', label: 'Success/Completed' },
                        { color: 'bg-yellow-500', label: 'Warning/In Progress' },
                        { color: 'bg-red-500', label: 'Error/Blocked' },
                        { color: 'bg-gray-400', label: 'Inactive/Pending' }
                      ].map(({ color, label }) => (
                        <div key={color} className="flex items-center space-x-2">
                          <div className={`w-4 h-4 ${color} rounded`} />
                          <span className="text-sm">{label}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold">Accessibility</h3>
                    <ul className="space-y-2">
                      {[
                        'Include ARIA labels',
                        'Support keyboard navigation',
                        'Provide screen reader descriptions',
                        'Use sufficient color contrast'
                      ].map((item) => (
                        <li key={item} className="flex items-center space-x-2">
                          <svg className="w-4 h-4 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                          </svg>
                          <span className="text-sm">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold">Responsiveness</h3>
                    <ul className="space-y-2">
                      {[
                        'Adjust to container size',
                        'Maintain aspect ratios',
                        'Support touch interactions',
                        'Optimize for mobile views'
                      ].map((item) => (
                        <li key={item} className="flex items-center space-x-2">
                          <svg className="w-4 h-4 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" />
                          </svg>
                          <span className="text-sm">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChartGallery;
