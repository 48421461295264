import { API_ENDPOINTS } from '../core/constants';
import { API_Client, API_Error } from '../core/apiClient';
import { Action_Config_API } from './actionConfig';

/**
 * Transform API response to standard format
 * @param {Object} Response_Data - Raw API response
 * @param {Array} Default_Data - Default data if response is empty
 * @returns {Object} Transformed response
 */
const Transform_Response = (Response_Data, Default_Data = []) => {
    if (!Response_Data) return { Message: '', Data: { Records: Default_Data } };
    
    if (Response_Data.Data) {
        return {
            Message: Response_Data.Message || '',
            Data: {
                Records: Array.isArray(Response_Data.Data) ? Response_Data.Data : (Response_Data.Data.Records || Default_Data),
                Analytics: Response_Data.Data.Analytics || {},
                Query_Context: Response_Data.Data.Query_Context || {}
            }
        };
    }
    
    return {
        Message: '',
        Data: {
            Records: Array.isArray(Response_Data) ? Response_Data : Default_Data
        }
    };
};

/**
 * Workflow and actions related API calls
 */
export const Workflow_API = {
    // Actions
    getActions: async (Company_ID, Action_ID = null, Options = {}) => {
        if (!Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
        }

        console.log('Workflow_API: Getting actions', { Company_ID, Action_ID, Options });
        const Query_Params = {
            Company_ID,
            ...Options
        };
        if (Action_ID) Query_Params.Action_ID = Action_ID;
        
        const Response = await API_Client.get(API_ENDPOINTS.WORKFLOW.ACTIONS, {
            params: Query_Params
        });
        return Transform_Response(Response);
    },

    createAction: async (Action_Data) => {
        if (!Action_Data.Company_ID || !Action_Data.Action_Type || !Action_Data.Employee_ID || !Action_Data.Org_ID) {
            throw new API_Error('Company ID, Action Type, Employee ID, and Organization ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Creating action', {
            Company_ID: Action_Data.Company_ID,
            Action_Type: Action_Data.Action_Type,
            Employee_ID: Action_Data.Employee_ID,
            Org_ID: Action_Data.Org_ID
        });

        const Response = await API_Client.post(API_ENDPOINTS.WORKFLOW.ACTIONS, Action_Data);

        return {
            Action: {
                Action_ID: Response.Action_ID,
                Status: Response.Status || 'Initiated',
                Current_Step: Response.Current_Step || 1
            }
        };
    },

    advanceAction: async (Action_Data) => {
        if (!Action_Data.Action_ID || !Action_Data.Approver_ID) {
            throw new API_Error('Action ID and Approver ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Advancing action', {
            Action_ID: Action_Data.Action_ID,
            Approver_ID: Action_Data.Approver_ID
        });

        const Response = await API_Client.post(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/advance`, Action_Data);

        return {
            Action: {
                Action_ID: Action_Data.Action_ID,
                Status: Response.Status || 'In_Progress',
                Current_Step: Response.Current_Step || 2
            }
        };
    },

    rejectAction: async (Action_Type, Action_Payload) => {
        if (!Action_Type || !Action_Payload) {
            throw new API_Error('Action Type and Payload are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Rejecting action', { Action_Type });
        const Response = await API_Client.post(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/reject`, {
            Action_Type,
            ...Action_Payload
        });
        return Transform_Response(Response);
    },

    getActionStats: async (Company_ID) => {
        if (!Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
        }

        console.log('Workflow_API: Getting action stats', { Company_ID });
        const Response = await API_Client.get(API_ENDPOINTS.WORKFLOW.ACTIONS, {
            params: {
                Company_ID,
                Type: 'approval-stats'
            }
        });
        return Transform_Response(Response);
    },

    getCompletedActions: async (Company_ID, Employee_ID) => {
        if (!Company_ID || !Employee_ID) {
            throw new API_Error('Company ID and Employee ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Getting completed actions', { Company_ID, Employee_ID });
        const Response = await API_Client.get(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/completed-actions`, {
            params: {
                Company_ID,
                Employee_ID
            }
        });
        return Transform_Response(Response);
    },

    getPendingActions: async (Company_ID, Active_Approver) => {
        if (!Company_ID || !Active_Approver) {
            throw new API_Error('Company ID and Active Approver are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Getting pending actions', { Company_ID, Active_Approver });
        const Response = await API_Client.get(`${API_ENDPOINTS.WORKFLOW.ACTIONS}/pending-actions`, {
            params: {
                Company_ID,
                Active_Approver
            }
        });
        return Transform_Response(Response);
    },

    // Approval Groups
    getApprovalGroups: async (Company_ID = null, Options = {}) => {
        console.log('Workflow_API: Getting approval groups', { Company_ID, Options });
        const Query_Params = { ...Options };
        if (Company_ID) Query_Params.Company_ID = Company_ID;
        
        const Response = await API_Client.get(API_ENDPOINTS.WORKFLOW.APPROVALS, {
            params: Query_Params
        });
        return Transform_Response(Response);
    },

    createApprovalGroup: async (Group_Data) => {
        if (!Group_Data.Company_ID || !Group_Data.Approval_Group_ID || !Group_Data.Approval_Group_Name) {
            throw new API_Error('Company ID, Group ID, and Name are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Creating approval group', { 
            Company_ID: Group_Data.Company_ID, 
            Approval_Group_ID: Group_Data.Approval_Group_ID 
        });
        const Response = await API_Client.post(API_ENDPOINTS.WORKFLOW.APPROVALS, Group_Data);
        return Transform_Response(Response);
    },

    updateApprovalGroup: async (Group_Data) => {
        if (!Group_Data.Company_ID || !Group_Data.Approval_Group_ID) {
            throw new API_Error('Company ID and Group ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Updating approval group', { 
            Company_ID: Group_Data.Company_ID, 
            Approval_Group_ID: Group_Data.Approval_Group_ID 
        });
        const Response = await API_Client.put(`${API_ENDPOINTS.WORKFLOW.APPROVALS}/${Group_Data.Company_ID}`, Group_Data);
        return Transform_Response(Response);
    },

    deleteApprovalGroup: async (Company_ID) => {
        if (!Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
        }

        console.log('Workflow_API: Deleting approval group', { Company_ID });
        const Response = await API_Client.delete(API_ENDPOINTS.WORKFLOW.APPROVALS, {
            params: { Company_ID }
        });
        return Transform_Response(Response);
    },

    // Workflows
    getWorkflows: async (Company_ID = null, Action_Group_ID = null, Options = {}) => {
        console.log('Workflow_API: Getting workflows', { Company_ID, Action_Group_ID, Options });
        const Query_Params = { ...Options };
        if (Company_ID) Query_Params.Company_ID = Company_ID;
        if (Action_Group_ID) Query_Params.Action_Group_ID = Action_Group_ID;
        
        const Response = await API_Client.get(API_ENDPOINTS.WORKFLOW.WORKFLOWS, {
            params: Query_Params
        });
        return Transform_Response(Response);
    },

    createWorkflow: async (Workflow_Data) => {
        if (!Workflow_Data.Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Creating workflow', { Company_ID: Workflow_Data.Company_ID });
        const Response = await API_Client.post(API_ENDPOINTS.WORKFLOW.WORKFLOWS, Workflow_Data);
        return Transform_Response(Response);
    },

    updateWorkflow: async (Workflow_Data) => {
        if (!Workflow_Data.Company_ID || !Workflow_Data.Action_Group_ID) {
            throw new API_Error('Company ID and Action Group ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Updating workflow', { 
            Company_ID: Workflow_Data.Company_ID, 
            Action_Group_ID: Workflow_Data.Action_Group_ID 
        });
        const Response = await API_Client.put(API_ENDPOINTS.WORKFLOW.WORKFLOWS, Workflow_Data);
        return Transform_Response(Response);
    },

    deleteWorkflow: async (Company_ID, Action_Group_ID) => {
        if (!Company_ID || !Action_Group_ID) {
            throw new API_Error('Company ID and Action Group ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Workflow_API: Deleting workflow', { Company_ID, Action_Group_ID });
        const Response = await API_Client.delete(API_ENDPOINTS.WORKFLOW.WORKFLOWS, {
            params: {
                Company_ID,
                Action_Group_ID
            }
        });
        return Transform_Response(Response);
    },

    // Action Configurations
    ...Action_Config_API
};

// Export for backward compatibility
export const workflowApi = Workflow_API;
