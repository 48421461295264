import React, { useState, useEffect, useContext } from 'react';
import { StatusContext } from '../../contexts/Status';
import { workflowApi } from '../../api';
import ModalAddNew from './ModalAddNew';
import ModalEdit from './ModalEdit';

const Actions = () => {
  const [Actions_List, setActionsList] = useState([]);
  const [Is_Add_Modal_Visible, setIsAddModalVisible] = useState(false);
  const [Is_Edit_Modal_Visible, setIsEditModalVisible] = useState(false);
  const [Selected_Action_Data, setSelectedActionData] = useState(null);
  const { Company_ID } = useContext(StatusContext);

  useEffect(() => {
    Fetch_Actions();
  }, [Company_ID]);

  const Fetch_Actions = async () => {
    try {
      const API_Response = await workflowApi.getActions(Company_ID);
      // Handle normalized response format
      const Response_Data = API_Response.Data?.Records || [];
      setActionsList(Response_Data);
    } catch (Error) {
      console.error('Error fetching actions:', Error);
    }
  };

  const Handle_Action_Delete = async (Action_ID) => {
    try {
      await workflowApi.deleteAction(Company_ID, Action_ID);
      await Fetch_Actions();
    } catch (Error) {
      console.error('Error deleting action:', Error);
    }
  };

  const Handle_Action_Edit = (Action_Data) => {
    setSelectedActionData(Action_Data);
    setIsEditModalVisible(true);
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Actions</h1>
        <button
          className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors"
          onClick={() => setIsAddModalVisible(true)}
        >
          Add New Action
        </button>
      </div>

      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Action ID</th>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {Actions_List.map((Action) => (
              <tr key={Action.Action_ID} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="font-medium">{Action.Action_ID}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-left">
                  <div className="flex items-center">
                    <span>{Action.Action_Name}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-left">
                  <div className="flex items-center">
                    <span>{Action.Action_Description}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center">
                    <button
                      className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                      onClick={() => Handle_Action_Edit(Action)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </button>
                    <button
                      className="w-4 mr-2 transform hover:text-red-500 hover:scale-110"
                      onClick={() => Handle_Action_Delete(Action.Action_ID)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {Is_Add_Modal_Visible && (
        <ModalAddNew
          Company_ID={Company_ID}
          On_Close={() => setIsAddModalVisible(false)}
          On_Success={Fetch_Actions}
        />
      )}

      {Is_Edit_Modal_Visible && (
        <ModalEdit
          Action={Selected_Action_Data}
          Company_ID={Company_ID}
          On_Close={() => setIsEditModalVisible(false)}
          On_Success={Fetch_Actions}
        />
      )}
    </div>
  );
};

Actions.Display_Name = 'Actions';

export default Actions;
