import React, { useState, useContext, useEffect } from 'react';
import { StatusContext } from '../../contexts/Status';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import { JobsContext } from '../../contexts/JobsContext';
import { DepartmentContext } from '../../contexts/DepartmentContext';
import { organizationApi } from '../../api';
import HeatMap from '../../components/charts/HeatMap';
import CriticalJobsAnalysis from '../../components/dashboard/CriticalJobsAnalysis';
import { Button, Card } from 'flowbite-react';
import { FiX } from 'react-icons/fi';

const CriticalJobsHeatMap = () => {
    const { Company_ID, Company_Name } = useContext(StatusContext);
    const { Get_Employees_By_Job } = useContext(EmployeeContext);
    const { Job_Map } = useContext(JobsContext);
    const { Department_Map } = useContext(DepartmentContext);
    const [Grid_Heat_Map_Data, setGridHeatMapData] = useState([]);
    const [Critical_Jobs, setCriticalJobs] = useState([]);
    const [Selected_Job, setSelectedJob] = useState(null);
    const [Is_Loading, setIsLoading] = useState(true);
    const [Error_Message, setErrorMessage] = useState(null);

    // Create grid data
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                // Get jobs and critical jobs data
                const [Jobs_Response, Critical_Jobs_Response] = await Promise.all([
                    organizationApi.getJobs(Company_ID),
                    organizationApi.getCriticalJobs(Company_ID)
                ]);

                const Jobs = Jobs_Response?.Data?.Records || [];
                const Critical = Critical_Jobs_Response?.Data?.Records || [];
                setCriticalJobs(Critical);

                // Create grid data
                const Grid_Data = [];
                const Departments = new Set(Object.keys(Department_Map));

                Departments.forEach(Department_ID => {
                    Jobs.forEach(Job => {
                        const Employees = Get_Employees_By_Job(Job.Job_ID).filter(
                            emp => emp.Department_ID === Department_ID
                        );

                        Grid_Data.push({
                            Row_Label: Department_Map[Department_ID] || Department_ID,
                            Column_Label: Job_Map[Job.Job_ID] || Job.Job_ID,
                            Value: Employees.length,
                            Department_ID,
                            Job_ID: Job.Job_ID
                        });
                    });
                });

                setGridHeatMapData(Grid_Data);
                setErrorMessage(null);
            } catch (Error) {
                console.error('Error creating grid data:', Error);
                setErrorMessage(Error.Message || 'Failed to create grid data');
            } finally {
                setIsLoading(false);
            }
        };

        if (Company_ID) {
            fetchData();
        }
    }, [Company_ID, Department_Map, Job_Map, Get_Employees_By_Job]);

    const Handle_Cell_Click = async (Cell_Data) => {
        const Job_Data = Grid_Heat_Map_Data.find(
            item => item.Row_Label === Cell_Data.Row_Label && 
                   item.Column_Label === Cell_Data.Column_Label
        );

        if (Job_Data) {
            setSelectedJob({
                Department_ID: Job_Data.Department_ID,
                Job_ID: Job_Data.Job_ID,
                Is_Critical: Cell_Data.Is_Critical
            });
        }
    };

    const Handle_Critical_Job_Update = async (Job_Data) => {
        try {
            const Department_ID = Job_Data.Department_ID;
            const Job_ID = Job_Data.Job_ID;
            const Is_Currently_Critical = Critical_Jobs.some(
                job => job.Department_ID === Department_ID && job.Job_ID === Job_ID
            );

            if (Is_Currently_Critical) {
                await organizationApi.removeCriticalJob(Company_ID, {
                    Department_ID,
                    Job_ID
                });
                setCriticalJobs(prev => prev.filter(
                    job => !(job.Department_ID === Department_ID && job.Job_ID === Job_ID)
                ));
            } else {
                await organizationApi.addCriticalJob(Company_ID, {
                    Department_ID,
                    Job_ID
                });
                setCriticalJobs(prev => [...prev, { Department_ID, Job_ID }]);
            }
        } catch (Error) {
            console.error('Error updating critical job:', Error);
            setErrorMessage('Failed to update critical job status');
        }
    };

    if (Is_Loading) {
        return <div className="flex justify-center items-center h-64">Loading...</div>;
    }

    if (Error_Message) {
        return <div className="text-red-500">{Error_Message}</div>;
    }

    return (
        <div className="p-4 space-y-6">
            <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold">{Company_Name} - Critical Jobs Analysis</h1>
            </div>

            <Card>
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">Department-Job Distribution</h2>
                        <div className="flex items-center space-x-2">
                            <div className="flex items-center">
                                <span className="inline-block w-4 h-4 border-2 border-red-500 mr-2"></span>
                                <span className="text-sm">Critical Job</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="h-[600px]">
                        <HeatMap
                            Data_List={Grid_Heat_Map_Data}
                            Chart_Width={800}
                            Chart_Height={500}
                            Color_Range={['#f7fbff', '#08519c']}
                            Legend_Title="Employee Count"
                            Critical_Items={Critical_Jobs.map(job => ({
                                Row_Label: Department_Map[job.Department_ID] || job.Department_ID,
                                Column_Label: Job_Map[job.Job_ID] || job.Job_ID
                            }))}
                            Enable_Selection={true}
                            On_Cell_Click={Handle_Cell_Click}
                        />
                    </div>

                    <p className="text-sm text-gray-600 mt-4">
                        Click on a cell to view detailed analysis or toggle critical job status.
                    </p>
                </div>
            </Card>

            {Selected_Job && (
                <div className="relative">
                    <Button
                        color="gray"
                        size="sm"
                        className="absolute right-4 top-4 z-10"
                        onClick={() => setSelectedJob(null)}
                    >
                        <FiX className="h-4 w-4" />
                    </Button>
                    <CriticalJobsAnalysis
                        Company_ID={Company_ID}
                        Department_ID={Selected_Job.Department_ID}
                        Job_ID={Selected_Job.Job_ID}
                    />
                </div>
            )}
        </div>
    );
};

export default CriticalJobsHeatMap;
