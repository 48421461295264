import React from 'react';
import { Card } from 'flowbite-react';

const EmailConfiguration = () => {
  return (
    <Card>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        Email Configuration
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400">
        Email configuration settings will be implemented here.
      </p>
    </Card>
  );
};

export default EmailConfiguration;