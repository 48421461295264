import { CognitoUserPool } from "amazon-cognito-identity-js";

/**
 * AWS Cognito User Pool Configuration
 * @type {Object}
 */
export const Pool_Config = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
};

// Check for required environment variables
if (!process.env.REACT_APP_USER_POOL_ID || !process.env.REACT_APP_CLIENT_ID) {
    console.error("User_Pool: Missing required environment variables", {
        Has_User_Pool_ID: !!process.env.REACT_APP_USER_POOL_ID,
        Has_Client_ID: !!process.env.REACT_APP_CLIENT_ID
    });
    throw new Error("Missing required AWS Cognito configuration");
}

console.log("User_Pool: Initializing Cognito User Pool", {
    User_Pool_ID: Pool_Config.UserPoolId,
    Client_ID: Pool_Config.ClientId
});

/**
 * Cognito User Pool instance
 * @type {CognitoUserPool}
 */
export const User_Pool = new CognitoUserPool(Pool_Config);

// Export User_Pool as default for backward compatibility
export default User_Pool;
