import { API_ENDPOINTS } from '../core/constants';
import { API_Client, API_Error } from '../core/apiClient';

/**
 * Analytics related API calls
 */
export const Analytics_API = {
    // Organization Analytics
    getOrganizationAnalytics: async (Company_ID, Options = {}) => {
        if (!Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
        }

        console.log('Analytics_API: Getting organization analytics', {
            Company_ID,
            Start_Date: Options.Start_Date,
            End_Date: Options.End_Date
        });

        return API_Client.get(API_ENDPOINTS.ANALYTICS.ORG, {
            params: {
                Company_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date,
                ...Options
            }
        });
    },

    // Action Analytics
    getActionAnalytics: async (Company_ID, Options = {}) => {
        if (!Company_ID) {
            throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
        }

        console.log('Analytics_API: Getting action analytics', {
            Company_ID,
            Start_Date: Options.Start_Date,
            End_Date: Options.End_Date
        });

        return API_Client.get(API_ENDPOINTS.ANALYTICS.ACTIONS, {
            params: {
                Company_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date,
                ...Options
            }
        });
    },

    // Attrition Analysis
    getAttritionAnalytics: {
        Company: async (Company_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting company attrition analytics', { 
                Company_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            return API_Client.get(API_ENDPOINTS.ANALYTICS.ATTRITION.COMPANY, {
                params: {
                    Company_ID,
                    ...Options
                }
            });
        },

        Location: async (Company_ID, Work_Location_ID, Options = {}) => {
            if (!Company_ID || !Work_Location_ID) {
                throw new API_Error('Company ID and Work Location ID are required', 'MISSING_REQUIRED_FIELDS');
            }

            console.log('Analytics_API: Getting location attrition analytics', { 
                Company_ID,
                Work_Location_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            return API_Client.get(API_ENDPOINTS.ANALYTICS.ATTRITION.LOCATION, {
                params: {
                    Company_ID,
                    Work_Location_ID,
                    ...Options
                }
            });
        },

        Department: async (Company_ID, Department_ID, Options = {}) => {
            if (!Company_ID || !Department_ID) {
                throw new API_Error('Company ID and Department ID are required', 'MISSING_REQUIRED_FIELDS');
            }

            console.log('Analytics_API: Getting department attrition analytics', { 
                Company_ID,
                Department_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            return API_Client.get(API_ENDPOINTS.ANALYTICS.ATTRITION.DEPARTMENT, {
                params: {
                    Company_ID,
                    Department_ID,
                    ...Options
                }
            });
        },

        Job: async (Company_ID, Job_ID, Options = {}) => {
            if (!Company_ID || !Job_ID) {
                throw new API_Error('Company ID and Job ID are required', 'MISSING_REQUIRED_FIELDS');
            }

            console.log('Analytics_API: Getting job attrition analytics', { 
                Company_ID,
                Job_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            return API_Client.get(API_ENDPOINTS.ANALYTICS.ATTRITION.JOB, {
                params: {
                    Company_ID,
                    Job_ID,
                    ...Options
                }
            });
        }
    },

    // Tenure Analysis
    getTenureAnalytics: {
        Company: async (Company_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting company tenure analytics', { 
                Company_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.TENURE}/co`, {
                params: {
                    Company_ID,
                    ...Options
                }
            });
        },

        Location: async (Company_ID, Work_Location_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting location tenure analytics', { 
                Company_ID,
                Work_Location_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            const Query_Params = Work_Location_ID ? {
                Company_ID,
                Work_Location_ID,
                ...Options
            } : {
                Company_ID,
                ...Options
            };
            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.TENURE}loc`, {
                params: Query_Params
            });
        },

        Department: async (Company_ID, Department_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting department tenure analytics', { 
                Company_ID,
                Department_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            const Query_Params = Department_ID ? {
                Company_ID,
                Department_ID,
                ...Options
            } : {
                Company_ID,
                ...Options
            };
            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.TENURE}dept`, {
                params: Query_Params
            });
        },

        Job: async (Company_ID, Job_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting job tenure analytics', { 
                Company_ID,
                Job_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            const Query_Params = Job_ID ? {
                Company_ID,
                Job_ID,
                ...Options
            } : {
                Company_ID,
                ...Options
            };
            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.TENURE}job`, {
                params: Query_Params
            });
        }
    },

    // Wage Analysis
    getWageAnalytics: {
        Company: async (Company_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting company wage analytics', { 
                Company_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.WAGE}/co`, {
                params: {
                    Company_ID,
                    ...Options
                }
            });
        },

        Location: async (Company_ID, Work_Location_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting location wage analytics', { 
                Company_ID,
                Work_Location_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            const Query_Params = Work_Location_ID ? {
                Company_ID,
                Work_Location_ID,
                ...Options
            } : {
                Company_ID,
                ...Options
            };
            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.WAGE}loc`, {
                params: Query_Params
            });
        },

        Department: async (Company_ID, Department_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting department wage analytics', { 
                Company_ID,
                Department_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            const Query_Params = Department_ID ? {
                Company_ID,
                Department_ID,
                ...Options
            } : {
                Company_ID,
                ...Options
            };
            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.WAGE}dept`, {
                params: Query_Params
            });
        },

        Job: async (Company_ID, Job_ID, Options = {}) => {
            if (!Company_ID) {
                throw new API_Error('Company ID is required', 'MISSING_COMPANY_ID');
            }

            console.log('Analytics_API: Getting job wage analytics', { 
                Company_ID,
                Job_ID,
                Start_Date: Options.Start_Date,
                End_Date: Options.End_Date
            });

            const Query_Params = Job_ID ? {
                Company_ID,
                Job_ID,
                ...Options
            } : {
                Company_ID,
                ...Options
            };
            return API_Client.get(`${API_ENDPOINTS.ANALYTICS.WAGE}job`, {
                params: Query_Params
            });
        }
    }
};

// Export for backward compatibility
export const analyticsApi = Analytics_API;
