import React, { useState, useEffect } from "react";
import { Modal, Button, Label, TextInput, Select } from "flowbite-react";

const ClientModal = ({ show, onClose, client, onSave }) => {
  const [clientData, setClientData] = useState({
    Client_ID: "",
    Client_Name: "",
    Client_Type: "",
    Client_Status: "Active",  // Default to Active
    Create_Date: new Date().toISOString(),
    Last_Updated: new Date().toISOString()
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (client) {
      setClientData(client);
    } else {
      setClientData({
        Client_ID: "",
        Client_Name: "",
        Client_Type: "",
        Client_Status: "Active",
        Create_Date: new Date().toISOString(),
        Last_Updated: new Date().toISOString()
      });
    }
    setError(null);
  }, [client]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
      Last_Updated: new Date().toISOString()
    }));
  };

  const validateForm = () => {
    if (!clientData.Client_ID) {
      setError("Client ID is required");
      return false;
    }
    if (!clientData.Client_Name) {
      setError("Client Name is required");
      return false;
    }
    if (!clientData.Client_Type) {
      setError("Client Type is required");
      return false;
    }
    if (!clientData.Client_Status) {
      setError("Client Status is required");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSave(clientData);
    }
  };

  const clientTypes = [
    { value: "Enterprise", label: "Enterprise" },
    { value: "Corporate", label: "Corporate" },
    { value: "Small Business", label: "Small Business" },
    { value: "Government", label: "Government" },
    { value: "Non-Profit", label: "Non-Profit" }
  ];

  const clientStatuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Pending", label: "Pending" },
    { value: "Suspended", label: "Suspended" }
  ];

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>{client ? "Edit Client" : "Add Client"}</Modal.Header>
      <Modal.Body>
        {error && (
          <div className="mb-4 text-sm text-red-600 bg-red-100 p-2 rounded">
            {error}
          </div>
        )}
        <div className="space-y-4">
          <div>
            <Label htmlFor="clientId">Client ID</Label>
            <TextInput
              id="clientId"
              name="Client_ID"
              type="text"
              value={clientData.Client_ID}
              onChange={handleChange}
              required
              placeholder="Enter client ID"
              disabled={!!client}  // Disable editing for existing clients
            />
          </div>
          <div>
            <Label htmlFor="clientName">Client Name</Label>
            <TextInput
              id="clientName"
              name="Client_Name"
              type="text"
              value={clientData.Client_Name}
              onChange={handleChange}
              required
              placeholder="Enter client name"
            />
          </div>
          <div>
            <Label htmlFor="clientType">Client Type</Label>
            <Select
              id="clientType"
              name="Client_Type"
              value={clientData.Client_Type}
              onChange={handleChange}
              required
            >
              <option value="">Select a type</option>
              {clientTypes.map(type => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Label htmlFor="clientStatus">Client Status</Label>
            <Select
              id="clientStatus"
              name="Client_Status"
              value={clientData.Client_Status}
              onChange={handleChange}
              required
            >
              {clientStatuses.map(status => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          color="blue" 
          onClick={handleSubmit}
          disabled={!clientData.Client_ID || !clientData.Client_Name || !clientData.Client_Type}
        >
          {client ? "Update" : "Add"}
        </Button>
        <Button color="gray" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ClientModal);
