import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WaffleChart = ({
  Data_List,
  Chart_Width = 300,
  Chart_Height = 300,
  Row_Count = 10,
  Column_Count = 10,
  Color_Scheme = d3.schemeCategory10,
  Cell_Padding = 2,
  Is_Animated = true
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 100, Bottom: 40, Left: 40 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Waffle Chart")
      .append("g")
      .attr("transform", `translate(${Margin.Left},${Margin.Top})`);

    // Calculate total and percentages
    const Total_Value = d3.sum(Data_List, Item => Item.Value);
    const Enriched_Data = Data_List.map(Item => ({
      ...Item,
      Percentage: (Item.Value / Total_Value) * 100,
      Square_Count: Math.round((Item.Value / Total_Value) * (Row_Count * Column_Count))
    }));

    // Create color scale
    const Color_Scale = d3.scaleOrdinal()
      .domain(Data_List.map(Item => Item.Category))
      .range(Color_Scheme);

    // Calculate square size
    const Square_Size = Math.min(
      Inner_Width / Column_Count,
      Inner_Height / Row_Count
    ) - Cell_Padding;

    // Create squares data
    let Squares_Data = [];
    let Current_Category = 0;
    let Squares_Left = Enriched_Data[Current_Category].Square_Count;

    for (let Row = 0; Row < Row_Count; Row++) {
      for (let Col = 0; Col < Column_Count; Col++) {
        if (Squares_Left === 0 && Current_Category < Enriched_Data.length - 1) {
          Current_Category++;
          Squares_Left = Enriched_Data[Current_Category].Square_Count;
        }

        if (Squares_Left > 0) {
          Squares_Data.push({
            Row,
            Col,
            Category: Enriched_Data[Current_Category].Category,
            Color: Color_Scale(Enriched_Data[Current_Category].Category)
          });
          Squares_Left--;
        }
      }
    }

    // Add squares
    const Squares = SVG.selectAll(".square")
      .data(Squares_Data)
      .enter()
      .append("rect")
      .attr("class", "square")
      .attr("x", Item => Item.Col * (Square_Size + Cell_Padding))
      .attr("y", Item => Item.Row * (Square_Size + Cell_Padding))
      .attr("width", Square_Size)
      .attr("height", Square_Size)
      .attr("fill", Item => Item.Color)
      .attr("opacity", 0.7)
      .attr("stroke", "#fff")
      .attr("stroke-width", 1);

    if (Is_Animated) {
      Squares.attr("opacity", 0)
        .transition()
        .delay((Item, Index) => Index * 10)
        .duration(500)
        .attr("opacity", 0.7);
    }

    // Add legend
    const Legend = SVG.append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${Inner_Width + 20}, 0)`);

    Enriched_Data.forEach((Item, Index) => {
      const Legend_Item = Legend.append("g")
        .attr("transform", `translate(0, ${Index * 25})`);

      Legend_Item.append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", Color_Scale(Item.Category))
        .attr("opacity", 0.7)
        .attr("stroke", "#fff")
        .attr("stroke-width", 1);

      Legend_Item.append("text")
        .attr("x", 25)
        .attr("y", 12)
        .style("font-size", "12px")
        .text(`${Item.Category} (${Item.Percentage.toFixed(1)}%)`);
    });

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Squares.on("mouseover", (Event, Item) => {
      const Category_Data = Enriched_Data.find(Data_Item => Data_Item.Category === Item.Category);
      
      Tooltip.transition()
        .duration(200)
        .style("opacity", 0.9);
      Tooltip.html(`
        <strong>${Item.Category}</strong><br/>
        Value: ${Category_Data.Value}<br/>
        Percentage: ${Category_Data.Percentage.toFixed(1)}%
      `)
        .style("left", `${Event.pageX}px`)
        .style("top", `${Event.pageY - 28}px`);

      d3.select(Event.target)
        .transition()
        .duration(200)
        .attr("opacity", 1);
    })
    .on("mouseout", (Event) => {
      Tooltip.transition()
        .duration(500)
        .style("opacity", 0);

      d3.select(Event.target)
        .transition()
        .duration(200)
        .attr("opacity", 0.7);
    });

  }, [Data_List, Chart_Width, Chart_Height, Row_Count, Column_Count, Color_Scheme, Cell_Padding, Is_Animated]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

WaffleChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Category: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Row_Count: PropTypes.number,
  Column_Count: PropTypes.number,
  Color_Scheme: PropTypes.arrayOf(PropTypes.string),
  Cell_Padding: PropTypes.number,
  Is_Animated: PropTypes.bool
};

WaffleChart.Display_Name = 'WaffleChart';

export default WaffleChart;
