import React from "react";

const Spinner = () => {
  return (
    <div className="bg-[#F2F2F2] flex justify-center items-center h-screen">
      <div
        className="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r
    from-indigo-600
    to-blue-400"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-[#F2F2F2] rounded-full border-2 border-white"></div>
      </div>
    </div>
  );
};

Spinner.Display_Name = 'Spinner';

export default Spinner;
