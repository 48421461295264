import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const DonutChart = ({ Data_List, Chart_Width = 300, Chart_Height = 300, Color_Scheme = d3.schemeCategory10 }) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_List || Data_List.length === 0) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Chart_Radius = Math.min(Chart_Width, Chart_Height) / 2;
    const Arc_Generator = d3.arc()
      .innerRadius(Chart_Radius * 0.6)
      .outerRadius(Chart_Radius);

    const Pie_Generator = d3.pie()
      .value(Item => Item.Value)
      .sort(null);

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Donut Chart")
      .append("g")
      .attr("transform", `translate(${Chart_Width / 2},${Chart_Height / 2})`);

    // Create color scale
    const Color_Scale = d3.scaleOrdinal()
      .domain(Data_List.map(Item => Item.Label))
      .range(Color_Scheme);

    // Add slices
    const Slices = SVG.selectAll("path")
      .data(Pie_Generator(Data_List))
      .enter()
      .append("path")
      .attr("d", Arc_Generator)
      .attr("fill", Item => Color_Scale(Item.data.Label))
      .attr("stroke", "white")
      .attr("stroke-width", 2)
      .attr("aria-label", Item => `${Item.data.Label}: ${Item.data.Value}`);

    // Add labels
    const Label_Arc = d3.arc()
      .innerRadius(Chart_Radius * 0.8)
      .outerRadius(Chart_Radius * 0.8);

    const Labels = SVG.selectAll("text")
      .data(Pie_Generator(Data_List))
      .enter()
      .append("text")
      .attr("transform", Item => `translate(${Label_Arc.centroid(Item)})`)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text(Item => `${Item.data.Label}: ${Item.data.Value}`);

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Slices
      .on("mouseover", (Event, Item) => {
        Tooltip.transition()
          .duration(200)
          .style("opacity", 0.9);
        Tooltip.html(`
          <strong>${Item.data.Label}</strong><br/>
          Value: ${Item.data.Value}<br/>
          Percentage: ${(Item.value * 100 / d3.sum(Data_List, D => D.Value)).toFixed(1)}%
        `)
          .style("left", `${Event.pageX}px`)
          .style("top", `${Event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        Tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // Add legend
    const Legend = SVG.selectAll(".legend")
      .data(Data_List)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (Item, Index) => `translate(${Chart_Radius + 10},${Index * 20 - Chart_Radius + 10})`);

    Legend.append("rect")
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", Item => Color_Scale(Item.Label));

    Legend.append("text")
      .attr("x", 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .text(Item => Item.Label);

  }, [Data_List, Chart_Width, Chart_Height, Color_Scheme]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

DonutChart.propTypes = {
  Data_List: PropTypes.arrayOf(PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired
  })).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Scheme: PropTypes.arrayOf(PropTypes.string)
};

DonutChart.Display_Name = 'DonutChart';

export default DonutChart;
