import React, { useContext } from 'react';
import { useCompanyChartData } from '../../contexts/CompanyChartDataContext';
import { useCompanyData } from '../../contexts/CompanyContext';
import { Card } from 'flowbite-react';
import D3StatisticsChart from '../../components/Components/Dashboard/D3StatisticsChart';
import D3OrgChart from '../../components/Components/Dashboard/D3OrgChart';
import CompanyStatsWidget from '../../components/dashboard/CompanyStatsWidget';
import ActionMetricsWidget from '../../components/dashboard/ActionMetricsWidget';

const AdminDashboard = () => {
  const { Chart_Data = {}, Is_Loading: Is_Chart_Loading, Error: Chart_Error } = useCompanyChartData() || {};
  const { 
    Company_Data, 
    Company_Metrics,
    Get_Latest_Metrics,
    Is_Loading: Is_Org_Loading, 
    Error: Org_Error 
  } = useCompanyData();

  // Debug log
  console.log('AdminDashboard - Initializing:', {
    Has_Chart_Data: !!Chart_Data,
    Has_Company_Data: !!Company_Data,
    Is_Chart_Loading,
    Is_Org_Loading,
    Chart_Error,
    Org_Error
  });

  const Latest_Metrics = Get_Latest_Metrics();

  const Default_Chart_Height = 350;
  const Chart_Colors = {
    Risk: ['#3b82f6', '#ef4444', '#22c55e'],
    Attrition: ['#ef4444', '#f59e0b', '#3b82f6', '#22c55e', '#8b5cf6'],
    Tenure: ['#3b82f6', '#f59e0b', '#22c55e', '#8b5cf6'],
    Wage: ['#3b82f6', '#f59e0b', '#22c55e', '#8b5cf6']
  };

  // Transform organization data for the org chart
  const Org_Chart_Data = Company_Data?.Structure ? {
    Organization_ID: Company_Data.Organization_ID,
    Organization_Name: Company_Data.Organization_Name,
    Organization_Level: 1,
    Power_Score: Company_Data.Structure[0]?.Power_Score,
    Power_Level: Company_Data.Structure[0]?.Power_Level,
    Direct_Reports: Company_Data.Structure[0]?.Direct_Reports,
    Total_Reports: Latest_Metrics?.Count_Total || Company_Data.Structure[0]?.Total_Reports,
    Children: Company_Data.Structure
      .filter(Org => Org.Parent_Organization_ID === Company_Data.Organization_ID)
      .map(Org => ({
        Organization_ID: Org.Organization_ID,
        Organization_Name: Org.Organization_Name,
        Organization_Level: Org.Organization_Level,
        Power_Score: Org.Power_Score,
        Power_Level: Org.Power_Level,
        Direct_Reports: Org.Direct_Reports,
        Total_Reports: Org.Total_Reports,
        Children: Company_Data.Structure
          .filter(Child => Child.Parent_Organization_ID === Org.Organization_ID)
          .map(Child => ({
            Organization_ID: Child.Organization_ID,
            Organization_Name: Child.Organization_Name,
            Organization_Level: Child.Organization_Level,
            Power_Score: Child.Power_Score,
            Power_Level: Child.Power_Level,
            Direct_Reports: Child.Direct_Reports,
            Total_Reports: Child.Total_Reports
          }))
      }))
  } : null;

  // Debug log data transformation
  console.log('AdminDashboard - Data prepared:', {
    Has_Latest_Metrics: !!Latest_Metrics,
    Has_Org_Chart_Data: !!Org_Chart_Data,
    Metrics_Count: Company_Metrics?.length
  });

  if (Is_Chart_Loading || Is_Org_Loading) {
    return (
      <div className="space-y-8">
        <h1 className="text-3xl font-bold mb-8">Analytics Overview</h1>
        <Card>
          <div className="flex items-center justify-center h-[500px]">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>
        </Card>
      </div>
    );
  }

  if (Chart_Error || Org_Error) {
    return (
      <div className="space-y-8">
        <h1 className="text-3xl font-bold mb-8">Analytics Overview</h1>
        <Card className="bg-red-50">
          <div className="text-red-600 p-4">
            {Chart_Error || Org_Error}
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold mb-8">Analytics Overview</h1>
      
      {/* Overview Widgets */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <CompanyStatsWidget />
        <ActionMetricsWidget />
      </div>
      
      {/* Organization Chart Section */}
      <div className="space-y-6">
        {Org_Chart_Data && (
          <D3OrgChart
            Data={Org_Chart_Data}
            Title="Organization Structure"
            Description="Hierarchical view of the organization with power metrics"
            Height={500}
          />
        )}
      </div>

      {/* Charts Section */}
      <div className="space-y-6">
        {/* Risk Analysis Chart */}
        {Chart_Data?.Risk_Chart_Data && (
          <D3StatisticsChart
            Chart={{
              Options: {
                Chart: { Type: 'line', Height: Default_Chart_Height },
                X_Axis: { Categories: Chart_Data.Risk_Chart_Data.Labels }
              },
              Series: Chart_Data.Risk_Chart_Data.Series
            }}
            Title="Risk Analysis"
            Description="Risk trends and distribution over time"
            Custom_Colors={Chart_Colors.Risk}
            Height={Default_Chart_Height}
          />
        )}

        {/* Employee Distribution Chart */}
        {Chart_Data?.Gender_Chart_Data && (
          <D3StatisticsChart
            Chart={{
              Options: {
                Chart: { Type: 'line', Height: Default_Chart_Height },
                X_Axis: { Categories: Chart_Data.Gender_Chart_Data.Labels }
              },
              Series: Chart_Data.Gender_Chart_Data.Series
            }}
            Title="Employee Distribution"
            Description="Employee demographics over time"
            Custom_Colors={Chart_Colors.Attrition}
            Height={Default_Chart_Height}
          />
        )}
      </div>
    </div>
  );
};

AdminDashboard.Display_Name = 'AdminDashboard';

export default AdminDashboard;
