import React, { useState } from 'react';
import { Button, Modal, Spinner, Alert } from 'flowbite-react';
import { FiPlus } from 'react-icons/fi';
import { useWorkflowManagement } from '../../hooks/useWorkflowManagement';
import WorkflowCard from '../../components/workflow/WorkflowCard';

const WorkflowForm = ({ Workflow_Data = {}, On_Submit, On_Cancel }) => {
  const [Form_State, setFormState] = useState({
    Workflow_Name: Workflow_Data.Workflow_Name || '',
    Workflow_Description: Workflow_Data.Workflow_Description || '',
    Approvers_List: Workflow_Data.Approvers || [''],
    Action_Type: Workflow_Data.Action_Type || ''
  });

  const Handle_Form_Submit = (Event) => {
    Event.preventDefault();
    On_Submit(Form_State);
  };

  const Add_Approver = () => {
    setFormState(Previous_State => ({
      ...Previous_State,
      Approvers_List: [...Previous_State.Approvers_List, '']
    }));
  };

  const Remove_Approver = (Index) => {
    setFormState(Previous_State => ({
      ...Previous_State,
      Approvers_List: Previous_State.Approvers_List.filter((_, Item_Index) => Item_Index !== Index)
    }));
  };

  const Update_Approver = (Index, New_Value) => {
    setFormState(Previous_State => ({
      ...Previous_State,
      Approvers_List: Previous_State.Approvers_List.map((Approver, Item_Index) => 
        Item_Index === Index ? New_Value : Approver
      )
    }));
  };

  return (
    <form onSubmit={Handle_Form_Submit} className="space-y-4">
      <div>
        <label className="block mb-2">Workflow Name</label>
        <input
          type="text"
          value={Form_State.Workflow_Name}
          onChange={(Event) => setFormState(Previous_State => ({ ...Previous_State, Workflow_Name: Event.target.value }))}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      
      <div>
        <label className="block mb-2">Description</label>
        <textarea
          value={Form_State.Workflow_Description}
          onChange={(Event) => setFormState(Previous_State => ({ ...Previous_State, Workflow_Description: Event.target.value }))}
          className="w-full p-2 border rounded"
          rows={3}
        />
      </div>

      <div>
        <label className="block mb-2">Action Type</label>
        <input
          type="text"
          value={Form_State.Action_Type}
          onChange={(Event) => setFormState(Previous_State => ({ ...Previous_State, Action_Type: Event.target.value }))}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div>
        <label className="block mb-2">Approvers</label>
        {Form_State.Approvers_List.map((Approver, Index) => (
          <div key={Index} className="flex gap-2 mb-2">
            <input
              type="text"
              value={Approver}
              onChange={(Event) => Update_Approver(Index, Event.target.value)}
              className="flex-1 p-2 border rounded"
              placeholder={`Approver ${Index + 1}`}
              required
            />
            {Form_State.Approvers_List.length > 1 && (
              <Button color="failure" size="sm" onClick={() => Remove_Approver(Index)}>
                Remove
              </Button>
            )}
          </div>
        ))}
        <Button type="button" onClick={Add_Approver} size="sm">
          Add Approver
        </Button>
      </div>

      <div className="flex justify-end gap-2">
        <Button color="gray" onClick={On_Cancel}>
          Cancel
        </Button>
        <Button type="submit" color="blue">
          Save Workflow
        </Button>
      </div>
    </form>
  );
};

const WorkflowManagement = () => {
  const { Workflows, Is_Loading, Error_Message, Create_Workflow, Update_Workflow, Delete_Workflow } = useWorkflowManagement();
  const [Is_Modal_Visible, setIsModalVisible] = useState(false);
  const [Editing_Workflow_Data, setEditingWorkflowData] = useState(null);

  const Handle_Form_Submit = async (Form_Data) => {
    if (Editing_Workflow_Data) {
      await Update_Workflow(Editing_Workflow_Data.Workflow_ID, {
        ...Editing_Workflow_Data,
        ...Form_Data
      });
    } else {
      await Create_Workflow(Form_Data);
    }
    setIsModalVisible(false);
    setEditingWorkflowData(null);
  };

  const Handle_Edit = (Workflow_Data) => {
    setEditingWorkflowData(Workflow_Data);
    setIsModalVisible(true);
  };

  const Handle_Delete = async (Workflow_Data) => {
    if (window.confirm('Are you sure you want to delete this workflow?')) {
      await Delete_Workflow(Workflow_Data.Workflow_ID);
    }
  };

  const Handle_Steps_Reorder = async (Workflow_ID, New_Steps_Order) => {
    const Workflow = Workflows.find(W => W.Workflow_ID === Workflow_ID);
    if (Workflow) {
      const Updated_Workflow = {
        ...Workflow,
        Approvers: New_Steps_Order
      };
      await Update_Workflow(Workflow_ID, Updated_Workflow);
    }
  };

  if (Is_Loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spinner size="xl" />
      </div>
    );
  }

  if (Error_Message) {
    return (
      <Alert color="failure">
        Error loading workflows: {Error_Message}
      </Alert>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Workflow Management</h2>
        <Button onClick={() => setIsModalVisible(true)}>
          <FiPlus className="mr-2" /> Add Workflow
        </Button>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {Workflows && Workflows.map(Workflow => (
          <WorkflowCard
            key={Workflow.Workflow_ID}
            Workflow={Workflow}
            On_Edit={Handle_Edit}
            On_Delete={Handle_Delete}
            On_Steps_Reorder={Handle_Steps_Reorder}
          />
        ))}
      </div>

      <Modal
        show={Is_Modal_Visible}
        onClose={() => {
          setIsModalVisible(false);
          setEditingWorkflowData(null);
        }}
      >
        <Modal.Header>
          {Editing_Workflow_Data ? 'Edit Workflow' : 'Create New Workflow'}
        </Modal.Header>
        <Modal.Body>
          <WorkflowForm
            Workflow_Data={Editing_Workflow_Data}
            On_Submit={Handle_Form_Submit}
            On_Cancel={() => {
              setIsModalVisible(false);
              setEditingWorkflowData(null);
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

WorkflowManagement.Display_Name = 'WorkflowManagement';
WorkflowForm.Display_Name = 'WorkflowForm';

export default WorkflowManagement;
