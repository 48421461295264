import React, { createContext, useState, useContext, useEffect, useMemo, useCallback} from 'react';
import { organizationApi } from '../api';
import { StatusContext } from './Status';

const CompanyDataContext = createContext();

// Rest of the file remains the same, just replace Structure_API with organizationApi
const processMetricsData = (Metrics_Data) => {
    if (!Array.isArray(Metrics_Data)) {
        console.log('CompanyContext - Invalid metrics data:', Metrics_Data);
        return [];
    }
    
    // Debug log
    console.log('CompanyContext - Raw metrics:', {
        Count: Metrics_Data.length,
        Sample: Metrics_Data[0],
        Sample_Keys: Metrics_Data[0] ? Object.keys(Metrics_Data[0]) : []
    });
    
    // Transform metrics to expected format
    const Processed_Data = Metrics_Data.map(metric => ({
        Period_Date: metric.Period_Date || new Date().toISOString(),
        Count_Total: metric.EmployeeCount || 0,
        Total_Risk: metric.RiskScore || 0,
        Total_Risk_Critical: metric.CriticalRiskCount || 0,
        Count_Red: metric.HighRiskCount || 0,
        Count_Yellow: metric.MediumRiskCount || 0,
        Count_Male: metric.MaleCount || 0,
        Count_Female: metric.FemaleCount || 0
    })).sort((a, b) => new Date(a.Period_Date) - new Date(b.Period_Date));
    
    // Debug log
    console.log('CompanyContext - Processed metrics:', {
        Count: Processed_Data.length,
        Sample: Processed_Data[0]
    });
    
    return Processed_Data;
};

export const CompanyDataProvider = ({ children }) => {
    const { Company_ID } = useContext(StatusContext);
    const [State, setState] = useState({
        Company_Data: null,
        Company_Metrics: [],
        Is_Loading: true,
        Error: null,
        Meta: null
    });

    const fetchData = useCallback(async () => {
        if (!Company_ID) {
            console.log('CompanyContext - No Company_ID provided');
            setState(Prev_State => ({
                ...Prev_State,
                Is_Loading: false,
                Company_Data: null,
                Company_Metrics: [],
                Meta: null
            }));
            return;
        }

        setState(Prev_State => ({ ...Prev_State, Is_Loading: true, Error: null }));

        try {
            console.log('CompanyContext - Fetching data for company:', Company_ID);
            
            const Response = await organizationApi.getOrganization(Company_ID);

            // Debug log
            console.log('CompanyContext - API response:', Response);

            // Extract organization data from the normalized response
            const Organization_Data = Response.Data || null;
            const Meta_Data = Response.Meta || null;

            // For now, generate sample metrics data
            const Sample_Metrics = [
                {
                    Period_Date: new Date().toISOString(),
                    EmployeeCount: 100,
                    RiskScore: 75,
                    CriticalRiskCount: 5,
                    HighRiskCount: 10,
                    MediumRiskCount: 20,
                    MaleCount: 60,
                    FemaleCount: 40
                }
            ];
            
            const Processed_Metrics = processMetricsData(Sample_Metrics);
            
            setState({
                Company_Data: Organization_Data,
                Company_Metrics: Processed_Metrics,
                Is_Loading: false,
                Error: null,
                Meta: Meta_Data
            });
        } catch (Error) {
            console.error('CompanyContext - Error fetching data:', Error);
            setState(Prev_State => ({
                ...Prev_State,
                Is_Loading: false,
                Error: Error.message || 'An error occurred while fetching data',
                Company_Data: null,
                Company_Metrics: [],
                Meta: Error.details?.meta || null
            }));
        }
    }, [Company_ID]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const Context_Value = useMemo(() => ({
        ...State,
        Refetch_Data: fetchData,
        Get_Latest_Metrics: () => State.Company_Metrics[State.Company_Metrics.length - 1] || null,
        Get_Metrics_By_Date: (Period_Date) => State.Company_Metrics.find(m => m.Period_Date === Period_Date),
        Get_Metrics_Time_Series: () => ({
            Period_Date: State.Company_Metrics.map(m => m.Period_Date),
            Total_Risk: State.Company_Metrics.map(m => m.Total_Risk),
            Total_Risk_Critical: State.Company_Metrics.map(m => m.Total_Risk_Critical),
            Count_Total: State.Company_Metrics.map(m => m.Count_Total),
            Count_Red: State.Company_Metrics.map(m => m.Count_Red),
            Count_Yellow: State.Company_Metrics.map(m => m.Count_Yellow),
            Count_Male: State.Company_Metrics.map(m => m.Count_Male),
            Count_Female: State.Company_Metrics.map(m => m.Count_Female)
        })
    }), [State, fetchData]);

    return (
        <CompanyDataContext.Provider value={Context_Value}>
            {children}
        </CompanyDataContext.Provider>
    );
};

export const useCompanyData = () => {
    const Context = useContext(CompanyDataContext);
    if (!Context) {
        throw new Error('useCompanyData must be used within a CompanyDataProvider');
    }
    return Context;
};

export const useCompanyDataLoading = () => {
    const { Is_Loading } = useCompanyData();
    return Is_Loading;
};
