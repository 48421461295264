import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const TrafficLightChart = ({
  Data_Values,
  Chart_Width = 300,
  Chart_Height = 300,
  Color_Scheme = {
    Red: '#ef4444',
    Yellow: '#f59e0b',
    Green: '#22c55e'
  },
  Show_Labels = true,
  Show_Percentages = true,
  Is_Animated = true
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (!Data_Values) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 40, Bottom: 40, Left: 40 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;
    const Light_Radius = Math.min(Inner_Width, Inner_Height) / 6;
    const Light_Spacing = Light_Radius * 0.5;

    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", "Traffic Light Chart")
      .append("g")
      .attr("transform", `translate(${Chart_Width / 2},${Margin.Top})`);

    // Calculate total for percentages
    const Total_Value = Data_Values.Red + Data_Values.Yellow + Data_Values.Green;

    // Create lights group
    const Lights_Group = SVG.append("g")
      .attr("class", "lights");

    // Helper function to create light
    const Create_Light = (Color_Name, Value, Y_Position) => {
      const Light_Group = Lights_Group.append("g")
        .attr("transform", `translate(0,${Y_Position})`);

      // Add outer circle (border)
      Light_Group.append("circle")
        .attr("class", `${Color_Name}-light-border`)
        .attr("r", Light_Radius + 2)
        .attr("fill", "none")
        .attr("stroke", "#374151")
        .attr("stroke-width", 2);

      // Add light circle
      const Light = Light_Group.append("circle")
        .attr("class", `${Color_Name}-light`)
        .attr("r", Light_Radius)
        .attr("fill", Color_Scheme[Color_Name])
        .attr("opacity", 0.3);

      if (Is_Animated) {
        Light.transition()
          .duration(1000)
          .attr("opacity", Value > 0 ? 1 : 0.3);
      } else {
        Light.attr("opacity", Value > 0 ? 1 : 0.3);
      }

      // Add label if enabled
      if (Show_Labels) {
        Light_Group.append("text")
          .attr("class", `${Color_Name}-label`)
          .attr("x", Light_Radius * 1.5)
          .attr("y", 0)
          .attr("dy", "0.35em")
          .attr("text-anchor", "start")
          .style("font-size", "14px")
          .text(`${Color_Name}: ${Value}`);
      }

      // Add percentage if enabled
      if (Show_Percentages) {
        Light_Group.append("text")
          .attr("class", `${Color_Name}-percentage`)
          .attr("x", -Light_Radius * 1.5)
          .attr("y", 0)
          .attr("dy", "0.35em")
          .attr("text-anchor", "end")
          .style("font-size", "14px")
          .text(`${((Value / Total_Value) * 100).toFixed(1)}%`);
      }

      return Light_Group;
    };

    // Create lights
    Create_Light("Red", Data_Values.Red, 0);
    Create_Light("Yellow", Data_Values.Yellow, (Light_Radius * 2) + Light_Spacing);
    Create_Light("Green", Data_Values.Green, (Light_Radius * 4) + Light_Spacing * 2);

    // Add tooltips
    const Tooltip = d3.select(SVG_Ref.current.parentNode)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    Lights_Group.selectAll("circle")
      .on("mouseover", (Event, Item) => {
        const Color_Name = Event.target.classList[0].split("-")[0];
        const Value = Data_Values[Color_Name];
        
        Tooltip.transition()
          .duration(200)
          .style("opacity", 0.9);
        Tooltip.html(`
          <strong>${Color_Name}</strong><br/>
          Count: ${Value}<br/>
          Percentage: ${((Value / Total_Value) * 100).toFixed(1)}%
        `)
          .style("left", `${Event.pageX}px`)
          .style("top", `${Event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        Tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }, [Data_Values, Chart_Width, Chart_Height, Color_Scheme, Show_Labels, Show_Percentages, Is_Animated]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

TrafficLightChart.propTypes = {
  Data_Values: PropTypes.shape({
    Red: PropTypes.number.isRequired,
    Yellow: PropTypes.number.isRequired,
    Green: PropTypes.number.isRequired
  }).isRequired,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Scheme: PropTypes.shape({
    Red: PropTypes.string,
    Yellow: PropTypes.string,
    Green: PropTypes.string
  }),
  Show_Labels: PropTypes.bool,
  Show_Percentages: PropTypes.bool,
  Is_Animated: PropTypes.bool
};

TrafficLightChart.Display_Name = 'TrafficLightChart';

export default TrafficLightChart;
