import { API_Client, API_Error } from '../core/apiClient';
import { API_ENDPOINTS } from '../core/constants';

export const Users_API = {
    // User operations
    getUsers: async (Query_Params = {}) => {
        console.log('Users_API: Getting users', Query_Params);
        if (Query_Params.Email_Address) {
            console.log('Users_API: Getting user by email', { Email: Query_Params.Email_Address });
        }
        return API_Client.get(API_ENDPOINTS.USERS.BASE, { params: Query_Params });
    },

    createUser: async (User_Data) => {
        if (!User_Data.Email_Address || !User_Data.Client_ID) {
            throw new API_Error('Email address and Client ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Creating user', { 
            Email: User_Data.Email_Address,
            Client_ID: User_Data.Client_ID
        });
        return API_Client.post(API_ENDPOINTS.USERS.BASE, User_Data);
    },

    updateUser: async (User_ID, User_Data) => {
        if (!User_ID || !User_Data.Client_ID) {
            throw new API_Error('User ID and Client ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Updating user', { 
            User_ID,
            Client_ID: User_Data.Client_ID
        });
        return API_Client.put(`${API_ENDPOINTS.USERS.BASE}/${User_ID}`, User_Data);
    },

    deleteUser: async (User_ID, Client_ID) => {
        if (!User_ID || !Client_ID) {
            throw new API_Error('User ID and Client ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Deleting user', { User_ID, Client_ID });
        return API_Client.delete(`${API_ENDPOINTS.USERS.BASE}/${User_ID}`, {
            params: { Client_ID }
        });
    },

    // User Entitlements
    getUserEntitlements: async (Email_Address, Client_ID) => {
        if (!Email_Address || !Client_ID) {
            throw new API_Error('Email address and Client ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Getting user entitlements', { 
            Email: Email_Address,
            Client_ID
        });
        return API_Client.get(API_ENDPOINTS.USERS.ENTITLEMENTS, {
            params: { Email_Address, Client_ID }
        });
    },

    getUsersEntitlements: async (Client_ID, Email_Address = null) => {
        if (!Client_ID) {
            throw new API_Error('Client ID is required', 'MISSING_CLIENT_ID');
        }

        console.log('Users_API: Getting users entitlements', { 
            Client_ID,
            Email_Address 
        });
        const Query_Params = { Client_ID };
        if (Email_Address) {
            Query_Params.Email_Address = Email_Address;
        }
        return API_Client.get(API_ENDPOINTS.USERS.ENTITLEMENTS, { params: Query_Params });
    },

    getUsersEntitleByClient: async (Client_ID) => {
        if (!Client_ID) {
            throw new API_Error('Client ID is required', 'MISSING_CLIENT_ID');
        }

        console.log('Users_API: Getting users entitlements by client', { Client_ID });
        return API_Client.get(API_ENDPOINTS.USERS.ENTITLEMENTS, { params: { Client_ID } });
    },

    createUserEntitlement: async (User_Data) => {
        if (!User_Data.Email_Address || !User_Data.Role_ID || !User_Data.Client_ID) {
            throw new API_Error('Email address, Role ID, and Client ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Creating user entitlement', {
            Email: User_Data.Email_Address,
            Role: User_Data.Role_ID,
            Client_ID: User_Data.Client_ID
        });
        return API_Client.post(API_ENDPOINTS.USERS.ENTITLEMENTS, User_Data);
    },

    updateUserEntitlement: async (Updated_User) => {
        if (!Updated_User.Email_Address || !Updated_User.Client_ID) {
            throw new API_Error('Email address and Client ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Updating user entitlement', { 
            Email: Updated_User.Email_Address,
            Client_ID: Updated_User.Client_ID
        });
        return API_Client.put(API_ENDPOINTS.USERS.ENTITLEMENTS, Updated_User);
    },

    deleteUserEntitlement: async (Email_Address, Client_ID) => {
        if (!Email_Address || !Client_ID) {
            throw new API_Error('Email address and Client ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Deleting user entitlement', { 
            Email: Email_Address,
            Client_ID
        });
        return API_Client.delete(API_ENDPOINTS.USERS.ENTITLEMENTS, {
            params: { Email_Address, Client_ID }
        });
    },

    // Client operations
    getClients: async (Client_ID = null) => {
        console.log('Users_API: Getting clients', { Client_ID });
        const Query_Params = Client_ID ? { Client_ID } : {};
        return API_Client.get(API_ENDPOINTS.CLIENTS.BASE, { params: Query_Params });
    },

    createClient: async (Client_Data) => {
        if (!Client_Data.Client_Name || !Client_Data.Client_Type) {
            throw new API_Error('Client name and type are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Creating client', { 
            Name: Client_Data.Client_Name,
            Type: Client_Data.Client_Type
        });
        return API_Client.post(API_ENDPOINTS.CLIENTS.BASE, Client_Data);
    },

    updateClient: async (Client_ID, Updated_Client) => {
        if (!Client_ID) {
            throw new API_Error('Client ID is required', 'MISSING_CLIENT_ID');
        }

        console.log('Users_API: Updating client', { Client_ID });
        return API_Client.put(API_ENDPOINTS.CLIENTS.BASE, {
            Client_ID,
            ...Updated_Client
        });
    },

    deleteClient: async (Client_ID) => {
        if (!Client_ID) {
            throw new API_Error('Client ID is required', 'MISSING_CLIENT_ID');
        }

        console.log('Users_API: Deleting client', { Client_ID });
        return API_Client.delete(API_ENDPOINTS.CLIENTS.BASE, {
            params: { Client_ID }
        });
    },

    // Client Company operations
    getClientCompanies: async (Client_ID) => {
        if (!Client_ID) {
            throw new API_Error('Client ID is required', 'MISSING_CLIENT_ID');
        }

        console.log('Users_API: Getting client companies', { Client_ID });
        return API_Client.get(API_ENDPOINTS.CLIENTS.COMPANIES, {
            params: { Client_ID }
        });
    },

    createClientCompany: async (Company_Data) => {
        if (!Company_Data.Client_ID || !Company_Data.Company_Name) {
            throw new API_Error('Client ID and Company Name are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Creating client company', {
            Client_ID: Company_Data.Client_ID,
            Name: Company_Data.Company_Name
        });
        return API_Client.post(API_ENDPOINTS.CLIENTS.COMPANIES, Company_Data);
    },

    updateClientCompany: async (Company_Data) => {
        if (!Company_Data.Client_ID || !Company_Data.Company_ID) {
            throw new API_Error('Client ID and Company ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Updating client company', {
            Client_ID: Company_Data.Client_ID,
            Company_ID: Company_Data.Company_ID
        });
        return API_Client.put(API_ENDPOINTS.CLIENTS.COMPANIES, Company_Data);
    },

    deleteClientCompany: async (Client_ID, Company_ID) => {
        if (!Client_ID || !Company_ID) {
            throw new API_Error('Client ID and Company ID are required', 'MISSING_REQUIRED_FIELDS');
        }

        console.log('Users_API: Deleting client company', { Client_ID, Company_ID });
        return API_Client.delete(API_ENDPOINTS.CLIENTS.COMPANIES, {
            params: { Client_ID, Company_ID }
        });
    }
};

// Export for backward compatibility
export const usersApi = Users_API;
