import React, { useEffect, useContext, useState } from "react";
import { StatusContext } from "../../contexts/Status";
import { workflowApi } from "../../api";
import { usersApi } from "../../api";
import { Button, Select, Modal, Spinner, Alert } from "flowbite-react";
import { FiArrowRight, FiArrowLeft, FiUserPlus, FiUsers, FiUserMinus } from "react-icons/fi";

const ApprovalGroups = () => {
  const { Company_ID } = useContext(StatusContext);
  const [All_Users, setAllUsers] = useState([]);
  const [Approval_Groups, setApprovalGroups] = useState([]);
  const [Selected_Group, setSelectedGroup] = useState(null);
  const [Group_Members, setGroupMembers] = useState([]);
  const [Selected_Available_Users, setSelectedAvailableUsers] = useState([]);
  const [Selected_Group_Members, setSelectedGroupMembers] = useState([]);
  const [Is_New_Group_Modal_Visible, setIsNewGroupModalVisible] = useState(false);
  const [New_Group_Name, setNewGroupName] = useState("");
  const [Is_Loading, setIsLoading] = useState(true);
  const [Is_Updating, setIsUpdating] = useState(false);
  const [Error_Message, setErrorMessage] = useState("");

  useEffect(() => {
    const Fetch_Initial_Data = async () => {
      try {
        setIsLoading(true);
        setErrorMessage("");
        const [Users_Response, Groups_Response] = await Promise.all([
          usersApi.getUsers(Company_ID),
          workflowApi.getApprovalGroups(Company_ID)
        ]);
        
        if (!Users_Response.Success || !Groups_Response.Success) {
          throw new Error("Failed to fetch data");
        }

        const Users_Data = Users_Response.Data?.Records || [];
        const Groups_Data = Groups_Response.Data?.Records || [];

        setAllUsers(Users_Data);
        setApprovalGroups(Groups_Data);
      } catch (Error) {
        console.error('Error fetching data:', Error);
        setErrorMessage("Failed to load users and groups. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    Fetch_Initial_Data();
  }, [Company_ID]);

  useEffect(() => {
    const Fetch_Group_Members = async () => {
      if (Selected_Group) {
        try {
          setIsUpdating(true);
          setErrorMessage("");
          const Members_Response = await workflowApi.getApprovalGroupsEmail(Company_ID, Selected_Group.Approval_Group_ID);
          
          if (!Members_Response.Success) {
            throw new Error("Failed to fetch group members");
          }
          
          const Members_Data = Members_Response.Data?.Records || [];
          setGroupMembers(Members_Data);
        } catch (Error) {
          console.error('Error fetching group members:', Error);
          setErrorMessage("Failed to load group members. Please try again.");
        } finally {
          setIsUpdating(false);
        }
      } else {
        setGroupMembers([]);
      }
    };
    Fetch_Group_Members();
  }, [Selected_Group, Company_ID]);

  const Handle_Create_Group = async () => {
    if (!New_Group_Name.trim()) return;
    
    try {
      setIsUpdating(true);
      setErrorMessage("");
      
      // Generate a unique group ID
      const Group_ID = `AG_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      
      const Create_Response = await workflowApi.createApprovalGroup({
        Company_ID,
        Approval_Group_ID: Group_ID,
        Approval_Group_Name: New_Group_Name.trim()
      });
      
      if (!Create_Response.Success) {
        throw new Error("Failed to create group");
      }
      
      const Groups_Response = await workflowApi.getApprovalGroups(Company_ID);
      if (!Groups_Response.Success) {
        throw new Error("Failed to refresh groups");
      }
      
      const Groups_Data = Groups_Response.Data?.Records || [];
      setApprovalGroups(Groups_Data);
      
      // Select the newly created group
      const New_Group = Groups_Data.find(g => g.Approval_Group_ID === Group_ID);
      if (New_Group) {
        setSelectedGroup(New_Group);
      }
      
      setIsNewGroupModalVisible(false);
      setNewGroupName("");
    } catch (Error) {
      console.error('Error creating group:', Error);
      setErrorMessage("Failed to create group. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const Handle_Add_To_Group = async (Users_To_Add = Selected_Available_Users) => {
    if (!Selected_Group || Users_To_Add.length === 0) return;

    try {
      setIsUpdating(true);
      setErrorMessage("");
      const Add_Results = await Promise.all(
        Users_To_Add.map(async User_ID => {
          const User = All_Users.find(U => U.User_ID === User_ID);
          if (!User) return null;
          
          return workflowApi.createApprovalGroupEmail({
            Company_ID: Company_ID,
            Approval_Group_ID: Selected_Group.Approval_Group_ID,
            Email_Address: User.Email_Address
          });
        })
      );

      // Check if any operations failed
      const Failed_Operations = Add_Results.filter(result => !result || !result.Success);
      if (Failed_Operations.length > 0) {
        throw new Error(`Failed to add ${Failed_Operations.length} users to group`);
      }

      const Members_Response = await workflowApi.getApprovalGroupsEmail(Company_ID, Selected_Group.Approval_Group_ID);
      if (!Members_Response.Success) {
        throw new Error("Failed to refresh group members");
      }
      
      const Members_Data = Members_Response.Data?.Records || [];
      setGroupMembers(Members_Data);
      setSelectedAvailableUsers([]);
    } catch (Error) {
      console.error('Error adding users to group:', Error);
      setErrorMessage("Failed to add users to group. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const Handle_Remove_From_Group = async (Users_To_Remove = Selected_Group_Members) => {
    if (!Selected_Group || Users_To_Remove.length === 0) return;

    try {
      setIsUpdating(true);
      setErrorMessage("");
      const Remove_Results = await Promise.all(
        Users_To_Remove.map(async User_ID => {
          const User = All_Users.find(U => U.User_ID === User_ID);
          if (!User) return null;
          
          return workflowApi.deleteApprovalGroupEmail(
            Company_ID,
            Selected_Group.Approval_Group_ID,
            User.Email_Address
          );
        })
      );

      // Check if any operations failed
      const Failed_Operations = Remove_Results.filter(result => !result || !result.Success);
      if (Failed_Operations.length > 0) {
        throw new Error(`Failed to remove ${Failed_Operations.length} users from group`);
      }

      const Members_Response = await workflowApi.getApprovalGroupsEmail(Company_ID, Selected_Group.Approval_Group_ID);
      if (!Members_Response.Success) {
        throw new Error("Failed to refresh group members");
      }
      
      const Members_Data = Members_Response.Data?.Records || [];
      setGroupMembers(Members_Data);
      setSelectedGroupMembers([]);
    } catch (Error) {
      console.error('Error removing users from group:', Error);
      setErrorMessage("Failed to remove users from group. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const Handle_Add_All = () => {
    const All_Available_User_IDs = Available_Users.map(user => user.User_ID);
    Handle_Add_To_Group(All_Available_User_IDs);
  };

  const Handle_Remove_All = () => {
    const All_Member_IDs = Group_Members.map(member => member.User_ID);
    Handle_Remove_From_Group(All_Member_IDs);
  };

  const Available_Users = All_Users.filter(
    User => !Group_Members.some(Member => Member.Email_Address === User.Email_Address)
  );

  const Get_User_Display = (user) => {
    return user.Display_Name || user.First_Name ? 
      `${user.First_Name || ''} ${user.Last_Name || ''} (${user.Email_Address})` : 
      user.Email_Address;
  };

  if (Is_Loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  return (
    <div className="p-6">
      {Error_Message && (
        <Alert color="failure" className="mb-4">
          <span className="font-medium">Error!</span> {Error_Message}
        </Alert>
      )}

      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4 flex-1">
          <Select
            className="max-w-md"
            value={Selected_Group?.Approval_Group_ID || ""}
            onChange={(Event) => {
              const Group = Approval_Groups.find(G => G.Approval_Group_ID === Event.target.value);
              setSelectedGroup(Group);
              setSelectedAvailableUsers([]);
              setSelectedGroupMembers([]);
            }}
          >
            <option value="">Select Approval Group</option>
            {Approval_Groups.map(Group => (
              <option key={Group.Approval_Group_ID} value={Group.Approval_Group_ID}>
                {Group.Approval_Group_Name}
              </option>
            ))}
          </Select>
          <Button 
            color="blue"
            onClick={() => setIsNewGroupModalVisible(true)}
            className="flex items-center gap-2"
          >
            <FiUserPlus className="h-5 w-5" />
            New Group
          </Button>
        </div>
      </div>

      <div className="flex gap-4 h-[600px] relative">
        {Is_Updating && (
          <div className="absolute inset-0 bg-white/50 flex items-center justify-center z-10">
            <Spinner size="xl" />
          </div>
        )}
        
        <div className="flex-1 border rounded-lg p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold">Available Users</h3>
            <Button
              size="sm"
              color="blue"
              disabled={!Selected_Group || Available_Users.length === 0 || Is_Updating}
              onClick={Handle_Add_All}
              className="flex items-center gap-2"
            >
              <FiUsers className="h-4 w-4" />
              Add All
            </Button>
          </div>
          <div className="h-[500px] overflow-y-auto">
            {Available_Users.map(User => (
              <div
                key={User.User_ID}
                className={`p-2 cursor-pointer rounded transition-colors ${
                  Selected_Available_Users.includes(User.User_ID)
                    ? 'bg-blue-100 hover:bg-blue-200'
                    : 'hover:bg-gray-50'
                }`}
                onClick={() => {
                  setSelectedAvailableUsers(Previous_Selection =>
                    Previous_Selection.includes(User.User_ID)
                      ? Previous_Selection.filter(ID => ID !== User.User_ID)
                      : [...Previous_Selection, User.User_ID]
                  );
                }}
              >
                {Get_User_Display(User)}
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col justify-center gap-4">
          <Button
            color="blue"
            disabled={!Selected_Group || Selected_Available_Users.length === 0 || Is_Updating}
            onClick={() => Handle_Add_To_Group()}
            className="p-2"
            title="Add selected users to group"
          >
            <FiArrowRight className="h-6 w-6" />
          </Button>
          <Button
            color="blue"
            disabled={!Selected_Group || Selected_Group_Members.length === 0 || Is_Updating}
            onClick={() => Handle_Remove_From_Group()}
            className="p-2"
            title="Remove selected users from group"
          >
            <FiArrowLeft className="h-6 w-6" />
          </Button>
        </div>

        <div className="flex-1 border rounded-lg p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold">Group Members</h3>
            <Button
              size="sm"
              color="blue"
              disabled={!Selected_Group || Group_Members.length === 0 || Is_Updating}
              onClick={Handle_Remove_All}
              className="flex items-center gap-2"
            >
              <FiUserMinus className="h-4 w-4" />
              Remove All
            </Button>
          </div>
          <div className="h-[500px] overflow-y-auto">
            {Group_Members.map(Member => {
              const User = All_Users.find(U => U.Email_Address === Member.Email_Address);
              return (
                <div
                  key={Member.User_ID}
                  className={`p-2 cursor-pointer rounded transition-colors ${
                    Selected_Group_Members.includes(Member.User_ID)
                      ? 'bg-blue-100 hover:bg-blue-200'
                      : 'hover:bg-gray-50'
                  }`}
                  onClick={() => {
                    setSelectedGroupMembers(Previous_Selection =>
                      Previous_Selection.includes(Member.User_ID)
                        ? Previous_Selection.filter(ID => ID !== Member.User_ID)
                        : [...Previous_Selection, Member.User_ID]
                    );
                  }}
                >
                  {User ? Get_User_Display(User) : Member.Email_Address}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Modal
        show={Is_New_Group_Modal_Visible}
        onClose={() => setIsNewGroupModalVisible(false)}
        size="md"
      >
        <Modal.Header>Create New Approval Group</Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-900">
              Group Name
            </label>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={New_Group_Name}
              onChange={(Event) => setNewGroupName(Event.target.value)}
              placeholder="Enter group name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end gap-2">
            <Button color="gray" onClick={() => setIsNewGroupModalVisible(false)}>
              Cancel
            </Button>
            <Button 
              color="blue" 
              onClick={Handle_Create_Group} 
              disabled={Is_Updating || !New_Group_Name.trim()}
            >
              Create Group
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ApprovalGroups.Display_Name = 'ApprovalGroups';

export default ApprovalGroups;
