import React from "react";
import { useClientCompany } from "../../contexts/ClientCompanyContext";
import ClientManagement from "../../components/super/ClientManagement";

const SuperClients = () => {
  const {
    Clients: clients,
    Companies: companies,
    Is_Loading: loading,
    Error: error,
    handleAddClient,
    handleUpdateClient,
    handleDeleteClient,
    handleUpdateOrCreateComp,
    handleDeleteClientComp
  } = useClientCompany();

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <ClientManagement
          clients={clients}
          clientComps={companies}
          loading={loading}
          error={error}
          handleAddClient={handleAddClient}
          handleUpdateClient={handleUpdateClient}
          handleDeleteClient={handleDeleteClient}
          handleUpdateOrCreateComp={handleUpdateOrCreateComp}
          handleDeleteClientComp={handleDeleteClientComp}
        />
      </div>
    </div>
  );
};

export default SuperClients;
