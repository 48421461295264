import React, { useState, useContext, useEffect } from "react";
import { StatusContext } from "../../contexts/Status";
import { Users_API } from '../../api';
import Footer from "../../global/Footer";
import UserList from "../../components/users/UserList";
import UserAddModal from "../../components/users/UserAddModal";

const ITEMS_PER_PAGE = 10;

const AdminUsers = () => {
  const { Client_ID } = useContext(StatusContext);
  const [Is_Loading, setIsLoading] = useState(true);
  const [Users_List, setUsersList] = useState([]);
  const [Is_Add_Modal_Visible, setIsAddModalVisible] = useState(false);
  const [Current_Page, setCurrentPage] = useState(1);
  const [Search_Query, setSearchQuery] = useState("");

  useEffect(() => {
    const Fetch_Users_Data = async () => {
      if (!Client_ID) return;
      
      try {
        setIsLoading(true);
        const [Users_Response, Entitlements_Response] = await Promise.all([
          Users_API.getUsers({ Client_ID }),
          Users_API.getUsersEntitlements(Client_ID)
        ]);

        // Handle normalized response format
        const Users_Data = Users_Response.Data?.Records || [];
        const Entitlements_Data = Entitlements_Response.Data?.Records || [];

        const Filtered_Users = Users_Data.filter(
          User => !User.Is_Super_Admin
        );

        const Combined_Users = Combine_User_Data(Filtered_Users, Entitlements_Data);
        setUsersList(Combined_Users);
      } catch (Error) {
        console.error('Error fetching users:', Error);
      } finally {
        setIsLoading(false);
      }
    };

    Fetch_Users_Data();
  }, [Client_ID]);

  const Combine_User_Data = (Users, Entitlements) => {
    const Combined_Data = {};
    
    Entitlements.forEach(Entitlement => {
      Combined_Data[Entitlement.Email_Address] = {
        ...Combined_Data[Entitlement.Email_Address],
        ...Entitlement
      };
    });

    Users.forEach(User => {
      Combined_Data[User.Email_Address] = {
        ...Combined_Data[User.Email_Address],
        ...User
      };
    });

    return Object.values(Combined_Data);
  };

  const Handle_User_Update = async (Updated_User_Data) => {
    try {
      await Users_API.updateUserEntitlement(Updated_User_Data);
      const Updated_Users_List = Users_List.map(User =>
        User.Email_Address === Updated_User_Data.Email_Address ? Updated_User_Data : User
      );
      setUsersList(Updated_Users_List);
    } catch (Error) {
      console.error('Error updating user:', Error);
    }
  };

  const Filtered_Users = Users_List.filter(User =>
    User.Email_Address.toLowerCase().includes(Search_Query.toLowerCase())
  );

  const Paginated_Users = Filtered_Users.slice(
    (Current_Page - 1) * ITEMS_PER_PAGE,
    Current_Page * ITEMS_PER_PAGE
  );

  if (Is_Loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow p-6">
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold">Users</h1>
            <button
              className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors"
              onClick={() => setIsAddModalVisible(true)}
            >
              Add New User
            </button>
          </div>

          <UserList
            Users={Paginated_Users}
            On_Update_User={Handle_User_Update}
            Current_Page={Current_Page}
            Total_Pages={Math.ceil(Filtered_Users.length / ITEMS_PER_PAGE)}
            On_Page_Change={setCurrentPage}
            Search_Query={Search_Query}
            On_Search_Change={setSearchQuery}
          />
        </div>

        {Is_Add_Modal_Visible && (
          <UserAddModal 
            On_Close={() => setIsAddModalVisible(false)} 
            Client_ID={Client_ID}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

AdminUsers.Display_Name = 'AdminUsers';

export default AdminUsers;
