import { User_Pool } from "../../auth/UserPool";

// Constants
const TOKEN_BUFFER = 5 * 60 * 1000; // 5 minutes buffer before expiry

// Cache variables
let Cached_Token = null;
let Token_Promise = null;
let Token_Expiry = null;

// Custom error types
class Auth_Error extends Error {
    constructor(message, code) {
        super(message);
        this.name = 'Auth_Error';
        this.code = code;
    }
}

/**
 * Get authentication token from user pool with caching
 * @returns {Promise<string>} JWT token
 * @throws {Auth_Error} If no user found or session retrieval fails
 */
export const Get_Auth_Token = async () => {
    console.log('Auth: Requesting auth token');

    // If we have a cached token that's not expired, return it
    if (Cached_Token && Token_Expiry && Date.now() < Token_Expiry - TOKEN_BUFFER) {
        console.log('Auth: Using cached token');
        return Cached_Token;
    }

    // If a token request is already in progress, wait for it
    if (Token_Promise) {
        console.log('Auth: Using existing token promise');
        return Token_Promise;
    }

    // Create new token promise
    Token_Promise = new Promise((resolve, reject) => {
        const Current_User = User_Pool.getCurrentUser();
        
        if (!Current_User) {
            console.error('Auth: No current user found');
            reject(new Auth_Error("No current user found", "NO_USER"));
            return;
        }

        Current_User.getSession((Error, Session) => {
            if (Error) {
                console.error('Auth: Session Error:', Error);
                reject(new Auth_Error("Error during session retrieval: " + Error, "SESSION_ERROR"));
                return;
            }

            if (!Session) {
                console.error('Auth: No session available');
                reject(new Auth_Error("No session available", "NO_SESSION"));
                return;
            }

            if (!Session.idToken) {
                console.error('Auth: No ID token in session');
                reject(new Auth_Error("No ID token in session", "NO_ID_TOKEN"));
                return;
            }

            if (!Session.idToken.jwtToken) {
                console.error('Auth: No JWT token in ID token');
                reject(new Auth_Error("No JWT token in ID token", "NO_JWT_TOKEN"));
                return;
            }

            // Cache the token and set expiry
            Cached_Token = Session.idToken.jwtToken;
            Token_Expiry = Session.idToken.payload.exp * 1000; // Convert to milliseconds
            
            console.log('Auth: Successfully retrieved new token', {
                Expires_At: new Date(Token_Expiry).toISOString()
            });
            
            resolve(Cached_Token);
        });
    }).finally(() => {
        Token_Promise = null;
    });

    return Token_Promise;
};

/**
 * Create headers with authorization token
 * @param {Object} Additional_Headers - Additional headers to include
 * @returns {Promise<Object>} Headers object with authorization
 * @throws {Auth_Error} If token creation fails
 */
export const Create_Auth_Headers = async (Additional_Headers = {}) => {
    const Auth_Token = await Get_Auth_Token();
    
    if (!Auth_Token) {
        console.error('Auth: No token available for headers');
        throw new Auth_Error('No token available for headers', 'NO_TOKEN_FOR_HEADERS');
    }

    const Headers = {
        ...Additional_Headers,
        'Authorization': `Bearer ${Auth_Token}`
    };

    return Headers;
};

/**
 * Verify if we have a valid token
 * @returns {Promise<boolean>} True if valid token exists
 */
export const Has_Valid_Token = async () => {
    try {
        // Check if cached token exists and is not expired
        if (Cached_Token && Token_Expiry && Date.now() < Token_Expiry - TOKEN_BUFFER) {
            console.log('Auth: Using cached token for validation');
            return true;
        }

        // Try to get a new token
        const Auth_Token = await Get_Auth_Token();
        console.log('Auth: Token validation result:', !!Auth_Token);
        return !!Auth_Token;
    } catch (Error) {
        console.error('Auth: Token validation failed:', Error);
        return false;
    }
};

/**
 * Refresh the session token
 * @returns {Promise<string>} New JWT token
 * @throws {Auth_Error} If token refresh fails
 */
export const Refresh_Auth_Token = async () => {
    console.log('Auth: Refreshing token');
    
    // Clear cached token
    Clear_Token_Cache();

    // Get fresh token
    return Get_Auth_Token();
};

/**
 * Clear the token cache
 */
export const Clear_Token_Cache = () => {
    console.log('Auth: Clearing token cache');
    Cached_Token = null;
    Token_Expiry = null;
    Token_Promise = null;
};
