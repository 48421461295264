import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Users_API } from '../api';
import { StatusContext, normalizeCompanyFields } from './Status';

const ClientCompanyContext = createContext();

// Helper to normalize companies response
const normalizeCompaniesResponse = (Response) => {
  console.log('ClientCompanyContext: Normalizing companies response:', Response);

  // Handle direct array format (Response itself is the array)
  if (Array.isArray(Response)) {
    console.log('ClientCompanyContext: Handling direct array response');
    return Response.map(normalizeCompanyFields);
  }
  
  // Handle standard format
  if (Response?.Data?.Companies) {
    console.log('ClientCompanyContext: Handling standard format with Companies');
    return Response.Data.Companies.map(normalizeCompanyFields);
  }

  // Handle direct data array
  if (Array.isArray(Response?.Data)) {
    console.log('ClientCompanyContext: Handling direct Data array');
    return Response.Data.map(normalizeCompanyFields);
  }

  // If Response is an object with expected company fields, treat it as a single company
  if (Response?.Company_ID || Response?.Create_Date) {
    console.log('ClientCompanyContext: Handling single company object');
    return [normalizeCompanyFields(Response)];
  }

  console.log('ClientCompanyContext: No valid company data found in response');
  return [];
};

// Helper to normalize clients response
const normalizeClientsResponse = (Response) => {
  console.log('ClientCompanyContext: Normalizing clients response:', Response);

  // Handle standard format with Clients array
  if (Response?.Data?.Clients) {
    console.log('ClientCompanyContext: Found clients in Data.Clients array');
    return Response.Data.Clients;
  }

  // Handle direct array format
  if (Array.isArray(Response?.Data)) {
    console.log('ClientCompanyContext: Found clients in Data array');
    return Response.Data;
  }

  // Handle direct array response
  if (Array.isArray(Response)) {
    console.log('ClientCompanyContext: Found clients in direct array');
    return Response;
  }

  console.log('ClientCompanyContext: No valid clients data found');
  return [];
};

export const ClientCompanyProvider = ({ children }) => {
  const { Is_Super_Admin } = useContext(StatusContext);
  const [Clients_List, setClientsList] = useState([]);
  const [Companies_Map, setCompaniesMap] = useState({});
  const [Selected_Client_ID, setSelectedClientID] = useState(null);
  const [Error_Message, setErrorMessage] = useState(null);
  const [Meta_Data, setMetaData] = useState(null);
  const [Is_Loading, setIsLoading] = useState(false);

  const fetchClients = useCallback(async (force = false) => {
    if (!Is_Super_Admin) return;

    try {
      setErrorMessage(null);
      setIsLoading(true);
      const Response = await Users_API.getClients();
      console.log('ClientCompanyContext: Clients response:', Response);
      const clientsList = normalizeClientsResponse(Response);
      console.log('ClientCompanyContext: Normalized clients list:', clientsList);
      setClientsList(clientsList);
      setMetaData(Response.Meta);
    } catch (Error) {
      console.error("Failed to fetch clients:", Error);
      setErrorMessage(Error.message || "Failed to fetch clients. Please try again.");
      setMetaData(Error.details?.meta || null);
      setClientsList([]);
    } finally {
      setIsLoading(false);
    }
  }, [Is_Super_Admin]);

  const fetchCompanies = useCallback(async (Client_ID) => {
    if (!Client_ID) return;

    try {
      setIsLoading(true);
      const Response = await Users_API.getClientCompanies(Client_ID);
      console.log('ClientCompanyContext: Companies response:', Response);
      const companiesList = normalizeCompaniesResponse(Response);
      console.log('ClientCompanyContext: Normalized companies list:', companiesList);
      setCompaniesMap(prev => ({
        ...prev,
        [Client_ID]: companiesList
      }));
      setMetaData(Response.Meta);
    } catch (Error) {
      console.error("Failed to fetch companies:", Error);
      setErrorMessage(Error.message || "Failed to fetch companies. Please try again.");
      setMetaData(Error.details?.meta || null);
      setCompaniesMap(prev => ({
        ...prev,
        [Client_ID]: []
      }));
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Initial clients fetch
  useEffect(() => {
    if (Is_Super_Admin) {
      fetchClients();
    }
  }, [Is_Super_Admin, fetchClients]);

  // Fetch companies when client is selected
  useEffect(() => {
    if (Selected_Client_ID) {
      fetchCompanies(Selected_Client_ID);
    }
  }, [Selected_Client_ID, fetchCompanies]);

  const selectClient = useCallback((Client_ID) => {
    console.log('ClientCompanyContext: Selecting client:', Client_ID);
    setSelectedClientID(Client_ID);
  }, []);

  const getClientById = useCallback((Client_ID) => {
    const client = Clients_List.find(Client => Client.Client_ID === Client_ID);
    console.log('ClientCompanyContext: Getting client by ID:', { Client_ID, client });
    return client;
  }, [Clients_List]);

  const getCompanyById = useCallback((Client_ID, Company_ID) => {
    const clientCompanies = Companies_Map[Client_ID] || [];
    const company = clientCompanies.find(Company => Company.Company_ID === Company_ID);
    console.log('ClientCompanyContext: Getting company by ID:', { Client_ID, Company_ID, company });
    return company;
  }, [Companies_Map]);

  const handleAddClient = useCallback(async (clientData) => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      const Response = await Users_API.createClient(clientData);
      await fetchClients(true);
      return Response;
    } catch (Error) {
      setErrorMessage(Error.message || "Failed to add client");
      setMetaData(Error.details?.meta || null);
      throw Error;
    } finally {
      setIsLoading(false);
    }
  }, [fetchClients]);

  const handleUpdateClient = useCallback(async (clientId, clientData) => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      const Response = await Users_API.updateClient(clientId, clientData);
      
      // Get updated client data
      const Client_Response = await Users_API.getClients(clientId);
      console.log('ClientCompanyContext: Updated client response:', Client_Response);
      
      const Updated_Client = Client_Response.Data?.Client || 
                           (Client_Response.Data?.Clients && 
                            Client_Response.Data.Clients.find(c => c.Client_ID === clientId)) ||
                           (Array.isArray(Client_Response.Data) &&
                            Client_Response.Data.find(c => c.Client_ID === clientId)) ||
                           (Array.isArray(Client_Response) &&
                            Client_Response.find(c => c.Client_ID === clientId));
      
      if (Updated_Client) {
        console.log('ClientCompanyContext: Updating client in list:', Updated_Client);
        setClientsList(prev => prev.map(client => 
          client.Client_ID === clientId ? Updated_Client : client
        ));
      }
      
      return Response;
    } catch (Error) {
      setErrorMessage(Error.message || "Failed to update client");
      setMetaData(Error.details?.meta || null);
      throw Error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDeleteClient = useCallback(async (clientId) => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      const Response = await Users_API.deleteClient(clientId);
      await fetchClients(true);
      return Response;
    } catch (Error) {
      setErrorMessage(Error.message || "Failed to delete client");
      setMetaData(Error.details?.meta || null);
      throw Error;
    } finally {
      setIsLoading(false);
    }
  }, [fetchClients]);

  const handleUpdateOrCreateComp = useCallback(async (companyData) => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      if (companyData.Company_ID) {
        await Users_API.updateClientCompany(companyData);
      } else {
        await Users_API.createClientCompany(companyData);
      }
      await fetchCompanies(companyData.Client_ID);
    } catch (Error) {
      setErrorMessage(Error.message || "Failed to update company");
      setMetaData(Error.details?.meta || null);
      throw Error;
    } finally {
      setIsLoading(false);
    }
  }, [fetchCompanies]);

  const handleDeleteClientComp = useCallback(async (clientId, companyId) => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      await Users_API.deleteClientCompany(clientId, companyId);
      await fetchCompanies(clientId);
    } catch (Error) {
      setErrorMessage(Error.message || "Failed to delete company");
      setMetaData(Error.details?.meta || null);
      throw Error;
    } finally {
      setIsLoading(false);
    }
  }, [fetchCompanies]);

  const Context_Value = {
    Clients: Clients_List,
    Companies: Companies_Map[Selected_Client_ID] || [],
    Selected_Client_ID,
    Error: Error_Message,
    Meta: Meta_Data,
    Is_Loading,
    Select_Client: selectClient,
    Clear_Error: () => setErrorMessage(null),
    Get_Client_By_ID: getClientById,
    Get_Company_By_ID: getCompanyById,
    handleAddClient,
    handleUpdateClient,
    handleDeleteClient,
    handleUpdateOrCreateComp,
    handleDeleteClientComp,
    Refresh_Data: fetchClients
  };

  return (
    <ClientCompanyContext.Provider value={Context_Value}>
      {children}
    </ClientCompanyContext.Provider>
  );
};

export const useClientCompany = () => {
  const Context = useContext(ClientCompanyContext);
  if (!Context) {
    throw new Error('useClientCompany must be used within a ClientCompanyProvider');
  }
  return Context;
};
