import React from 'react';
import { useCompanyChartData } from '../../contexts/CompanyChartDataContext';
import RenderCompanyCards from '../../components/dashboard/RenderCompanyCards';
import RenderCompanySparkCharts from '../../components/dashboard/RenderCompanySparkCharts';

const AdminDashboardData = () => {
    const { Is_Loading, Error_Message } = useCompanyChartData();

    // Debug log
    console.log('AdminDashboardData - Initializing:', {
        Is_Loading,
        Error_Message,
        Has_Context: true
    });

    if (Is_Loading) {
        return (
            <div className="space-y-6">
                <div className="bg-white rounded-lg shadow-lg p-6">
                    <div className="animate-pulse">
                        <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                        <div className="space-y-4">
                            <div className="h-8 bg-gray-200 rounded w-1/2"></div>
                            <div className="h-8 bg-gray-200 rounded w-2/3"></div>
                            <div className="h-8 bg-gray-200 rounded w-1/3"></div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-lg shadow-lg p-6">
                    <div className="animate-pulse">
                        <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                        <div className="space-y-4">
                            <div className="h-8 bg-gray-200 rounded w-1/2"></div>
                            <div className="h-8 bg-gray-200 rounded w-2/3"></div>
                            <div className="h-8 bg-gray-200 rounded w-1/3"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (Error_Message) {
        return (
            <div className="text-red-600 p-4 bg-white rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-2">Error Loading Data</h2>
                <p>{Error_Message}</p>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Company Metrics</h2>
                <RenderCompanyCards />
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Metric Trends</h2>
                <RenderCompanySparkCharts />
            </div>
        </div>
    );
};

AdminDashboardData.Display_Name = 'AdminDashboardData';

export default AdminDashboardData;
