import React, { createContext, useState, useEffect, useCallback } from 'react';
import { analyticsApi } from '../api';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
    const [state, setState] = useState({
        structure: null,
        powerScores: null,
        pmsMetrics: null,
        isLoading: true,
        error: null,
        queryContext: {
            Request_ID: '',
            Timestamp: ''
        }
    });

    // Fetch organization structure
    const fetchOrgStructure = useCallback(async (Company_ID, Root_Org_ID = null) => {
        setState(prev => ({ ...prev, isLoading: true }));
        try {
            const response = await analyticsApi.getOrgStructure(Company_ID, Root_Org_ID);
            setState(prev => ({
                ...prev,
                structure: response.Organization,
                isLoading: false,
                error: null,
                queryContext: {
                    Request_ID: `org_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                }
            }));
        } catch (error) {
            setState(prev => ({
                ...prev,
                error: {
                    message: 'Failed to fetch organization structure',
                    errors: [error.message]
                },
                isLoading: false
            }));
        }
    }, []);

    // Fetch power scores
    const fetchPowerScores = useCallback(async (Company_ID, Org_ID) => {
        try {
            const response = await analyticsApi.getPowerScores(Company_ID, Org_ID);
            setState(prev => ({
                ...prev,
                powerScores: response.Power_Scores,
                queryContext: {
                    Request_ID: `power_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                }
            }));
        } catch (error) {
            console.error('Error fetching power scores:', error);
            throw error;
        }
    }, []);

    // Fetch PMS metrics
    const fetchPMSMetrics = useCallback(async (Company_ID, Org_ID) => {
        try {
            const response = await analyticsApi.getPMSMetrics(Company_ID, Org_ID);
            setState(prev => ({
                ...prev,
                pmsMetrics: response.PMS_Metrics,
                queryContext: {
                    Request_ID: `pms_${Date.now()}`,
                    Timestamp: new Date().toISOString()
                }
            }));
        } catch (error) {
            console.error('Error fetching PMS metrics:', error);
            throw error;
        }
    }, []);

    // Update power score
    const updatePowerScore = useCallback(async (Company_ID, Org_ID, adjustment, reason, details) => {
        try {
            const response = await analyticsApi.updatePowerScore(Company_ID, Org_ID, {
                Adjustment: adjustment,
                Reason: reason,
                Impact_Details: details
            });

            setState(prev => ({
                ...prev,
                powerScores: {
                    ...prev.powerScores,
                    Current_Score: response.Power_Score.New,
                    Historical: [
                        ...prev.powerScores.Historical,
                        {
                            Date: new Date().toISOString(),
                            Score: response.Power_Score.New,
                            Change_Reason: reason
                        }
                    ]
                }
            }));

            return response.Power_Score;
        } catch (error) {
            console.error('Error updating power score:', error);
            throw error;
        }
    }, []);

    // Update PMS category
    const updatePMSCategory = useCallback(async (Company_ID, Org_ID, newCategory, reason, actions) => {
        try {
            const response = await analyticsApi.updatePMSCategory(Company_ID, Org_ID, {
                New_Category: newCategory,
                Reason: reason,
                Required_Actions: actions
            });

            setState(prev => ({
                ...prev,
                pmsMetrics: {
                    ...prev.pmsMetrics,
                    Category: newCategory
                }
            }));

            return response.PMS_Update;
        } catch (error) {
            console.error('Error updating PMS category:', error);
            throw error;
        }
    }, []);

    // Get node by path
    const getNodeByPath = useCallback((path) => {
        if (!state.structure) return null;

        let current = state.structure;
        for (const id of path) {
            if (!current.Children) return null;
            const next = current.Children.find(child => child.Org_ID === id);
            if (!next) return null;
            current = next;
        }
        return current;
    }, [state.structure]);

    // Get node metrics
    const getNodeMetrics = useCallback((Org_ID) => {
        return {
            power_score: state.powerScores?.Current_Score,
            pms_category: state.pmsMetrics?.Category,
            current_metrics: state.pmsMetrics?.Current_Metrics,
            target_metrics: state.pmsMetrics?.Target_Metrics
        };
    }, [state.powerScores, state.pmsMetrics]);

    // Validate node updates
    const validateNodeUpdates = useCallback((updates) => {
        const errors = [];

        if (updates.Power_Score_Adjustment) {
            const newScore = state.powerScores.Current_Score + updates.Power_Score_Adjustment;
            if (newScore < 0 || newScore > 10) {
                errors.push('Power score must be between 0 and 10');
            }
        }

        if (updates.PMS_Category) {
            const validTransitions = {
                Reward: ['Replace'],
                Replace: ['Reward', 'Remediate'],
                Remediate: ['Replace']
            };

            const currentCategory = state.pmsMetrics.Category;
            if (!validTransitions[currentCategory].includes(updates.PMS_Category)) {
                errors.push(`Invalid transition from ${currentCategory} to ${updates.PMS_Category}`);
            }
        }

        return errors;
    }, [state.powerScores, state.pmsMetrics]);

    const contextValue = {
        ...state,
        fetchOrgStructure,
        fetchPowerScores,
        fetchPMSMetrics,
        updatePowerScore,
        updatePMSCategory,
        getNodeByPath,
        getNodeMetrics,
        validateNodeUpdates
    };

    return (
        <OrganizationContext.Provider value={contextValue}>
            {children}
        </OrganizationContext.Provider>
    );
};

export const useOrganization = () => {
    const context = React.useContext(OrganizationContext);
    if (!context) {
        throw new Error('useOrganization must be used within an OrganizationProvider');
    }
    return context;
};
