import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Chart_Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const D3GaugeChart = ({
  Value,
  Min_Value = 0,
  Max_Value = 10,
  Chart_Width = 300,
  Chart_Height = 200,
  Color_Scale = d3.scaleThreshold()
    .domain([3, 5, 7])
    .range(['#ef4444', '#f59e0b', '#22c55e', '#3b82f6']),
  Show_Value = true,
  Show_Thresholds = true,
  Title = '',
  Transition_Duration = 750
}) => {
  const SVG_Ref = useRef(null);

  useEffect(() => {
    if (Value === undefined || Value === null) return;

    // Clear previous chart
    d3.select(SVG_Ref.current).selectAll("*").remove();

    const Margin = { Top: 40, Right: 30, Bottom: 30, Left: 30 };
    const Inner_Width = Chart_Width - Margin.Left - Margin.Right;
    const Inner_Height = Chart_Height - Margin.Top - Margin.Bottom;

    const Radius = Math.min(Inner_Width / 2, Inner_Height);
    const Start_Angle = -Math.PI / 2;  // -90 degrees
    const End_Angle = Math.PI / 2;     // 90 degrees

    // Create SVG
    const SVG = d3.select(SVG_Ref.current)
      .attr("width", Chart_Width)
      .attr("height", Chart_Height)
      .attr("role", "img")
      .attr("aria-label", `Gauge Chart showing ${Value} out of ${Max_Value}`)
      .append("g")
      .attr("transform", `translate(${Chart_Width/2},${Chart_Height - Margin.Bottom})`);

    // Create scale for the gauge
    const Scale = d3.scaleLinear()
      .domain([Min_Value, Max_Value])
      .range([Start_Angle, End_Angle]);

    // Create arc generator
    const Arc = d3.arc()
      .innerRadius(Radius * 0.6)
      .outerRadius(Radius)
      .startAngle(Start_Angle);

    // Add background arc
    SVG.append("path")
      .datum({ endAngle: End_Angle })
      .style("fill", "#e5e7eb")
      .attr("d", Arc);

    // Add value arc with transition
    const Value_Arc = SVG.append("path")
      .datum({ endAngle: Start_Angle })
      .style("fill", Color_Scale(Value))
      .attr("d", Arc);

    Value_Arc.transition()
      .duration(Transition_Duration)
      .attrTween("d", (d) => {
        const Interpolate = d3.interpolate(d.endAngle, Scale(Value));
        return (t) => {
          d.endAngle = Interpolate(t);
          return Arc(d);
        };
      });

    // Add thresholds if enabled
    if (Show_Thresholds) {
      const Thresholds = Color_Scale.domain();
      Thresholds.forEach(threshold => {
        const Angle = Scale(threshold);
        const [x1, y1] = [
          Math.cos(Angle) * Radius * 0.55,
          Math.sin(Angle) * Radius * 0.55
        ];
        const [x2, y2] = [
          Math.cos(Angle) * Radius * 1.05,
          Math.sin(Angle) * Radius * 1.05
        ];

        SVG.append("line")
          .attr("x1", x1)
          .attr("y1", y1)
          .attr("x2", x2)
          .attr("y2", y2)
          .style("stroke", "#6b7280")
          .style("stroke-width", "2px");

        SVG.append("text")
          .attr("x", Math.cos(Angle) * Radius * 1.15)
          .attr("y", Math.sin(Angle) * Radius * 1.15)
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .text(threshold);
      });
    }

    // Add value text if enabled
    if (Show_Value) {
      SVG.append("text")
        .attr("class", "value-text")
        .attr("transform", `translate(0,${-Radius * 0.4})`)
        .attr("text-anchor", "middle")
        .style("font-size", "24px")
        .style("font-weight", "bold")
        .text(Value.toFixed(1));

      if (Title) {
        SVG.append("text")
          .attr("class", "title-text")
          .attr("transform", `translate(0,${-Radius * 0.6})`)
          .attr("text-anchor", "middle")
          .style("font-size", "14px")
          .text(Title);
      }
    }

    // Add min and max labels
    SVG.append("text")
      .attr("x", -Radius)
      .attr("y", 0)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .text(Min_Value);

    SVG.append("text")
      .attr("x", Radius)
      .attr("y", 0)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .text(Max_Value);

  }, [Value, Min_Value, Max_Value, Chart_Width, Chart_Height, Color_Scale, Show_Value, Show_Thresholds, Title, Transition_Duration]);

  return (
    <Chart_Container>
      <svg ref={SVG_Ref} />
    </Chart_Container>
  );
};

D3GaugeChart.propTypes = {
  Value: PropTypes.number.isRequired,
  Min_Value: PropTypes.number,
  Max_Value: PropTypes.number,
  Chart_Width: PropTypes.number,
  Chart_Height: PropTypes.number,
  Color_Scale: PropTypes.func,
  Show_Value: PropTypes.bool,
  Show_Thresholds: PropTypes.bool,
  Title: PropTypes.string,
  Transition_Duration: PropTypes.number
};

export default D3GaugeChart;
