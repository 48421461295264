import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUpload, FiChevronDown, FiClipboard } from 'react-icons/fi';
import logo from '../../assets/logo512.png';
import { StatusContext } from '../../contexts/Status';
import { useClientCompany } from '../../contexts/ClientCompanyContext';
import { organizationApi } from '../../api';
import { useWorkflowActions } from '../../hooks/useWorkflowActions';

const TopNavigation = () => {
  const { 
    User_Data,
    Is_Super_Admin,
    Client_ID,
    Client_Name,
    Company_Name,
    Company_ID,
    Update_Client_And_Company 
  } = useContext(StatusContext);
  const { Clients: companies } = useClientCompany();
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { pendingCount } = useWorkflowActions();

  // Only fetch departments and locations when needed
  const fetchDepartmentsAndLocations = async () => {
    if (!Company_ID) return;
    
    try {
      const [departmentsData, locationsData] = await Promise.all([
        organizationApi.getDepartment(Company_ID),
        organizationApi.getLocations(Company_ID)
      ]);
      
      setDepartments(departmentsData?.data?.Records || []);
      setLocations(locationsData?.data?.Records || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCompanyChange = (companyId) => {
    Update_Client_And_Company(Client_ID, companyId);
    setShowCompanyDropdown(false);
    setSelectedDepartment(null);
    setSelectedLocation(null);
    navigate('/admin/dashboard');
  };

  const handleDepartmentChange = async (department) => {
    setSelectedDepartment(department);
    setShowDepartmentDropdown(false);
    if (department) {
      navigate('/admin/dashboard/department', { state: { departmentId: department.Department_ID }});
    }
  };

  const handleLocationChange = async (location) => {
    setSelectedLocation(location);
    setShowLocationDropdown(false);
    if (location) {
      navigate('/admin/dashboard/location', { state: { locationId: location.Work_Location_ID }});
    }
  };

  const handleDepartmentClick = async () => {
    if (!departments.length) {
      await fetchDepartmentsAndLocations();
    }
    setShowDepartmentDropdown(!showDepartmentDropdown);
  };

  const handleLocationClick = async () => {
    if (!locations.length) {
      await fetchDepartmentsAndLocations();
    }
    setShowLocationDropdown(!showLocationDropdown);
  };

  return (
    <div className="top-navigation flex items-center justify-between p-4 bg-white shadow-md">
      <img src={logo} alt="Logo" className="logo h-12 cursor-pointer" onClick={() => navigate('/admin/dashboard')} />

      <div className="flex items-center gap-4">
        <span>Email: {User_Data?.Email_Address || '-'}</span>
        
        {Is_Super_Admin && (
          <div className="relative">
            <span className="cursor-pointer flex items-center" onClick={() => setShowCompanyDropdown(!showCompanyDropdown)}>
              Client: {Client_Name || 'All'} {companies?.length > 0 && <FiChevronDown className="ml-1" />}
            </span>
            {showCompanyDropdown && (
              <div className="absolute top-full left-0 mt-1 bg-white border rounded shadow-lg z-10">
                {(companies || []).map((company) => (
                  <div key={company.Company_ID} className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleCompanyChange(company.Company_ID)}>
                    {company.Company_Name}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {Company_ID && (
          <>
            <div className="relative">
              <span className="cursor-pointer flex items-center" onClick={handleDepartmentClick}>
                Department: {selectedDepartment ? selectedDepartment.Department_Name : 'All'} <FiChevronDown className="ml-1" />
              </span>
              {showDepartmentDropdown && (
                <div className="absolute top-full left-0 mt-1 bg-white border rounded shadow-lg z-10">
                  <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleDepartmentChange(null)}>All</div>
                  {departments.map((department) => (
                    <div key={department.Department_ID} className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleDepartmentChange(department)}>
                      {department.Department_Name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="relative">
              <span className="cursor-pointer flex items-center" onClick={handleLocationClick}>
                Location: {selectedLocation ? selectedLocation.Work_Location_Name : 'All'} <FiChevronDown className="ml-1" />
              </span>
              {showLocationDropdown && (
                <div className="absolute top-full left-0 mt-1 bg-white border rounded shadow-lg z-10">
                  <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleLocationChange(null)}>All</div>
                  {locations.map((location) => (
                    <div key={location.Work_Location_ID} className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleLocationChange(location)}>
                      {location.Work_Location_Name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}

        <button 
          onClick={() => navigate('/onboarding')}
          title="Upload Data"
          className="flex items-center justify-center p-2 rounded hover:bg-gray-100"
        >
          <FiUpload className="h-6 w-6" />
        </button>

        <button 
          onClick={() => navigate('/admin/approvals')}
          title="Pending Approvals"
          className="flex items-center justify-center p-2 rounded hover:bg-gray-100 relative"
        >
          <FiClipboard className="h-6 w-6" />
          {pendingCount > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {pendingCount > 99 ? '99+' : pendingCount}
            </span>
          )}
        </button>

        {Is_Super_Admin && (
          <button 
            onClick={() => navigate('/super/dashboard')}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Super Dashboard
          </button>
        )}
      </div>
    </div>
  );
};

export default TopNavigation;
